import { Component, Input, OnInit } from '@angular/core';
import { NotificacionesService } from 'src/app/services/notificaciones.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-notificacion',
  templateUrl: './notificacion.component.html',
  styleUrls: ['./notificacion.component.css']
})
export class NotificacionComponent implements OnInit {


  @Input() notificacion;
  @Input() numeromensajes;
  
  idNotificacion: string;
  datosNotificacion: any;
  datosEmisor: any = { avatar: '', nombres: '', apellidos: '' };

  // url = '';
  constructor(
    private userServices: UserService,
    private notificacionesService: NotificacionesService
  ) {}

  ngOnInit() {
    this.datosEmisor.avatar = '';
    this.datosNotificacion = this.notificacion.payload.doc.data();
    this.idNotificacion = this.notificacion.payload.doc.id;
    this.userServices
      .getUserById(this.datosNotificacion.emisor)
      .subscribe((data) => {
        this.datosEmisor = data[0].payload.doc.data();
      });

    // if (
    //   this.datosNotificacion.mensaje == true
    // ) {
    //   this.url = this.datosNotificacion.url;
    // } else {
    //   // this.url = '/dashboard/profile/'+this.+'/miseventos';
    // }
  }
  deletenotificacion() {
    this.notificacionesService
      .removeNotificacion(this.idNotificacion)
      .then((data) => {});
  }


  vernotificacion(uid) {
  //   const update = {
  //     verlo: '',
  //   };
  //   this.notificacionesService
  //     .updateNotificacion(uid, update)
  //     .then((data) => {});
  }
}
