import { User } from './../../interfaces/user';
import { ChatService } from './../../services/chat.service';
import { UserService } from './../../services/user.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import {
  Component,
  OnInit,
  ComponentFactoryResolver,
  Output,
  EventEmitter,
} from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { NotificacionesService } from 'src/app/services/notificaciones.service';

@Component({
  selector: 'app-mensajes',
  templateUrl: './mensajes.component.html',
  styleUrls: ['./mensajes.component.css'],
})
export class MensajesComponent implements OnInit {
  mainuserid: string;
  searchValue = '';
  results: any = '';
  chatmessage = '';
  receiverid: string;
  chats = <any>[];
  chatmessages = [];
  datecu: Date;
  elotrousaurio: any;
  chatid: string;
  chatusers = [];
  userchat: any;
  thelastmessage: any;
  grupodechats = [];
  chatactive = [];
  chatloader = false;
  notification = [];
  mainuseremail: any;
  urluid: any;
  nombres: any;
  apellidos: any;
  clases: any;
  // notificaciones: any;
  conteomensajes: number = 0;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private chatService: ChatService,
    private notificacionesService: NotificacionesService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit(): void {
    const Userdata: any = this.userService.getUserinfodata();

    if (Userdata === undefined) {
      this.authenticationService.getStatus().subscribe((status) => {
        this.mainuserid = status.uid;
        this.mainuseremail = status.email;

        this.authenticationService
          .isUserAdmin(this.mainuserid)
          .subscribe((userdata) => {
            if (userdata[0]) {
              const userdata2: any = userdata[0];
              this.nombres =
                userdata2.nombres; /* nombres del usuario logueado */
              this.apellidos =
                userdata2.apellidos; /*apellidos del usuario logueado */
              this.clases = userdata2.clases;

              this.notificacionesService.getNotificacionesMensajes(this.mainuserid).subscribe(notificaciones => {
                this.conteomensajes = 0;
                if(notificaciones.length>0){
                  this.conteomensajes = notificaciones.length
                }
              })
            }
          });

        this.getChats();
      });
    } else {
      this.mainuserid = Userdata.payload.doc.data().uid;
      this.mainuseremail = Userdata.payload.doc.data().email;
      this.nombres =
        Userdata.payload.doc.data().nombres; /* nombres del usuario logueado */
      this.apellidos =
        Userdata.payload.doc.data().apellidos; /*apellidos del usuario logueado */
      this.clases = Userdata.payload.doc.data().clases;

      this.notificacionesService.getNotificacionesMensajes(this.mainuserid).subscribe(notificaciones => {
        this.conteomensajes = 0;
        if(notificaciones.length>0){
          this.conteomensajes = notificaciones.length
        }
      })

      this.getChats();
    }

    this.chatService.getBorrarElChat().subscribe((deldata) => {
      const chatdeldata: any = deldata;
      this.deleteconversation(chatdeldata.mainuid, chatdeldata.theuser);
    });
  }

  search() {
    this.userService.searchUsers(this.clases).subscribe((searchdata) => {
      this.results = searchdata;
    });
  }

  openchat(result) {
    this.receiverid = result.uid;
    const ids = [this.mainuserid, result.uid].sort();
    const chatid = ids.join('|');

    if (!this.chatusers[result.uid]) {
      this.chatService.setchatmembers(chatid, {
        members: [
          { uid: this.mainuserid, join: true },
          { uid: result.uid, join: false },
        ],
      });
      this.grupodechats[chatid] = [];
      this.chatusers[result.uid] = result;
      this.chatactive[chatid] = { active: false };
    }
    this.results = '';
  }

  getChats() {
    this.chatService.getChats(this.mainuserid).subscribe((chat) => {
      this.chats = chat;

      chat.forEach((chatData: any) => {
        const messagechatid = [
          chatData.members[0].uid,
          chatData.members[1].uid,
        ].sort();
        const themeschaid = messagechatid.join('|');

        this.grupodechats[themeschaid] = [];

        let receiveruser;

        if (chatData.members[1].uid !== this.mainuserid) {
          receiveruser = chatData.members[1].uid;
        } else {
          receiveruser = chatData.members[0].uid;
        }

        this.userService.getUserById(receiveruser).subscribe((user) => {
          if(user.length>0){
            this.userchat = user[0].payload.doc.data();
            this.chatusers[this.userchat.uid] = [];
            this.chatusers[this.userchat.uid] = user[0].payload.doc.data();
            this.chatactive[themeschaid] = { active: false, nuevomessage: false };
          }
        });

        this.getlastmessage();
      });

      this.chatloader = true;

      this.chatService.chatActual.subscribe((data) => {
        const thechatdata: any = data;
        this.urluid = thechatdata;
        this.chatstatus(this.chatactive[thechatdata]);
      });
    });
  }

  getlastmessage() {
    if (this.grupodechats) {
      // tslint:disable-next-line: forin
      for (let key in this.grupodechats) {
        this.chatService
          .getLastMessage(key, this.mainuserid)
          .subscribe((chat) => {
            this.grupodechats[key] = chat[0]?.payload.doc.data();

            if (
              !this.notification[chat[0]?.payload.doc.id] &&
              chat[0]?.payload.doc.data().receiver === this.mainuserid &&
              chat[0]?.payload.doc.data().nuevo === true
            ) {
              const audio = new Audio('assets/sounds/chatnotification.m4a');
              audio.play();
              this.notification[chat[0]?.payload.doc.id] = {};
              const chatid = [
                chat[0]?.payload.doc.data().receiver,
                chat[0]?.payload.doc.data().sender,
              ].sort();
              const chatidjoin = chatid.join('|');
              const update = { nuevo: false };
              this.chatService
                .updatemessage(chatidjoin, chat[0].payload.doc.id, update)
                .then();
              this.chatactive[chatidjoin] = { nuevomessage: true };
            }
          });
      }
    }
  }

  deleteconversation(mainuid, receiveruid) {
    const members = {
      members: [
        { uid: mainuid, join: false },
        { uid: receiveruid, join: true },
      ],
    };
    const chatid = [mainuid, receiveruid].sort();
    const prechatid = chatid.join('|');

    this.chatService.deleteconversation(prechatid, members, receiveruid);
    this.router.navigate(['dashboard', 'mensajes']);

    delete this.grupodechats[prechatid];
    delete this.chatactive[prechatid];
    delete this.chatusers[receiveruid];
  }

  chatstatus(chat) {
    const fruit_keys = Object.keys(this.chatactive);
    fruit_keys.forEach((thechat) => {
      this.chatactive[thechat].active = false;
    });
    chat.nuevomessage = false;
    chat.active = true;
  }
}
