<!-- dashboard -->
<div class="dashboard">
  <div class="fila1">
    <div>
      <section class="basicbox horojo wplus medium fullheight mismensajes">
        <header>
          <h3 translate>dashboard.home.mensajes</h3>
          <div>
            <div class="plusbutton" routerLink="/dashboard/mensajes/"  (click)="UpdateBreadcrumb('dashboard.home.mensajes','/dashboard/mensajes/')"
            >+</div>
          </div>
          <div class="icon" routerLink="/dashboard/mensajes/" (click)="UpdateBreadcrumb('dashboard.home.mensajes','/dashboard/mensajes/')">
            <div>
              <ng-container *ngIf="conteomensajes > 0">
                <div class="notificationnumber">
                  {{ conteomensajes }}
                </div>
              </ng-container>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
                <g
                  id="Grupo_9488"
                  data-name="Grupo 9488"
                  transform="translate(-2 -2)"
                >
                  <path
                    id="Trazado_9284"
                    data-name="Trazado 9284"
                    d="M2.831,41.87a1.431,1.431,0,0,0,1.527-.214l6.79-5.82a10.012,10.012,0,0,1,6.507-2.407h18.63A5.72,5.72,0,0,0,42,27.714v-20A5.72,5.72,0,0,0,36.286,2H7.714A5.72,5.72,0,0,0,2,7.714V40.571A1.432,1.432,0,0,0,2.831,41.87ZM4.857,7.714A2.86,2.86,0,0,1,7.714,4.857H36.286a2.86,2.86,0,0,1,2.857,2.857v20a2.86,2.86,0,0,1-2.857,2.857H17.656a12.867,12.867,0,0,0-8.367,3.1l-4.431,3.8Z"
                    transform="translate(0 0)"
                    fill="#23273b"
                  />
                  <rect
                    id="Rectángulo_3102"
                    data-name="Rectángulo 3102"
                    width="14"
                    height="4"
                    transform="translate(15 16)"
                    fill="#23273b"
                  />
                </g>
              </svg>
            </div>
          </div>
        </header>

        <article class="users">
          <app-userbox
            *ngFor="let chat of chats"
            [mainuid]="userUid"
            [members]="chat.members"
            [chatusers]="chatusers"
            [grupodechats]="grupodechats"
            [nombre]="nombres"
            [apellidos]="apellidos"
          ></app-userbox>
        </article>

        <footer>
          <a routerLink="/dashboard/mensajes/" class="button medium center" (click)="UpdateBreadcrumb('dashboard.home.mensajes','/dashboard/mensajes/')">
            {{ "dashboard.home.mensajes" | translate }}
          </a>
        </footer>
      </section>
    </div>

    <div>
      <section class="basicbox horojo medium fullheight thcmeet">
        <header>
          <h3 translate>dashboard.home.video</h3>
          <div class="icon">
            <a
              href="https://in2.thecampus.education/webapp3/#/?conference={{
                usuario
              }}&name={{ nombres | titlecase }} {{
                apellidos | titlecase
              }}&pin={{ pexipdata?.pin }}&role=host&callType=video&join=1"
              target="_blank"
            >
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46.401 31">
                  <g
                    id="Grupo_9489"
                    data-name="Grupo 9489"
                    transform="translate(-2.5 -6.5)"
                  >
                    <path
                      id="Trazado_9285"
                      data-name="Trazado 9285"
                      d="M48.352,11.858a1.049,1.049,0,0,0-1.128-.054l-7.6,4.232V12.958c0-3.562-2.6-6.458-5.8-6.458H8.3c-3.2,0-5.8,2.9-5.8,6.458V31.042C2.5,34.6,5.1,37.5,8.3,37.5H33.821c3.2,0,5.8-2.9,5.8-6.458V27.965l7.6,4.234a1.07,1.07,0,0,0,1.128-.054,1.344,1.344,0,0,0,.552-1.1V12.958A1.34,1.34,0,0,0,48.352,11.858Zm-1.77,17.094-7.6-4.234a1.081,1.081,0,0,0-1.128.054,1.344,1.344,0,0,0-.552,1.1v5.167a3.7,3.7,0,0,1-3.48,3.875H8.3a3.7,3.7,0,0,1-3.48-3.875V12.958A3.7,3.7,0,0,1,8.3,9.083H33.821a3.7,3.7,0,0,1,3.48,3.875v5.167a1.34,1.34,0,0,0,.55,1.1,1.059,1.059,0,0,0,1.128.054l7.6-4.232Z"
                      transform="translate(0 0)"
                      fill="#23273b"
                    />
                  </g>
                </svg>
              </div>
            </a>
          </div>
        </header>
        <article class="containervideo">
          <img
            id="changeC"
            src="/assets/img/changeCam.svg"
            alt="cambiar Camara"
            (click)="changeCamera()"
          />
          <img
            title="Apagar Cámara"
            id="changeC"
            src="/assets/img/camaraOff2.svg"
            alt="apagar camara"
            (click)="apagar()"
          />
          <div *ngIf="apagado == false" id="videoContainer">
            <video id="video"></video>
          </div>
          <div *ngIf="apagado == true" id="videoContainer2">
            <img src="assets/img/camara_apagada2.png" />
          </div>
          <article class="threebuttons3">
            <div>
              <a
                href="https://in2.thecampus.education/webapp3/#/?conference={{
                  usuario
                }}&name={{ nombres | titlecase }} {{
                  apellidos | titlecase
                }}&pin={{ pexipdata?.pin }}&role=host&callType=video&join=1"
                target="_blank"
                class="iconcircle tusala"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 34.611 22.947"
                >
                  <g
                    id="Grupo_9465"
                    data-name="Grupo 9465"
                    transform="translate(0.5 0.5)"
                  >
                    <path
                      id="Trazado_9259"
                      data-name="Trazado 9259"
                      d="M35.713,10.293a.775.775,0,0,0-.817-.038l-5.507,3V11.072a4.4,4.4,0,0,0-4.2-4.572H6.7a4.4,4.4,0,0,0-4.2,4.572v12.8a4.4,4.4,0,0,0,4.2,4.572H25.188a4.4,4.4,0,0,0,4.2-4.572V21.7l5.506,3a.79.79,0,0,0,.817-.038.945.945,0,0,0,.4-.781v-12.8A.942.942,0,0,0,35.713,10.293ZM34.431,22.4l-5.506-3a.8.8,0,0,0-.817.038.945.945,0,0,0-.4.781v3.658a2.642,2.642,0,0,1-2.521,2.743H6.7a2.642,2.642,0,0,1-2.521-2.743v-12.8A2.642,2.642,0,0,1,6.7,8.329H25.188a2.642,2.642,0,0,1,2.521,2.743V14.73a.942.942,0,0,0,.4.779.782.782,0,0,0,.817.038l5.507-3Z"
                      transform="translate(-2.5 -6.5)"
                      fill="#fff"
                      stroke="#fff"
                      stroke-width="1"
                    />
                  </g>
                </svg>
              </a>
            </div>
            <p>
              <a
                href="https://in2.thecampus.education/webapp3/#/?conference={{
                  usuario
                }}&name={{ nombres | titlecase }} {{
                  apellidos | titlecase
                }}&pin={{ pexipdata?.pin }}&role=host&callType=video&join=1"
                target="_blank"
              >
                {{ "dashboard.home.sala" | translate }}
              </a>
            </p>
            <div>
              <a
                href="https://in2.thecampus.education/webapp3/#/?conference=salapruebas&conference=salapruebas&callType=video&join=1"
                target="_blank"
                class="iconcircle"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 34.037 34.537"
                >
                  <g
                    id="Grupo_9466"
                    data-name="Grupo 9466"
                    transform="translate(0.75 0.75)"
                  >
                    <g id="Grupo_9407" data-name="Grupo 9407">
                      <path
                        id="Trazado_9260"
                        data-name="Trazado 9260"
                        d="M21.268,5A16.4,16.4,0,0,0,5,21.518,16.4,16.4,0,0,0,21.268,38.037,16.4,16.4,0,0,0,37.537,21.518,16.4,16.4,0,0,0,21.268,5Zm0,31.2A14.573,14.573,0,0,1,6.808,21.518,14.573,14.573,0,0,1,21.268,6.835,14.573,14.573,0,0,1,35.729,21.518,14.573,14.573,0,0,1,21.268,36.2Z"
                        transform="translate(-5 -5)"
                        fill="#23273b"
                        stroke="#23273b"
                        stroke-width="1.5"
                      />
                      <path
                        id="Trazado_9261"
                        data-name="Trazado 9261"
                        d="M41.674,31.627a1.3,1.3,0,0,0-1.813.232l-6.324,8.184-3.324-4.361a1.3,1.3,0,0,0-1.811-.245h0a1.3,1.3,0,0,0-.245,1.811L32.467,42.9a1.389,1.389,0,0,0,2.131,0l7.309-9.458A1.3,1.3,0,0,0,41.674,31.627Z"
                        transform="translate(-18.768 -20.862)"
                        fill="#23273b"
                        stroke="#23273b"
                        stroke-width="1.5"
                      />
                    </g>
                  </g>
                </svg>
              </a>
            </div>
            <p>
              <a
                href="https://in2.thecampus.education/webapp3/#/?conference=salapruebas&conference=salapruebas&callType=video&join=1"
                target="_blank"
              >
                {{ "dashboard.home.live" | translate }}
              </a>
            </p>
            <!-- <div>
                      <a title="The campus video" href="https://in.thecampus.education/webapp2/home" (click)="UpdateBreadcrumb('The Campus Video', '/dashboard/appsint/in.thecampus.education')"  class="iconcircle">
                        <svg xmlns="http://www.w3.org/2000/svg" width="33.615" height="23.944" viewBox="0 0 34.615 22.944">
                          <g id="Grupo_9465" data-name="Grupo 9465" transform="translate(0.504 0.5)">
                            <path id="Trazado_9259" data-name="Trazado 9259" d="M35.713,10.293a.775.775,0,0,0-.817-.038l-5.507,3V11.072a4.4,4.4,0,0,0-4.2-4.572H6.7a4.4,4.4,0,0,0-4.2,4.572v12.8a4.4,4.4,0,0,0,4.2,4.572H25.188a4.4,4.4,0,0,0,4.2-4.572V21.7l5.506,3a.79.79,0,0,0,.817-.038.945.945,0,0,0,.4-.781v-12.8A.942.942,0,0,0,35.713,10.293ZM34.431,22.4l-5.506-3a.8.8,0,0,0-.817.038.945.945,0,0,0-.4.781v3.658a2.642,2.642,0,0,1-2.521,2.743H6.7a2.642,2.642,0,0,1-2.521-2.743v-12.8A2.642,2.642,0,0,1,6.7,8.329H25.188a2.642,2.642,0,0,1,2.521,2.743V14.73a.942.942,0,0,0,.4.779.782.782,0,0,0,.817.038l5.507-3Z" transform="translate(-2.5 -6.5)" fill="#23273b" stroke="#23273b" stroke-width="1"/>
                          </g>
                        </svg>
                        
                      </a>
                  </div>
                   <p>
                       <a href="https://in.thecampus.education/webapp2/home" target="_blank" (click)="UpdateBreadcrumb('The Campus Video', '/dashboard/appsint/in.thecampus.education')" title="The campus video">
                           Ir a Otra Sala
                       </a>
                   </p>  -->
          </article>

          <p translate>dashboard.home.llamar</p>
          <div class="search">
            <img src="assets/img/search.svg" alt="" />
            <input
              type="text"
              placeholder="{{ 'dashboard.home.placeholder' | translate }}"
              (keyup)="search()"
              [(ngModel)]="searchValue"
            />
            <div class="resulstsearch" *ngIf="results != '' && searchValue">
              <ng-container
                *ngFor="let result of results | search2 : searchValue"
              >
                <ng-container *ngIf="result.payload.doc.data().uid != userUid">
                  <p
                    id="buscador"
                    (click)="openVideo(result.payload.doc.data())"
                  >
                    {{ result.payload.doc.data().nombres | titlecase }}
                    {{ result.payload.doc.data().apellidos | titlecase }}
                  </p>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </article>
        <footer>
          <a
            routerLink="/dashboard/profile/{{ userUid }}/misala"
            class="button medium center bicon bolder2"
          >
            <span>
              <img src="/assets/img/key.svg" alt="" />
            </span>
            {{ "dashboard.home.config" | translate }}
          </a>
        </footer>
      </section>
    </div>

    <div>
      <section class="basicbox horojo medium fullheight wplus calendar">
        <header>
          <h3 translate>dashboard.home.eventos</h3>
          <div>
            <div
              class="plusbutton"
              routerLink="/dashboard/profile/{{ userUid }}/eventos"
              (click)="
                UpdateBreadcrumb(
                  'dashboard.home.creareventos',
                  '/dashboard/profile/' + userUid + '/eventos'
                )
              "
            >
              +
            </div>
          </div>
          <div
            class="icon"
            routerLink="/dashboard/profile/{{ userUid }}/miseventos"
            (click)="
              UpdateBreadcrumb(
                'dashboard.home.miseventos',
                '/dashboard/profile/' + userUid + '/miseventos'
              )
            "
          >
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 37.485 36.615"
              >
                <g
                  id="Grupo_9498"
                  data-name="Grupo 9498"
                  transform="translate(-52.7 -63.476)"
                >
                  <g
                    id="Grupo_9491"
                    data-name="Grupo 9491"
                    transform="translate(52.706 75.647)"
                  >
                    <g
                      id="Grupo_9490"
                      data-name="Grupo 9490"
                      transform="translate(0 0)"
                    >
                      <path
                        id="Trazado_9286"
                        data-name="Trazado 9286"
                        d="M87.882,362.882v18.474c0,.841.008,1.678,0,2.518,0,.049,0,.094-.008.143.016-.11.029-.216.045-.327a1.154,1.154,0,0,1-.069.265l.122-.294c-.024.049-.049.094-.073.143-.094.18.2-.233.057-.082s.265-.155.082-.057c-.049.024-.094.053-.143.073l.294-.122a1.155,1.155,0,0,1-.265.069c.11-.016.216-.029.327-.045-.322.029-.653.008-.971.008H56.372c-.424,0-.845,0-1.269,0a1.119,1.119,0,0,1-.163-.008c.11.016.216.029.327.045a1.155,1.155,0,0,1-.265-.069l.294.122-.143-.073c-.18-.094.233.2.082.057s.155.265.057.082c-.024-.049-.053-.094-.073-.143l.122.294a1.154,1.154,0,0,1-.069-.265c.016.11.029.216.045.327-.02-.216-.008-.437-.008-.653V365.486c0-.853.02-1.71,0-2.567v-.037l-1.224,1.225h33.6c.457,0,.91,0,1.367,0H89.1a1.224,1.224,0,0,0,0-2.449h-33.6c-.457,0-.91,0-1.367,0h-.057a1.241,1.241,0,0,0-1.224,1.224v20.363c0,.212,0,.429,0,.641a2.228,2.228,0,0,0,2.229,2.212c.51,0,1.024,0,1.535,0H88.11a2.231,2.231,0,0,0,2.22-2.22c.008-.71,0-1.42,0-2.131V362.882a1.224,1.224,0,0,0-2.449,0Z"
                        transform="translate(-52.856 -361.656)"
                        fill="#23273b"
                      />
                    </g>
                  </g>
                  <g
                    id="Grupo_9493"
                    data-name="Grupo 9493"
                    transform="translate(60.059 63.476)"
                  >
                    <g
                      id="Grupo_9492"
                      data-name="Grupo 9492"
                      transform="translate(0 0)"
                    >
                      <path
                        id="Trazado_9287"
                        data-name="Trazado 9287"
                        d="M235.449,70.579V64.7a1.224,1.224,0,0,0-2.449,0v5.878a1.224,1.224,0,0,0,2.449,0Z"
                        transform="translate(-233 -63.476)"
                        fill="#23273b"
                      />
                    </g>
                  </g>
                  <g
                    id="Grupo_9495"
                    data-name="Grupo 9495"
                    transform="translate(80.386 63.476)"
                  >
                    <g
                      id="Grupo_9494"
                      data-name="Grupo 9494"
                      transform="translate(0 0)"
                    >
                      <path
                        id="Trazado_9288"
                        data-name="Trazado 9288"
                        d="M731,64.7v5.878a1.224,1.224,0,1,0,2.449,0V64.7a1.224,1.224,0,1,0-2.449,0Z"
                        transform="translate(-731 -63.476)"
                        fill="#23273b"
                      />
                    </g>
                  </g>
                  <g
                    id="Grupo_9497"
                    data-name="Grupo 9497"
                    transform="translate(52.7 66.37)"
                  >
                    <g
                      id="Grupo_9496"
                      data-name="Grupo 9496"
                      transform="translate(0 0)"
                    >
                      <path
                        id="Trazado_9289"
                        data-name="Trazado 9289"
                        d="M87.733,136.568v8.306l1.224-1.224h-33.6c-.457,0-.91-.008-1.367,0h-.057l1.224,1.224v-7.31c0-.367-.024-.743.008-1.106-.016.11-.029.216-.045.327a1.152,1.152,0,0,1,.069-.265l-.122.294c.024-.049.049-.094.073-.143.094-.18-.2.233-.057.082s-.265.155-.082.057c.049-.025.094-.053.143-.073l-.294.122a1.155,1.155,0,0,1,.265-.069c-.11.016-.216.029-.327.045.322-.029.653-.008.971-.008H86.671c.424,0,.845,0,1.269,0a1.122,1.122,0,0,1,.163.008c-.11-.016-.216-.029-.327-.045a1.155,1.155,0,0,1,.265.069l-.294-.122.143.073c.18.094-.233-.2-.082-.057s-.155-.265-.057-.082c.024.049.053.094.073.143l-.122-.294a1.152,1.152,0,0,1,.069.265c-.016-.11-.029-.216-.045-.327a.923.923,0,0,1,0,.11,1.224,1.224,0,0,0,2.449,0,2.234,2.234,0,0,0-2.2-2.2H56.52c-.539,0-1.082-.012-1.62,0a2.211,2.211,0,0,0-2.151,1.755,4.624,4.624,0,0,0-.049.747v8a1.242,1.242,0,0,0,1.224,1.224h33.6c.457,0,.91,0,1.367,0h.057a1.242,1.242,0,0,0,1.225-1.224v-8.306a1.218,1.218,0,1,0-2.437,0Z"
                        transform="translate(-52.7 -134.367)"
                        fill="#23273b"
                      />
                    </g>
                  </g>
                </g>
              </svg>
            </div>
          </div>
        </header>
        <article>
          <app-calendar></app-calendar>
        </article>
        <footer class="threeicons">
          <div>
            <a id="downloadCalendar" (click)="download()">
              <img src="/assets/img/outlook2.jpg" alt="" />
            </a>
          </div>
          <div>
            <a id="downloadCalendar" (click)="download()">
              <img src="/assets/img/gcalendar.jpg" alt="" />
            </a>
          </div>
          <div>
            <a id="downloadCalendar" (click)="download()">
              <img src="/assets/img/calendarios.jpg" alt="" />
            </a>
          </div>
        </footer>
      </section>
    </div>
  </div>

  <div class="fila2">
    <div>
      <section class="basicbox horojo appsbox">
        <header>
          <h3 translate>dashboard.home.appInternas</h3>
          <!-- <div class="icon">
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
                <path
                  id="Trazado_9290"
                  data-name="Trazado 9290"
                  d="M8.5,23.592H20.088a3.509,3.509,0,0,0,3.5-3.5V8.5a3.509,3.509,0,0,0-3.5-3.5H8.5A3.509,3.509,0,0,0,5,8.5V20.088A3.508,3.508,0,0,0,8.5,23.592ZM7.222,8.5A1.284,1.284,0,0,1,8.5,7.222H20.088A1.284,1.284,0,0,1,21.371,8.5V20.088a1.284,1.284,0,0,1-1.283,1.282H8.5a1.284,1.284,0,0,1-1.283-1.282Zm22.69,15.088H41.5a3.508,3.508,0,0,0,3.5-3.5V8.5A3.508,3.508,0,0,0,41.5,5H29.912a3.508,3.508,0,0,0-3.5,3.5V20.088A3.508,3.508,0,0,0,29.912,23.592ZM28.63,8.5a1.284,1.284,0,0,1,1.282-1.282H41.5A1.284,1.284,0,0,1,42.778,8.5V20.088A1.283,1.283,0,0,1,41.5,21.37H29.912a1.283,1.283,0,0,1-1.282-1.282ZM8.5,45H20.088a3.509,3.509,0,0,0,3.5-3.5V29.912a3.509,3.509,0,0,0-3.5-3.5H8.5a3.509,3.509,0,0,0-3.5,3.5V41.5A3.509,3.509,0,0,0,8.5,45ZM7.222,29.912A1.284,1.284,0,0,1,8.5,28.629H20.088a1.284,1.284,0,0,1,1.283,1.283V41.5a1.284,1.284,0,0,1-1.283,1.282H8.5A1.284,1.284,0,0,1,7.222,41.5ZM29.912,45H41.5A3.508,3.508,0,0,0,45,41.5V29.912a3.509,3.509,0,0,0-3.5-3.5H29.912a3.509,3.509,0,0,0-3.5,3.5V41.5A3.508,3.508,0,0,0,29.912,45ZM28.63,29.912a1.284,1.284,0,0,1,1.282-1.283H41.5a1.284,1.284,0,0,1,1.282,1.283V41.5A1.284,1.284,0,0,1,41.5,42.778H29.912A1.284,1.284,0,0,1,28.63,41.5Z"
                  transform="translate(-5 -5)"
                  fill="#23273b"
                />
              </svg>
            </div>
          </div> -->
        </header>
        <article>
          <!-- box -->
          <div class="subbasicbox apps">
            <div *ngIf="this.colegio?.codigo_colegio === 'demo_arcol'">
              <a
                href="https://eva.edu.xunta.gal"
                target="_blank"
                title="Edixgal"
              >
                <img id="" src="assets/img/edixgal.png" />
              </a>
            </div>

            <div>
              <a
                routerLink="/dashboard/appsint/mygofacing.web.app/"
                (click)="
                  UpdateBreadcrumb(
                    'Mygofacing',
                    '/dashboard/appsint/mygofacing.web.app/'
                  )
                "
                title="Mygofacing"
              >
                <img id="go" src="assets/img/gofacing.png" />
              </a>
            </div>

            <div>
              <a
                routerLink="/dashboard/appsint/laiatech.com/"
                (click)="
                  UpdateBreadcrumb(
                    'Laia Tech',
                    '/dashboard/appsint/laiatech.com/'
                  )
                "
                title="Laiatech"
              >
                <img src="assets/img/laia.png" />
              </a>
            </div>

            <div>
              <a href="https://docs.zoho.com" target="_blank" title="Docs">
                <img src="assets/img/docs.png" />
              </a>
            </div>

            <div>
              <a
                href="https://accounts.zoho.eu/signin?servicename=ZohoCRM&signupurl=https://www.zoho.eu/crm/signup.html?plan=enterprise"
                target="_blank"
                title="CRM"
              >
                <img src="assets/img/crm.png" />
              </a>
            </div>

            <div>
              <a
                href="https://accounts.zoho.eu/signin?servicename=ZohoCampaigns&signupurl=https://www.zoho.eu/campaigns/signup.html"
                target="_blank"
                title="Campaigns"
              >
                <img src="assets/img/campaigns.png" />
              </a>
            </div>

            <div>
              <a
                href="https://forms.zoho.com/GoFacing/home#myforms"
                target="_blank"
                title="Forms"
              >
                <img src="assets/img/forms.png" />
              </a>
            </div>

            <div>
              <a
                href="https://accounts.zoho.eu/signin?servicename=VirtualOffice&signupurl=https://workplace.zoho.eu/orgsignup.do?signup.html"
                target="_blank"
                title="Email"
              >
                <img src="assets/img/email.png" />
              </a>
            </div>

            <div>
              <a href="https://desk.zoho.com/" target="_blank" title="Soporte">
                <img src="assets/img/soporte.png" />
              </a>
            </div>

            <div>
              <a
                href="https://accounts.zoho.com/signin?servicename=ZohoInventory&signupurl=https://www.zoho.com/inventory/signup/"
                target="_blank"
                title="Inventario"
              >
                <img src="assets/img/inventario.png" />
              </a>
            </div>

            <div>
              <a
                href="https://accounts.zoho.com/signin?servicename=ZohoBooks&signupurl=https://www.zoho.com/books/signup/"
                target="_blank"
                title="Books"
              >
                <img src="assets/img/books.png" />
              </a>
            </div>
            <div>
              <a
                routerLink="/dashboard/appsint/app.mihuelladigital.es"
                (click)="
                  UpdateBreadcrumb(
                    'Mi huella digital',
                    '/dashboard/appsint/app.mihuelladigital.es'
                  )
                "
                title="Ewink"
              >
                <img id="go" src="assets/img/tools/ewink-dark-logo.png" />
              </a>
            </div>
            <div>
              <a
                routerLink="/dashboard/appsint/app.ewink.es"
                (click)="
                  UpdateBreadcrumb(
                    'ewink',
                    '/dashboard/appsint/app.ewink.es'
                  )
                "
                title="Ewink"
              >
                <img id="go" src="assets/img/tools/ewink-copia.png" />
              </a>
            </div>
          </div>
          <!--/box -->
        </article>
      </section>
    </div>
    <div>
      <section class="basicbox horojo appsbox">
        <header>
          <h3 translate>dashboard.home.appExternas</h3>
          <!-- <div class="icon">
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
                <path
                  id="Trazado_9290"
                  data-name="Trazado 9290"
                  d="M8.5,23.592H20.088a3.509,3.509,0,0,0,3.5-3.5V8.5a3.509,3.509,0,0,0-3.5-3.5H8.5A3.509,3.509,0,0,0,5,8.5V20.088A3.508,3.508,0,0,0,8.5,23.592ZM7.222,8.5A1.284,1.284,0,0,1,8.5,7.222H20.088A1.284,1.284,0,0,1,21.371,8.5V20.088a1.284,1.284,0,0,1-1.283,1.282H8.5a1.284,1.284,0,0,1-1.283-1.282Zm22.69,15.088H41.5a3.508,3.508,0,0,0,3.5-3.5V8.5A3.508,3.508,0,0,0,41.5,5H29.912a3.508,3.508,0,0,0-3.5,3.5V20.088A3.508,3.508,0,0,0,29.912,23.592ZM28.63,8.5a1.284,1.284,0,0,1,1.282-1.282H41.5A1.284,1.284,0,0,1,42.778,8.5V20.088A1.283,1.283,0,0,1,41.5,21.37H29.912a1.283,1.283,0,0,1-1.282-1.282ZM8.5,45H20.088a3.509,3.509,0,0,0,3.5-3.5V29.912a3.509,3.509,0,0,0-3.5-3.5H8.5a3.509,3.509,0,0,0-3.5,3.5V41.5A3.509,3.509,0,0,0,8.5,45ZM7.222,29.912A1.284,1.284,0,0,1,8.5,28.629H20.088a1.284,1.284,0,0,1,1.283,1.283V41.5a1.284,1.284,0,0,1-1.283,1.282H8.5A1.284,1.284,0,0,1,7.222,41.5ZM29.912,45H41.5A3.508,3.508,0,0,0,45,41.5V29.912a3.509,3.509,0,0,0-3.5-3.5H29.912a3.509,3.509,0,0,0-3.5,3.5V41.5A3.508,3.508,0,0,0,29.912,45ZM28.63,29.912a1.284,1.284,0,0,1,1.282-1.283H41.5a1.284,1.284,0,0,1,1.282,1.283V41.5A1.284,1.284,0,0,1,41.5,42.778H29.912A1.284,1.284,0,0,1,28.63,41.5Z"
                  transform="translate(-5 -5)"
                  fill="#23273b"
                />
              </svg>
            </div>
          </div> -->
        </header>
        <article>
          <!-- box -->
          <div class="subbasicbox apps">
            <!--<ng-container *ngFor="let app of colegioapps_externas; let i= index">


                       <div>
                         <a href="{{app.url}}" target="_blank">
                          <img *ngIf="app.icono else defaultappicono" src="{{app.icono}}" alt="{{app.nombre}}">
                          <ng-template #defaultappicono>
                            <img src="assets/img/defaultapp.jpg" alt="{{app.nombre}}">
                          </ng-template>
                         </a>
                       </div>


                     </ng-container>-->

            <div>
              <a href="https://asana.com/" target="_blank" title="Asana">
                <img src="assets/img/asana.png" />
              </a>
            </div>

            <div>
              <a
                href="https://calendar.google.com/"
                target="_blank"
                title="GCalendar"
              >
                <img src="assets/img/gcalendar.png" />
              </a>
            </div>

            <div>
              <a
                href="https://drive.google.com/"
                target="_blank"
                title="Gdrive"
              >
                <img src="assets/img/gdrive.png" />
              </a>
            </div>

            <div>
              <a
                href="https://www.apple.com/co/itunes/"
                target="_blank"
                title="iTunes"
              >
                <img src="assets/img/itunes.png" />
              </a>
            </div>

            <div>
              <a
                href="https://mailchimp.com/"
                target="_blank"
                title="Mailchimp"
              >
                <img src="assets/img/mailchimp.png" />
              </a>
            </div>

            <div>
              <a
                href="https://outlook.live.com"
                target="_blank"
                title="Outlook"
              >
                <img src="assets/img/outlook.png" />
              </a>
            </div>

            <div>
              <a href="https://slack.com/" target="_blank" title="Slack">
                <img src="assets/img/slack.png" />
              </a>
            </div>

            <div>
              <a
                href="https://www.youtube.com/"
                target="_blank"
                title="Youtube"
              >
                <img src="assets/img/youtube.png" />
              </a>
            </div>
          </div>
          <!--/box -->
        </article>
      </section>
    </div>
  </div>
</div>
<!-- /dashboard -->
