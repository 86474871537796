import { AngularFireStorageModule } from '@angular/fire/storage';
import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PadresRoutingModule } from './padres-routing.module';
import { FormsModule } from '@angular/forms';
import { ImageCropperModule } from 'ngx-image-cropper';

// import { SearchPipe } from '../pipes/search.pipe';
// import { Search2Pipe } from '../pipes/search2.pipe';
// import { Safe2Pipe } from '../pipes/safe2.pipe';
import { Safe2Pipe } from '../pipes/safe2.pipe';

import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';

import { CalendarModule } from 'angular-calendar';
import { DateAdapter } from 'angular-calendar';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';

registerLocaleData(localeEs);
import { NgxPaginationModule } from 'ngx-pagination';

import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

import { ReactiveFormsModule } from '@angular/forms';

import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { DatetimePopupModule } from 'ngx-bootstrap-datetime-popup';

// import { OrderbyPipe } from '../pipes/orderby.pipe';
// import { FilterarchivoPipe } from '../pipes/filterarchivo.pipe';
// import { BreadcrumbactualPipe } from '../pipes/breadcrumbactual.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { HomeComponent } from './home/home.component';
import{HeaderComponent} from './header/header.component'


// import { HeaderComponent } from './header/header.component';
import { MenuComponent } from './menu/menu.component';
import { AppsInternasComponent } from './home/apps-internas/apps-internas.component';
import { UserboxComponent } from './home/userbox/userbox.component';
import { PadresComponent } from './padres.component';
import { MishijosComponent } from './mishijos/mishijos.component';
import { HijoboxComponent } from './mishijos/hijobox/hijobox.component';
import { HijoComponent } from './hijo/hijo.component';
import { ClaseboxComponent } from './hijo/clasebox/clasebox.component';
import { MensajesComponent } from './mensajes/mensajes.component';
import { HiloComponent } from './mensajes/hilo/hilo.component';
import { UserboxChatComponent } from './mensajes/userbox-chat/userbox-chat.component';
import { NotificacionesComponent } from './notificaciones/notificaciones.component';
import { NotificacionComponent } from './notificaciones/notificacion/notificacion.component';
import { ProfileComponent } from './profile/profile.component';
import { AjustesComponent } from './profile/ajustes/ajustes.component';
import { FotoComponent } from './profile/foto/foto.component';
import { PassComponent } from './profile/pass/pass.component';

@NgModule({
  declarations: [
    HomeComponent,
    HeaderComponent,
    MenuComponent,
    AppsInternasComponent,
    UserboxComponent,
    Safe2Pipe,
    PadresComponent,
    MishijosComponent,
    HijoboxComponent,
    HijoComponent,
    ClaseboxComponent,
    MensajesComponent,
    HiloComponent,
    UserboxChatComponent,
    NotificacionesComponent,
    NotificacionComponent,
    ProfileComponent,
    AjustesComponent,
    FotoComponent,
    PassComponent
    // SearchPipe,
    // Search2Pipe,
    // OrderbyPipe,
    // FilterarchivoPipe,
    // BreadcrumbactualPipe,
  ],
  imports: [
    TimepickerModule,
    DatetimePopupModule,
    CommonModule,
    PadresRoutingModule,
    FormsModule,
    ImageCropperModule,
    AngularFireStorageModule,
    NgbModalModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    NgxPaginationModule,
    NgxMaterialTimepickerModule,
    TranslateModule,
    ReactiveFormsModule,
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'es-ES' }],
})
export class PadresModule {}
