<!-- header -->
<header id="mainheader" style="background-color:{{background}}" >
    <div></div>
    <div class="saludo" >
  
      <ng-container *ngIf="nombres && apellidos">
        <div *ngIf="genero=='masculino'; else elsebox"><p translate>dashboard.header.bienvenido <span> {{nombres | titlecase }} {{apellidos | titlecase }}</span></p></div>
        <ng-template #elsebox> <p translate>dashboard.header.bienvenida<span>{{nombres | titlecase }} {{apellidos | titlecase }}</span></p></ng-template>
      </ng-container>
  
  
    </div>
    <div class="logo">
      <div class="logo" *ngIf="colegio.avatar!='';else elsebox" >
        <img src="{{avatar}}" routerLink="/padres" alt="">
      </div>
      <ng-template class="logo" #elsebox  >
        <img src="assets/img/logo.png" routerLink="/padres" alt="">
      </ng-template>
    </div>
  </header>