import { HttpClient, HttpParams } from '@angular/common/http';
import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ColegiosService } from '../services/colegios.service';
import { CursosService } from '../services/cursos.service';
import { ClasesService } from '../services/clases.service';
import { UserService } from '../services/user.service';
import { LicenciaService } from '../services/licencia.service';
import { AuthenticationService } from '../services/authentication.service';
import * as toxicity from '@tensorflow-models/toxicity';
import tf from '@tensorflow/tfjs';
import { AngularFireStorage } from '@angular/fire/storage';
import { ChatService } from '../services/chat.service';

@Component({
  selector: 'app-test123',
  templateUrl: './test123.component.html',
  styleUrls: ['./test123.component.css'],
})
export class Test123Component implements OnInit {
  @ViewChild('scriptContainer') scriptContainer: ElementRef;
  correos = [];

  cursos: any = [];
  clases: any = [];
  numerofinal: number = 0;
  numeroinicial: number = 0;

  threshold = 0.75;

  chatmessage = '';

  labelsToInclude = [
    'toxicity',
    'severe_toxicity',
    'identity_attack',
    'insult',
    'threat',
    'sexual_explicit',
  ];
  mensajes: any = [];
  chatorimessa: any;
  nombre = 'Pepe';
  apellidos = 'Fernandez';
  files = [];

  constructor(
    private http: HttpClient,
    private colegiosService: ColegiosService,
    private cursosService: CursosService,
    private clasesService: ClasesService,
    private usuariosService: UserService,
    private chatService: ChatService,
    private licenciasService: LicenciaService,
    private authenticationService: AuthenticationService,
    private storage: AngularFireStorage,
    private renderer: Renderer2
  ) {}

  // ngAfterViewInit() {
  //   try {
  //     const script = this.renderer.createElement('script');
  //     this.renderer.setAttribute(
  //       script,
  //       'src',
  //       'https://www.recordingandstreaming.com/live/js/9IDfJXZlosz57Zsl.js'
  //     );
  //     this.renderer.appendChild(this.scriptContainer.nativeElement, script);
  //   } catch (error) {
  //     console.log('ERRRROR', error);
  //   }
  // }

  ngOnInit(): void {
    // this.buscarCursos();
    // toxicity.load(this.threshold, this.labelsToInclude).then((model) => {
    //   // Now you can use the `model` object to label sentences.
    //   model.classify(["you' re a clown"]).then((predictions) => {
    //     predictions.forEach(element => {
    //       console.log(element.label + "->" , element.results[0].match)
    //     });
    //     // console.log(predictions);
    //   });
    // });
    // let body = document.body;
    // let script = document.createElement('script');
    // script.src =
    //   'https://www.recordingandstreaming.com/live/js/9IDfJXZlosz57Zsl.js';
    // let div = document.getElementById('video');
    // div.appendChild(script);
    // body.appendChild(script);
    // this.mensajes.push({
    //   nombre: 'Juan Perez',
    //   mensaje: 'Pr dfs df sobando',
    // });
    // this.mensajes.push({
    //   nombre: 'Juan Perez',
    //   mensaje: 'Pro fd fs dbando',
    // });
    // this.mensajes.push({
    //   nombre: 'Juan Perez',
    //   mensaje: 'Proba fa fsd dsf df sd fdfdsdf sd fs dfs ndo',
    // });
    // this.mensajes.push({
    //   nombre: 'Juan Perez',
    //   mensaje: 'Prando',
    // });
    // this.mensajes.push({
    //   nombre: 'Juan Perez',
    //   mensaje: 'Profds fd sd fdbando',
    // });

    // this.listFiles()

    
  }
  cambiarFinal(num) {
    this.numerofinal = num;
  }
  uploadFile(event: any) {
    const file = event.target.files[0];
    const filePath = `testImg/${file.name}`;
    const storageRef = this.storage.ref(filePath);
    storageRef.getDownloadURL().subscribe(
      (url) => {
        // El archivo existe
        // Preguntar al usuario si quiere reemplazarlo
        const shouldReplace = confirm(
          '¿Quieres reemplazar el archivo existente?'
        );
        if (shouldReplace) {
          // Reemplazar el archivo existente
          const task = this.storage.upload(filePath, file);
        } else {
          // No hacer nada
        }
      },
      (error) => {
        // El archivo no existe
        // Subir el nuevo archivo
        const task = this.storage.upload(filePath, file);
      }
    );
  }

  sendmessagePrueba() {
    if (this.chatmessage != '') {
      this.mensajes.push({
        mensaje: this.chatmessage,
        nombre: 'Pepe',
      });
      this.chatmessage = '';
    }
  }




  listFiles() {
    this.files = [];
    const ref = this.storage.ref('posterColegia');
  
    ref.listAll().subscribe(result => {
      result.items.forEach(fileRef => {
        console.log(fileRef)
        fileRef.getDownloadURL().then((url)=>{
          this.files.push({ name: fileRef.name, url: url });
        })  
      });
    });
    this.files.sort((a, b) => a.name.localeCompare(b.name));

    return this.files;
  }




  getmessages() {
    const chatclassid = 'AUDITORIO1-MYF';
    this.chatService.getClaseMessages(chatclassid).subscribe((message) => {
      this.chatorimessa = message;
      // this.chatorimessa.forEach((messageData: any) => {
      //   this.userchat = messageData.payload.doc.data().sender;
      //   if (!this.chatusers[this.userchat.uid]) {
      //     this.chatusers[this.userchat.uid] = userchat[0].payload.doc.data();
      //   }
      // });
    });
  }

  sendmessage() {
    if (this.nombre != '' && this.apellidos != '') {
      const message = {
        sender: this.nombre + ' ' + this.apellidos,
        message: this.chatmessage,
        fecha: new Date(),
      };
      const chatclassid = 'AUDITORIO1-MYF';
      this.chatService
        .sendmessageclase(chatclassid, message)
        .then((datames) => {
          this.chatmessage = '';
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  scrolltobottom() {
    const objDiv = document.getElementById('themessagesbox');
    objDiv.scrollTop = objDiv.scrollHeight + 1000;
  }

  crearClase() {
    /*
    
00LLEEXpOAAAKAsZJbka
Llévate el éxito
    
000LlEEx0nuWst4AJQeN
3er encuentro educativo

https://firebasestorage.googleapis.com/v0/b/the-campus-prod.appspot.com/o/avatars%2FLOGO-LLEVATE-EL-EXITO2.png?alt=media&token=b55a3543-026e-47d7-89bb-f2abdd21a2dd
  

    000LlElEx00000001
    000LlElEx00000002
    000LlElEx00000003
    000LlElEx00000004
    000LlElEx00000005
    000LlElEx00000006
    000LlElEx00000007
    000LlElEx00000008
    000LlElEx00000009
    000LlElEx00000010
    000LlElEx00000011
    000LlElEx00000012
    000LlElEx00000013
    000LlElEx00000014
    000LlElEx00000015
    000LlElEx00000016
    000LlElEx00000017
    
    
    */
  }

  deleteEmail() {
    console.log('--Comenzamos--' + this.correos.length);

    if (this.numerofinal > this.correos.length) {
      this.numerofinal = this.correos.length;
    }
    console.log('NUMERO inicial ' + this.numeroinicial);
    console.log('NUMERO FINAL ' + this.numerofinal);
    for (let index = this.numeroinicial; index < this.numerofinal; index++) {
      const correo = this.correos[index];
      let contador = index + 1;
      // console.log(contador + '. ' + correo);
      // this.eliminarUsuario(correo)
      //   .then((res) => {
      //     console.log('eliminado', res);
      //     console.log(contador);
      //   })
      //   .catch(() => {
      //     console.log('ya estaba eliminado');
      //     console.log(contador);
      //   });
    }
    console.log('--Terminamos--');
  }

  // async eliminarUsuario(email) {
  //   console.log('borrando: ' + email);
  //   try {
  //     return await this.http
  //       .get<any>(
  //         'https://us-central1-the-campus-prod.cloudfunctions.net/delUserEmail',
  //         { params: new HttpParams().set('email', email) }
  //       )
  //       .subscribe((element) => {
  //         console.log('me devuelven esto', element);
  //       });
  //   } catch (error) {console.log("Error al borrar: " + email)}
  // }

  // buscarCursos() {
  //   this.colegiosService
  //     .getColegioByIdNow('qKQCtDaQ5GRP8wsJVVsi')
  //     .subscribe((cole) => {
  //       this.cursos = cole.data().cursos;
  //       this.verCursos();
  //     });
  // }

  eliminarCursos() {
    for (let index = 0; index < this.cursos.length; index++) {
      const curso = this.cursos[index];
      console.log('ID curso: ' + curso);
      // this.eliminarCurso(curso);
    }
  }

  verCursos() {
    for (let index = 0; index < this.cursos.length; index++) {
      const curso = this.cursos[index];
      console.log('ID curso: ' + curso);
    }
  }

  buscarClases() {
    for (let index = 0; index < this.cursos.length; index++) {
      const curso = this.cursos[index];
      this.cursosService.getCursosById(curso).subscribe((cursodato: any) => {
        this.clases = cursodato.payload.data().clases;
        this.verClases(this.cursos[index]);
      });
    }

    // this.cursosService.getCursosById("0CuUbDmBjWs1cUwGQBSE").subscribe((cursodato: any) => {
    //   this.clases = cursodato.payload.data().clases;
    //   this.verClases("0CuUbDmBjWs1cUwGQBSE");
    // });

    // this.eliminarCurso("0CuUbDmBjWs1cUwGQBSE");
  }
  verClases(curso) {
    console.log('clases totales: ' + this.clases.length);
    console.log('CURSO------->' + curso);
    for (let index = 0; index < this.clases.length; index++) {
      try {
        const clase = this.clases[index];
        let contador = index + 1;
        console.log(contador + '. ' + clase);
        // this.eliminarClase(clase)
      } catch (error) {
        console.error('---- ¡¡¡ Hubo fallo !!! ----' + error);
      }
    }
  }
  // pruebaEliminarClase(){
  //   this.eliminarClase("dlasjdlaskiasidua98sc12uu")
  // }

  eliminarClase(clase) {
    this.clasesService
      .DeleteClass(clase)
      .then(() => {
        console.log('ELIMINADA LA CLASE: ' + clase);
      })
      .catch((error) => {
        console.error(
          '---- ¡¡¡ Hubo fallo al borrar la clase !!! ----' + error
        );
      });
  }

  eliminarCurso(curso) {
    this.cursosService
      .deleteCurso(curso)
      .then(() => {
        console.log('ELIMINADA LA CLASE: ' + curso);
      })
      .catch((error) => {
        console.error(
          '---- ¡¡¡ Hubo fallo al borrar el curso !!! ----' + error
        );
      });
  }

  // descargarZIP() {
  //   const url =
  //     'https://us-central1-the-campus-56b06.cloudfunctions.net/app/createZip';
  //     fetch(url)
  //     .then((response) => response.blob())
  //     .then((blob) => {
  //       const url = window.URL.createObjectURL(blob);
  //       const a = document.createElement('a');
  //       a.style.display = 'none';
  //       a.href = url;
  //       a.download = 'posters.zip';
  //       document.body.appendChild(a);
  //       a.click();
  //       window.URL.revokeObjectURL(url);
  //     })
  //     .catch((error) => {
  //       console.error(`Error: ${error}`);
  //     });
  // }

  // estanUsuariosCodigo() {
  //   for (let index = 0; index < this.codigos.length; index++) {
  //     const codigo = this.codigos[index];
  //     this.usuariosService.getUserCodigo(codigo).subscribe((dato) => {
  //       // dato.docs[0].exists
  //       if (dato.size == 0) {
  //         console.log(index + ' ' + codigo);
  //         this.codigosNoEstan.push(codigo);
  //       } else {
  //       }
  //     });
  //   }
  // }

  // crearLicencias() {

  //   for (let index = 0; index < this.licencias.length; index++) {
  //     const element = this.licencias[index];
  //     var licen = {
  //       codigo: element,
  //       colegio: 'GicCEClIcYiFhACkwAll',
  //       usado: false,
  //     };
  //     this.licenciasService.createdLicencia(licen).then((da)=>{
  //       console.log("licencia", licen)
  //     });

  //   }
  // }
}
