import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LicenciaService } from '../services/licencia.service';
@Component({
  selector: 'app-academicaredirect',
  templateUrl: './academicaredirect.component.html',
  styleUrls: ['./academicaredirect.component.css'],
})
export class AcademicaredirectComponent implements OnInit {
  nombre: string;

  listasalas = [
    // { nombre: 'anna', sala: 'apujol@academica.school' },
    // { nombre: 'cristina', sala: 'cbenito@academica.school' },
    // { nombre: 'carme', sala: 'cpratdesaba@academica.school' },
    // { nombre: 'gloria', sala: 'gheredia@academica.school' },
    // { nombre: 'juancarlos', sala: 'jcdelosmozos@academica.school' },
    // { nombre: 'juanmiguel', sala: 'jmcomas@academica.school' },
    // { nombre: 'mariacampos', sala: 'mcampos@academica.school' },
    // { nombre: 'maged', sala: 'mgeorge@academica.school' },
    // { nombre: 'mar', sala: 'mmulet@academica.school' },
    // { nombre: 'mariapieras', sala: 'mpieras@academica.school' },
    // { nombre: 'paula', sala: 'pquecedo@academica.school' },
    // { nombre: 'susana', sala: 'spardo@academica.school' },
    // { nombre: 'teresa', sala: 'tmulet@academica.school' },
    // { nombre: 'virginia', sala: 'virginia@academica.school' },
    // { nombre: 'joseluis', sala: 'jlmartinez@academica.org' }
  ];
  noexiste: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private licenciaService: LicenciaService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.noexiste = false;
      var nombre = params['nombre'];
      this.nombre = nombre.toLowerCase();

      let usuario = this.listasalas.find(
        (element) => element.nombre == this.nombre
      );
      if (usuario) {
        location.href =
          'https://in2.thecampus.education/webapp3/#/?conference=' +
          usuario.sala +
          '&role=guest&callType=video';
      } else {
        setTimeout(() => {
          this.noexiste = true;
        }, 2000);
      }
    });
    //tuppercase

    // location.href =
    //   'https://in.thecampus.education/webapp/#/?conference=' +
    //   this.sala +
    //   '&role=guest&callType=video';

    // this.crearLicencias();
  }

  // crearLicencias() {
  //   let arrayNumeros = [];
  //   for (let index = 0; index < 10; index++) {
  //     let random = Math.floor(Math.random() * 9000) + 1000;
  //     let numeroString = "MYF"+random.toString();
  //     if (!arrayNumeros.includes(random)) {
  //       arrayNumeros.push(random);
  //       let licencia = {
  //         codigo: numeroString,
  //         colegio: '0MTFJvw88G9UdRdrq69q',
  //         usado: false,
  //       };
  //       console.log(numeroString);
  //       // this.licenciaService.createdLicencia(licencia)
  //     }
  //   }
  //   console.log(arrayNumeros);

  // }
}
