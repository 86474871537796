<!-- clase box -->
<div class="subbasicbox chat">
    <div class="boximage">
      <div class="notificaciones" *ngIf="totalNotificaciones>0">
        {{totalNotificaciones}}
      </div> 
        <a routerLink="/padres/mensajes/chat/{{chaturl}}">
  
            <img *ngIf="theuser?.avatar else defaultheadavatar" src="{{theuser?.avatar}}" alt="{{theuser?.nombres | titlecase}} {{theuser?.apellidos | titlecase}}">
            <ng-template #defaultheadavatar>
              <img src="assets/img/defaultavatar.jpg" alt="{{theuser?.nombres | titlecase}} {{theuser?.apellidos | titlecase}}">
            </ng-template>
  
            <div class="status" [ngClass]="{
              'online': presencia === 'online',
              'away':  presencia === 'away',
              '':  presencia === 'offline'
            }">
            </div>
  
  
        </a>
    </div>
    <div class="boxtitles">
          <h4>{{theuser?.nombres | titlecase}} {{theuser?.apellidos | titlecase}}</h4>
          <p><!--<app-presencia [mainuid]="userUid" [members]="chat.members" [actividad]="true" ></app-presencia>--></p>
          <ng-container *ngIf="tiempoDesconexion">
            <p translate [translateParams]="{tiempoDesconexion:tiempoDesconexion}"> dashboard.clase.editarC.activo</p>
          </ng-container>
  
    </div>
    <div>
      <div class="boxchatvideocall">
  
        <a href="https://in2.thecampus.education/webapp3/#/?conference={{theuser?.usuario | titlecase}}&name={{themainuser_nombre | titlecase}} {{themainuser_apellidos | titlecase}}&role=guest&callType=video&join=1" target="_blank">
          <img src="assets/img/videocall.svg" >
        </a>
  
  
      </div>
  
    </div>
  
    <div class="boxdata">
          <div class="lastchat">
  
                <p>{{ LastChatMessage?.message | slice:0:13 }}</p>
  
          </div>
    </div>
  
    <div class="boxvideocall">
            <div class="videocall">
              <a routerLink="/padres/mensajes/chat/{{chaturl}}">
                <img src="/assets/img/chatbox-b.svg">
              </a>
            </div>
    </div>
  </div>
  <!--/clase box -->
  