<!-- dashboard -->
<div class="dashboard">
  <div class="fila1">
    <div class="fila3">
      <section class="basicbox horojo wplus medium fullheight mismensajes">
        <header>
          <h3 translate>dashboard.home.mensajes</h3>
          <div>
            <!-- <div
              class="plusbutton"
              routerLink="/padres/mensajes/"
              (click)="
                UpdateBreadcrumb('dashboard.home.mensajes', '/padres/mensajes/')
              "
            >
              +
            </div> -->
          </div>
          <div
            class="icon"
            routerLink="/padres/mensajes/"
            (click)="
              UpdateBreadcrumb('dashboard.home.mensajes', '/padres/mensajes/')
            "
          >
            <div>
              <ng-container *ngIf="conteomensajes > 0">
                <div class="notificationnumber">
                  {{ conteomensajes }}
                </div>
              </ng-container>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
                <g
                  id="Grupo_9488"
                  data-name="Grupo 9488"
                  transform="translate(-2 -2)"
                >
                  <path
                    id="Trazado_9284"
                    data-name="Trazado 9284"
                    d="M2.831,41.87a1.431,1.431,0,0,0,1.527-.214l6.79-5.82a10.012,10.012,0,0,1,6.507-2.407h18.63A5.72,5.72,0,0,0,42,27.714v-20A5.72,5.72,0,0,0,36.286,2H7.714A5.72,5.72,0,0,0,2,7.714V40.571A1.432,1.432,0,0,0,2.831,41.87ZM4.857,7.714A2.86,2.86,0,0,1,7.714,4.857H36.286a2.86,2.86,0,0,1,2.857,2.857v20a2.86,2.86,0,0,1-2.857,2.857H17.656a12.867,12.867,0,0,0-8.367,3.1l-4.431,3.8Z"
                    transform="translate(0 0)"
                    fill="#23273b"
                  />
                  <rect
                    id="Rectángulo_3102"
                    data-name="Rectángulo 3102"
                    width="14"
                    height="4"
                    transform="translate(15 16)"
                    fill="#23273b"
                  />
                </g>
              </svg>
            </div>
          </div>
        </header>

        <article class="users">
          <app-userbox
            *ngFor="let chat of chats"
            [mainuid]="userUid"
            [members]="chat.members"
            [chatusers]="chatusers"
            [grupodechats]="grupodechats"
            [nombre]="nombres"
            [apellidos]="apellidos"
          ></app-userbox>
        </article>

        <footer>
          <a
            routerLink="/padres/mensajes/"
            class="button medium center"
            (click)="
              UpdateBreadcrumb('dashboard.home.mensajes', '/padres/mensajes/')
            "
          >
            {{ "dashboard.home.mensajes" | translate }}
          </a>
        </footer>
      </section>
    </div>

    <div class="fila4">
      <div>
        <section class="basicbox horojo appsbox">
          <header>
            <h3 translate>dashboard.home.appInternas</h3>
            <!-- <div class="icon">
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
                  <path
                    id="Trazado_9290"
                    data-name="Trazado 9290"
                    d="M8.5,23.592H20.088a3.509,3.509,0,0,0,3.5-3.5V8.5a3.509,3.509,0,0,0-3.5-3.5H8.5A3.509,3.509,0,0,0,5,8.5V20.088A3.508,3.508,0,0,0,8.5,23.592ZM7.222,8.5A1.284,1.284,0,0,1,8.5,7.222H20.088A1.284,1.284,0,0,1,21.371,8.5V20.088a1.284,1.284,0,0,1-1.283,1.282H8.5a1.284,1.284,0,0,1-1.283-1.282Zm22.69,15.088H41.5a3.508,3.508,0,0,0,3.5-3.5V8.5A3.508,3.508,0,0,0,41.5,5H29.912a3.508,3.508,0,0,0-3.5,3.5V20.088A3.508,3.508,0,0,0,29.912,23.592ZM28.63,8.5a1.284,1.284,0,0,1,1.282-1.282H41.5A1.284,1.284,0,0,1,42.778,8.5V20.088A1.283,1.283,0,0,1,41.5,21.37H29.912a1.283,1.283,0,0,1-1.282-1.282ZM8.5,45H20.088a3.509,3.509,0,0,0,3.5-3.5V29.912a3.509,3.509,0,0,0-3.5-3.5H8.5a3.509,3.509,0,0,0-3.5,3.5V41.5A3.509,3.509,0,0,0,8.5,45ZM7.222,29.912A1.284,1.284,0,0,1,8.5,28.629H20.088a1.284,1.284,0,0,1,1.283,1.283V41.5a1.284,1.284,0,0,1-1.283,1.282H8.5A1.284,1.284,0,0,1,7.222,41.5ZM29.912,45H41.5A3.508,3.508,0,0,0,45,41.5V29.912a3.509,3.509,0,0,0-3.5-3.5H29.912a3.509,3.509,0,0,0-3.5,3.5V41.5A3.508,3.508,0,0,0,29.912,45ZM28.63,29.912a1.284,1.284,0,0,1,1.282-1.283H41.5a1.284,1.284,0,0,1,1.282,1.283V41.5A1.284,1.284,0,0,1,41.5,42.778H29.912A1.284,1.284,0,0,1,28.63,41.5Z"
                    transform="translate(-5 -5)"
                    fill="#23273b"
                  />
                </svg>
              </div>
            </div> -->
          </header>
          <article>
            <!-- box -->
            <div class="subbasicbox apps">
              <div *ngIf="this.colegio?.codigo_colegio === 'demo_arcol'">
                <a
                  href="https://eva.edu.xunta.gal"
                  target="_blank"
                  title="Edixgal"
                >
                  <img id="" src="assets/img/edixgal.png" />
                </a>
              </div>

              <div>
                <a
                  routerLink="/padres/appsint/mygofacing.web.app/"
                  (click)="
                    UpdateBreadcrumb(
                      'Mygofacing',
                      '/padres/appsint/mygofacing.web.app/'
                    )
                  "
                  title="Mygofacing"
                >
                  <img id="go" src="assets/img/gofacing.png" />
                </a>
              </div>

              <div>
                <a
                  routerLink="/padres/appsint/laiatech.com/"
                  (click)="
                    UpdateBreadcrumb(
                      'Laia Tech',
                      '/padres/appsint/laiatech.com/'
                    )
                  "
                  title="Laiatech"
                >
                  <img src="assets/img/laia.png" />
                </a>
              </div>

              <div>
                <a href="https://docs.zoho.com" target="_blank" title="Docs">
                  <img src="assets/img/docs.png" />
                </a>
              </div>

              <div>
                <a
                  href="https://accounts.zoho.eu/signin?servicename=ZohoCRM&signupurl=https://www.zoho.eu/crm/signup.html?plan=enterprise"
                  target="_blank"
                  title="CRM"
                >
                  <img src="assets/img/crm.png" />
                </a>
              </div>

              <div>
                <a
                  href="https://accounts.zoho.eu/signin?servicename=ZohoCampaigns&signupurl=https://www.zoho.eu/campaigns/signup.html"
                  target="_blank"
                  title="Campaigns"
                >
                  <img src="assets/img/campaigns.png" />
                </a>
              </div>

              <div>
                <a
                  href="https://forms.zoho.com/GoFacing/home#myforms"
                  target="_blank"
                  title="Forms"
                >
                  <img src="assets/img/forms.png" />
                </a>
              </div>

              <div>
                <a
                  href="https://accounts.zoho.eu/signin?servicename=VirtualOffice&signupurl=https://workplace.zoho.eu/orgsignup.do?signup.html"
                  target="_blank"
                  title="Email"
                >
                  <img src="assets/img/email.png" />
                </a>
              </div>

              <div>
                <a
                  href="https://desk.zoho.com/"
                  target="_blank"
                  title="Soporte"
                >
                  <img src="assets/img/soporte.png" />
                </a>
              </div>

              <div>
                <a
                  href="https://accounts.zoho.com/signin?servicename=ZohoInventory&signupurl=https://www.zoho.com/inventory/signup/"
                  target="_blank"
                  title="Inventario"
                >
                  <img src="assets/img/inventario.png" />
                </a>
              </div>

              <div>
                <a
                  href="https://accounts.zoho.com/signin?servicename=ZohoBooks&signupurl=https://www.zoho.com/books/signup/"
                  target="_blank"
                  title="Books"
                >
                  <img src="assets/img/books.png" />
                </a>
              </div>
            </div>
            <!--/box -->
          </article>
        </section>
      </div>
      <div>
        <section class="basicbox horojo appsbox">
          <header>
            <h3 translate>dashboard.home.appExternas</h3>
            <!-- <div class="icon">
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
                  <path
                    id="Trazado_9290"
                    data-name="Trazado 9290"
                    d="M8.5,23.592H20.088a3.509,3.509,0,0,0,3.5-3.5V8.5a3.509,3.509,0,0,0-3.5-3.5H8.5A3.509,3.509,0,0,0,5,8.5V20.088A3.508,3.508,0,0,0,8.5,23.592ZM7.222,8.5A1.284,1.284,0,0,1,8.5,7.222H20.088A1.284,1.284,0,0,1,21.371,8.5V20.088a1.284,1.284,0,0,1-1.283,1.282H8.5a1.284,1.284,0,0,1-1.283-1.282Zm22.69,15.088H41.5a3.508,3.508,0,0,0,3.5-3.5V8.5A3.508,3.508,0,0,0,41.5,5H29.912a3.508,3.508,0,0,0-3.5,3.5V20.088A3.508,3.508,0,0,0,29.912,23.592ZM28.63,8.5a1.284,1.284,0,0,1,1.282-1.282H41.5A1.284,1.284,0,0,1,42.778,8.5V20.088A1.283,1.283,0,0,1,41.5,21.37H29.912a1.283,1.283,0,0,1-1.282-1.282ZM8.5,45H20.088a3.509,3.509,0,0,0,3.5-3.5V29.912a3.509,3.509,0,0,0-3.5-3.5H8.5a3.509,3.509,0,0,0-3.5,3.5V41.5A3.509,3.509,0,0,0,8.5,45ZM7.222,29.912A1.284,1.284,0,0,1,8.5,28.629H20.088a1.284,1.284,0,0,1,1.283,1.283V41.5a1.284,1.284,0,0,1-1.283,1.282H8.5A1.284,1.284,0,0,1,7.222,41.5ZM29.912,45H41.5A3.508,3.508,0,0,0,45,41.5V29.912a3.509,3.509,0,0,0-3.5-3.5H29.912a3.509,3.509,0,0,0-3.5,3.5V41.5A3.508,3.508,0,0,0,29.912,45ZM28.63,29.912a1.284,1.284,0,0,1,1.282-1.283H41.5a1.284,1.284,0,0,1,1.282,1.283V41.5A1.284,1.284,0,0,1,41.5,42.778H29.912A1.284,1.284,0,0,1,28.63,41.5Z"
                    transform="translate(-5 -5)"
                    fill="#23273b"
                  />
                </svg>
              </div>
            </div> -->
          </header>
          <article>
            <!-- box -->
            <div class="subbasicbox apps">
              <!--<ng-container *ngFor="let app of colegioapps_externas; let i= index">
  
  
                         <div>
                           <a href="{{app.url}}" target="_blank">
                            <img *ngIf="app.icono else defaultappicono" src="{{app.icono}}" alt="{{app.nombre}}">
                            <ng-template #defaultappicono>
                              <img src="assets/img/defaultapp.jpg" alt="{{app.nombre}}">
                            </ng-template>
                           </a>
                         </div>
  
  
                       </ng-container>-->

              <div>
                <a href="https://asana.com/" target="_blank" title="Asana">
                  <img src="assets/img/asana.png" />
                </a>
              </div>

              <div>
                <a
                  href="https://calendar.google.com/"
                  target="_blank"
                  title="GCalendar"
                >
                  <img src="assets/img/gcalendar.png" />
                </a>
              </div>

              <div>
                <a
                  href="https://drive.google.com/"
                  target="_blank"
                  title="Gdrive"
                >
                  <img src="assets/img/gdrive.png" />
                </a>
              </div>

              <div>
                <a
                  href="https://www.apple.com/co/itunes/"
                  target="_blank"
                  title="iTunes"
                >
                  <img src="assets/img/itunes.png" />
                </a>
              </div>

              <div>
                <a
                  href="https://mailchimp.com/"
                  target="_blank"
                  title="Mailchimp"
                >
                  <img src="assets/img/mailchimp.png" />
                </a>
              </div>

              <div>
                <a
                  href="https://outlook.live.com"
                  target="_blank"
                  title="Outlook"
                >
                  <img src="assets/img/outlook.png" />
                </a>
              </div>

              <div>
                <a href="https://slack.com/" target="_blank" title="Slack">
                  <img src="assets/img/slack.png" />
                </a>
              </div>

              <div>
                <a
                  href="https://www.youtube.com/"
                  target="_blank"
                  title="Youtube"
                >
                  <img src="assets/img/youtube.png" />
                </a>
              </div>
            </div>
            <!--/box -->
          </article>
        </section>
      </div>
    </div>
  </div>
  <!-- /dashboard -->
</div>
