import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class BannerService {

  constructor(private firestore: AngularFirestore) { }

  getBanners() {
    return this.firestore.collection('banners').snapshotChanges();
  }
  getBannerById(id) {
    return this.firestore.collection('banners').doc(id).snapshotChanges();
  }

  getBannerByColegio(idColegio) {
    return this.firestore.collection('banners', ref => ref.where('colegio', '==' , idColegio)).snapshotChanges();
  }

  createBanner(banner) {
    return this.firestore.collection('banners').add(banner);
  }

  editBanner(id, banner) {
    return this.firestore.collection('banners').doc(id).update(banner);
  }

  deleteBanner(id){
    return this.firestore.collection('banners').doc(id).delete();
  }

}
