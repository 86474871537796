<div class="cuerpo" id="video">
    <div *ngIf="!mostrar" class="pinsession">
      <div class="titulo">
        <h3>Introduce contraseña</h3>
      </div>
      <input
        [(ngModel)]="pin"
        class="pass"
        type="password"
        placeholder="{{ 'login.contraseña' | translate }}"
        (keyup.enter)="comprobarPin()"
      />
      <button class="botonacceso" (click)="comprobarPin()" >ACCEDER</button>
      <div class="rembox">
        <div class="rememberme">
          <div
            [ngClass]="{ checked: remember }"
            class="checkbox"
            (click)="rememberme()"
          ></div>
          <p (click)="rememberme()" translate>login.recordarme</p>
        </div>
      </div>
      <p>{{mensajeError}}</p>
    </div>
    <div *ngIf="mostrar" class="cuerpo">
      <div class="titulo2">
        <h3>Pulse en cada archivo para descargar</h3>
        <button  class="botonolvidar" (click)="descargarTodos()">Descargar todos los archivos</button>
        <button *ngIf="remember" class="botonolvidar" (click)="olvidarcredenciales()">Olvidar credencial</button>
      </div>
      <div class="archivos">
        <div class="fichero" *ngFor="let file of files">
          -
          <a
            [href]="file.url"
            target="_blank"
            download="{{ file.name }}"
            title="{{ file.name }}"
            >{{ file.name }}</a
          >
        </div>
      </div>
    </div>
  </div>
  