import { CursosService } from './../../services/cursos.service';
import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { ClasesService } from '../../services/clases.service';
import { Clases } from '../../interfaces/clases';
import { UserService } from '../../services/user.service';
import * as firebase from 'firebase/app';
import { CodigopadresService } from '../../services/codigopadres.service';

@Component({
  selector: 'app-padres',
  templateUrl: './padres.component.html',
  styleUrls: ['./padres.component.css'],
})
export class PadresComponent implements OnInit {
  inputCodigo = false;
  email: string = '';
  padres = [];
  codigosEnUso=[]

  userdata: any;
  misclases = [];
  userUid: any;
  clase: Clases;
  clasecontador: number;
  searchValue: string;
  clicked = false;
  borrar: boolean = false;
  uidBorrar: string = '';

  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private cursosService: CursosService,
    private codigopadresService: CodigopadresService,
    private clasesService: ClasesService
  ) {}

  ngOnInit(): void {
    const Userdata: any = this.userService.getUserinfodata();

    if (Userdata === undefined) {
      this.getmainuserdata();
    } else {
      this.userUid = Userdata.payload.doc.data().uid; /* id del usuario */
      this.userdata = Userdata.payload.doc.data();
      // this.getClases(this.userdata); /* llama a la funcion para obtener las clases del usuario */
      this.getPadres(this.userdata);
    }
  }

  /* trae informacion principal del usuario logueado */
  getmainuserdata() {
    this.authenticationService.isAuth().subscribe((auth) => {
      if (auth) {
        this.userUid = auth.uid; /* id del usuario */

        this.authenticationService
          .isUserAdmin(this.userUid)
          .subscribe((userdata) => {
            if (userdata[0]) {
              this.userdata = userdata[0];
              // this.getClases(this.userdata); /* llama a la funcion para obtener las clases del usuario */

              this.getPadres(this.userdata);
            }
          });
      }
    });
  }

  getPadres(userdata) {
    if (userdata && userdata.padres.length > 0) {
      console.log(userdata);
      userdata.padres.forEach((element) => {
        this.userService.getUserByIdNow(element).subscribe((padre) => {
          var padreDato = {
            id: padre.docs[0].id,
            datos: padre.docs[0].data(),
          };
          this.padres.push(padreDato);
        });
      });
    }
  }

  /* obtiene las clases al que pertenezca el usuario logueado */
  // getClases(userdata) {

  //   if (userdata && userdata.clases[0]){

  //     this.misclases = [];
  //     userdata.clases.forEach((clase: any) => {

  //       this.clasesService.getClaseById(clase).subscribe(laclase => {
  //         if (laclase) {

  //           this.clase = laclase.payload.data();

  //           this.misclases.push({
  //             uid: clase,
  //             administradores: this.clase.administradores,
  //             cursos: this.clase.cursos,
  //             colegio: this.clase.colegio,
  //             nombre: this.clase.nombre,
  //             imagen: this.clase.imagen,
  //             usuarios: this.clase.usuarios,
  //             colegio_nombre: this.clase.colegio_nombre,
  //             curso_nombre: this.clase.curso_nombre,
  //           });

  //           this.clasecontador = this.misclases.length;
  //         }
  //       });

  //     });

  //   }

  // }

  DeleteClass(classuid) {
    console.log(classuid);

    let searchusersbyclass = this.userService
      .getUsersbyClass(classuid)
      .subscribe((resulusers) => {
        if (resulusers) {
          resulusers.forEach((eluser) => {
            const clases = {
              clases: firebase.firestore.FieldValue.arrayRemove(classuid),
            };
            this.userService
              .editUser(clases, eluser.payload.doc.id)
              .then((edit) => {
                console.log(edit);
                this.uidBorrar = '';
              })
              .catch((error) => {
                console.log(error);
              });
          });
        }
        searchusersbyclass.unsubscribe();
      });

    let searchcursosbyclass = this.cursosService
      .getCursosByClassid(classuid)
      .subscribe((resulcurso) => {
        if (resulcurso) {
          resulcurso.forEach((elcurso) => {
            const clases = {
              clases: firebase.firestore.FieldValue.arrayRemove(classuid),
            };
            this.cursosService
              .editCurso(elcurso.payload.doc.id, clases)
              .then((edit) => {
                console.log(edit);
              })
              .catch((error) => {
                console.log(error);
              });
          });
        }
        searchcursosbyclass.unsubscribe();
      });

    this.clasesService.DeleteClass(classuid).then((respondel) => {
      this.clicked = false;
    });
  }

  borrarPop(classuid) {
    this.uidBorrar ? (this.uidBorrar = '') : (this.uidBorrar = classuid);
  }

  abrirInputCodigo() {
    this.inputCodigo = !this.inputCodigo;
  }



  generarCodigo() {
    let subscription = this.codigopadresService
      .searchCodigoUsuarioSinUso(this.userUid)
      .subscribe((codigos) => {
        if (this.userdata.padres.length + codigos.length > 1) {
          console.log("DAMASIADOS PADRES")
          codigos.forEach((codigo) => {
            this.codigosEnUso.push({
              id:codigo.payload.doc.id,
              datos:codigo.payload.doc.data()
            })
            console.log(codigo.payload.doc.data());
          });
        } else {
          var codigoAleatorio = Math.random()
            .toString(36)
            .toUpperCase()
            .substring(2, 10);
          var datos = {
            codigo: codigoAleatorio,
            hijo: this.userUid,
            usado: false,
          };
          this.codigopadresService.crearCodigo(datos)
        }
        // Desuscribirse después de obtener los datos
        subscription.unsubscribe();
      });
  }
}
