import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ClasesService } from 'src/app/services/clases.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-hijo',
  templateUrl: './hijo.component.html',
  styleUrls: ['./hijo.component.css'],
})
export class HijoComponent implements OnInit {
  urlid: any;
  datos: any;
  clases: any = [];
  nombre = '';
  usuario: any = '';
  userUid: any ="";

  constructor(
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private clasesService: ClasesService,
    private authenticationService: AuthenticationService,
  ) {}

  ngOnInit(): void {
    this.urlid = this.activatedRoute.snapshot.params['uid'];

    this.userService.getUserById(this.urlid).subscribe((usuario) => {
      var datos: any = usuario[0].payload.doc.data();
      this.nombre = datos.nombres + ' ' + datos.apellidos;
      this.usuario = datos.usuario;
    });
    this.clasesService.getUserClass(this.urlid).subscribe((clases) => {
      this.clases=[]
      clases.forEach((clase: any) => {
        var clasedatos: any = { nombre: '', administradores: [] };
        this.clases.push(clase.payload.doc.data());
        // clase.payload.doc.data().administradores.forEach(administrador => {
        //   this.userService.getUserById(administrador).subscribe((profesor)=>{
        //     this.datos = profesor[0].payload.doc.data()
        //     clasedatos.administradores
        //   })
        // });
      });
    });

    const Userdata: any = this.userService.getUserinfodata();

    if (Userdata === undefined) {
      this.authenticationService.isAuth().subscribe((auth) => {
        if (auth) {
          this.userUid = auth.uid;
        }
      });

      // this.getCurrentUser();
    } else {
      this.userUid = Userdata.payload.doc.data().uid;
    }
  }
}
