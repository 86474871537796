
<div class="userchat">
    <!-- clase box -->
    <div class="subbasicbox chat headinguser">
  
  
        <div class="boximage">
            <a href="">
  
                <img *ngIf="chatusers[elotrousaurio]?.avatar else defaultheadavatar" src="{{chatusers[elotrousaurio]?.avatar}}" alt="">
                <ng-template #defaultheadavatar><img src="assets/img/defaultavatar.jpg" alt=""></ng-template>
  
                <!--<div class="status"></div>-->
            </a>
        </div>
        <div class="boxtitles">
              <h4><a href="">{{chatusers[elotrousaurio]?.nombres}} {{chatusers[elotrousaurio]?.apellidos}}</a></h4>
              <!--<p class="colegio">{{chatusers[elotrousaurio].colegios_name[0]}}</p>
              <p class="curso">{{chatusers[elotrousaurio].clases_name[0]}}</p>-->
  
        </div>
        <div>
            <div class="boxchatvideocall">
                <a href="https://in2.thecampus.education/webapp3/#/?conference={{chatusers[elotrousaurio]?.usuario}}&name={{mainuseremail}}&join=1&role=guest&callType=video" target="_blank">
                  <img src="assets/img/videocall.svg" alt="">
                </a>
            </div>
        </div>
  
        <div class="boxdata">
  
        </div>
  
        <div class="boxvideocall "></div>
  
  
    </div>
    <!--/clase box -->
  
  
  <section class="basicbox2 chatmessages">
  
      <div class="messages" id="themessagesbox" [scrollTop]="scrolltobottom()">
  
        <ng-container *ngFor="let messages of grupodechats[urluid];">
  
          <!-- message -->
          <div class="message">
            <div class="avatar">
  
                <img *ngIf="chatusers[messages?.payload.doc.data().sender]?.avatar else defaultavatar" src="{{chatusers[messages?.payload.doc.data().sender]?.avatar}}" alt="{{chatusers[messages?.payload.doc.data().sender]?.nombres}} {{chatusers[messages?.payload.doc.data().sender]?.apellidos}}">
                <ng-template #defaultavatar><img src="assets/img/defaultavatar.jpg" alt=""></ng-template>
  
                <!--<div class="status"></div>-->
            </div>
            <div class="messagedata">
                 <div class="name">
                    {{chatusers[messages?.payload.doc.data().sender]?.nombres}} {{chatusers[messages?.payload.doc.data().sender]?.apellidos}}
                 </div>
                 <div class="date">
                  {{ messages?.payload.doc.data().fecha.toDate() | date: 'dd/MM/y - HH:mm' }}h
                 </div>
                 <div class="themessage">
                     <p>{{messages?.payload.doc.data().message}}</p>
                 </div>
            </div>
        </div>
        <!-- /message -->
  
        </ng-container>
  
      </div>
      <div class="sendbox">
          <input class="messagebox" type="text" placeholder="Escribir mensaje…." [(ngModel)]="chatmessage" (keyup.enter)="sendmessage()">
          <input class="sendmessage" type="submit" value="Enviar Mensaje" (click)="sendmessage()">
      </div>
  
  </section>
  </div>
  