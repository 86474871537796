import { AppsService } from '../../services/apps.service';
import { ColegiosService } from '../../services/colegios.service';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { App } from '../../interfaces/app';
import { ChatService } from './../../services/chat.service';
import { UserService } from './../../services/user.service';
import { EventosService } from './../../services/eventos.service';
import { BreadcrumbService } from '../../services/breadcrumb.service';
import { HttpClient } from '@angular/common/http';
import { HttpParams } from '@angular/common/http';

import { AngularFireFunctions } from '@angular/fire/functions';
import { NotificacionesService } from 'src/app/services/notificaciones.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  userdata: any;
  colegio: any;
  app: App;
  colegioapps_internas = [];
  colegioapps_externas = [];
  mainuseremail: any;
  userUid: any;
  nombres: any;
  apellidos: any;
  chats = [] as any;
  receiveruserchat: any;
  chatusers = [];
  grupodechats = [];
 
  content: any = '';
  locals: any;
  results: any = '';
  searchValue = '';
  usuario:any;
  cargado:boolean = false;
  conteomensajes: number;
  constructor(
    private authenticationService: AuthenticationService,
    private colegioService: ColegiosService,
    private appsService: AppsService,
    private chatService: ChatService,
    private userService: UserService,
    private breadService: BreadcrumbService,
    private http: HttpClient,
    private eventosService: EventosService,
    private functions: AngularFireFunctions,
    private notificacionesService: NotificacionesService,
  ) {}
  ngOnInit(): void {
    const Userdata: any = this.userService.getUserinfodata();

    if (Userdata === undefined) {
      this.getmainuserdata();
    } else {
      this.userUid = Userdata.payload.doc.data().uid; /* id del usuario */
      this.mainuseremail =
        Userdata.payload.doc.data().email; /* email del usuario */
        this.usuario = Userdata.payload.doc.data().usuario;
   

      this.userdata = Userdata.payload.doc.data();
      this.nombres = this.userdata.nombres; /* nombres del usuario logueado */
      this.apellidos =
        this.userdata.apellidos; /*apellidos del usuario logueado */

        this.notificacionesService.getNotificacionesMensajes(this.userUid).subscribe(notificaciones => {
          this.conteomensajes = 0;
          if(notificaciones.length>0){
            this.conteomensajes = notificaciones.length
          }
        })


      this.getApps(
        this.userdata
      ); /* llama a la funcion para obtener las apps del colegio */
      this.getLastChats(this.userUid); /* obtiene los ultimos mensajes */
    }
   


  }

   /* trae informacion principal del usuario logueado */
   getmainuserdata() {
    this.authenticationService.isAuth().subscribe((auth) => {
      if (auth) {
        this.userUid = auth.uid; /* id del usuario */
        this.mainuseremail = auth.email; /* email del usuario */
        
        this.authenticationService
        .isUserAdmin(this.userUid)
        .subscribe((userdata) => {
          if (userdata[0]) {
            this.userdata = userdata[0];
            this.nombres =
            this.userdata.nombres; /* nombres del usuario logueado */
            this.usuario = this.userdata.usuario;
            this.apellidos =
            this.userdata.apellidos; /*apellidos del usuario logueado */
  
              this.getApps(
                this.userdata
              ); /* llama a la funcion para obtener las apps del colegio */
              this.getLastChats(
                this.userUid
              ); /* obtiene los ultimos mensajes */
            }
          });

          this.notificacionesService.getNotificacionesMensajes(this.userUid).subscribe(notificaciones => {
            this.conteomensajes = 0;
            if(notificaciones.length>0){
              this.conteomensajes = notificaciones.length
            }
          })
      }
    });
  }

  getApps(userdata) {
    if (userdata && userdata.colegios[0]) {
      this.colegioService
        .getColegioById(userdata.colegios[0])
        .subscribe((colegio) => {
          if (colegio) {
            this.colegio = colegio.payload.data();

            this.colegioapps_internas = [];
            this.colegioapps_externas = [];

            if (this.colegio.apps) {
              this.colegio.apps.forEach((app: any) => {
                this.appsService.getAppById(app).subscribe((app) => {
                  if (app) {
                    this.app = app.payload.data();
                    this.cargado=true;

                    if (this.app.tipo === 'interna') {
                      if (
                        !this.colegioapps_internas.some(
                          (el) => el.nombre === this.app.nombre
                        )
                      ) {
                        /* añade las apps internas a un array */
                        this.colegioapps_internas.push({
                          icono: this.app.icono,
                          nombre: this.app.nombre,
                          url: this.app.url,
                        });
                      }
                    } else {
                      if (
                        !this.colegioapps_externas.some(
                          (el) => el.nombre === this.app.nombre
                        )
                      ) {
                        /* añade las apps externas un array */
                        this.colegioapps_externas.push({
                          icono: this.app.icono,
                          nombre: this.app.nombre,
                          url: this.app.url,
                        });
                      }
                    }
                  }
                });
              });
            }
            else{

              this.cargado=true;
            }
          }
        });
    }
   
  }

    /* obtiene los ultimos mensajes de chat */
    getLastChats(mainuserid) {
      this.chatService.getChats(mainuserid).subscribe((chat) => {
        chat.forEach((chatData: any) => {
          /* genera la id del chat */
          const messagechatid = [
            chatData.members[0].uid,
            chatData.members[1].uid,
          ].sort();
          const themeschaid = messagechatid.join('|');
  
          /* encuentra el usuario receptor o emisor que es el que no tiene la id del usuario logueado */
          let receiveruser;
          if (chatData.members[1].uid != mainuserid) {
            receiveruser = chatData.members[1].uid;
          } else {
            receiveruser = chatData.members[0].uid;
          }
  
          /* trae la informacion el usuario receptor o emisor */
          this.userService.getUserById(receiveruser).subscribe((user) => {
            if(user.length>0){
              this.receiveruserchat = user[0].payload.doc.data();
              /* guarda la informacion del usuario en un array usando su id como clave */
              this.chatusers[this.receiveruserchat.uid] =
                user[0].payload.doc.data();
              this.chats = chat; /* guarda todos los chats abiertos */
              this.getLastMessage(themeschaid, mainuserid);
            }
          });
        });
      });
    }
  
    getLastMessage(chatid, mainuserid) {
      this.chatService.getLastMessage(chatid, mainuserid).subscribe((chat) => {
        if (chat[0]) {
          this.grupodechats[chatid] = chat[0].payload.doc.data();
        }
      });
    }

    UpdateBreadcrumb(name, url) {
      this.breadService.addtobread(name, url);
    }

    ///  BUSCAR USUARIOS   ///
  search() {
    // this.userService.searchUsers(this.clases).subscribe((searchdata) => {
    //   this.results = searchdata;
    // });
  }

}
