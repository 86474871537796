import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AppsService } from 'src/app/services/apps.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ChatService } from './../../services/chat.service';
import { UserService } from './../../services/user.service';
import { EventosService } from './../../services/eventos.service';
import { BreadcrumbService } from '../../services/breadcrumb.service';
import { HttpClient } from '@angular/common/http';
import { App } from 'src/app/interfaces/app';
import { HttpParams } from '@angular/common/http';
import { createEvent, download } from './event-download.utils';

import { Alertas } from 'src/app/interfaces/alertas';
import { AlertasService } from 'src/app/services/alertas.service';
import { AngularFireFunctions } from '@angular/fire/functions';
import { ClasesService } from 'src/app/services/clases.service';
import { CursosService } from 'src/app/services/cursos.service';
import { ColegiosService } from 'src/app/services/colegios.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class AdminHomeComponent implements OnInit {
  userdata: any;
  colegio: any;
  app: App;
  colegioapps_internas = [];
  colegioapps_externas = [];
  mainuseremail: any;
  userUid: any;
  nombres: any;
  apellidos: any;
  chats = [] as any;
  receiveruserchat: any;
  chatusers = [];
  grupodechats = [];
  events = [];
  content: any;
  eventos = [];
  locals: any;
  pexipdata: any;
  usuarios: any[];
  usuariosAcademica: any[];

  constructor(
    private authenticationService: AuthenticationService,
    private clasesService: ClasesService,
    private chatService: ChatService,
    private userService: UserService,
    private breadService: BreadcrumbService,
    private http: HttpClient,
    private eventosService: EventosService,
    private cursosService: CursosService,
    private colegiosService: ColegiosService,
    private functions: AngularFireFunctions
  ) {}

  ngOnInit(): void {
    this.usuarios = new Array();
    this.usuariosAcademica = new Array();
    const Userdata: any = this.userService.getUserinfodata();

    if (Userdata === undefined) {
      this.getmainuserdata();
    } else {
      this.userUid = Userdata.payload.doc.data().uid; /* id del usuario */
      this.mainuseremail =
        Userdata.payload.doc.data().email; /* email del usuario */
      this.getroom(Userdata.payload.doc.data().email);

      this.userdata = Userdata.payload.doc.data();
      this.nombres = this.userdata.nombres; /* nombres del usuario logueado */
      this.apellidos =
        this.userdata.apellidos; /*apellidos del usuario logueado */
      //   this.getApps(
      //     this.userdata
      //   ); /* llama a la funcion para obtener las apps del colegio */
      this.getLastChats(this.userUid); /* obtiene los ultimos mensajes */
    }

    this.userService.getUsers().subscribe((users) => {
      users.forEach((usu) => {
        let usuario = usu.payload.doc;
        this.usuarios.push(usuario);
      });
    });
  }

  /* trae informacion principal del usuario logueado */
  getmainuserdata() {
    this.authenticationService.isAuth().subscribe((auth) => {
      if (auth) {
        this.userUid = auth.uid; /* id del usuario */
        this.mainuseremail = auth.email; /* email del usuario */
        this.getroom(auth.email);

        this.authenticationService
          .isUserAdmin(this.userUid)
          .subscribe((userdata) => {
            if (userdata[0]) {
              this.userdata = userdata[0];
              this.nombres =
                this.userdata.nombres; /* nombres del usuario logueado */
              this.apellidos =
                this.userdata.apellidos; /*apellidos del usuario logueado */

              //   this.getApps(
              //     this.userdata
              //   ); /* llama a la funcion para obtener las apps del colegio */
              this.getLastChats(
                this.userUid
              ); /* obtiene los ultimos mensajes */
            }
          });
      }
    });
  }

  /* obtiene las app del primer colegio(array) al que pertenezca el usuario logueado */
  //   getApps(userdata) {
  //     if (userdata && userdata.colegios[0]) {
  //       this.colegioService
  //         .getColegioById(userdata.colegios[0])
  //         .subscribe((colegio) => {
  //           if (colegio) {
  //             this.colegio = colegio.payload.data();

  //             this.colegioapps_internas = [];
  //             this.colegioapps_externas = [];

  //             if (this.colegio.apps) {
  //               this.colegio.apps.forEach((app: any) => {
  //                 this.appsService.getAppById(app).subscribe((app) => {
  //                   if (app) {
  //                     this.app = app.payload.data();

  //                     if (this.app.tipo === 'interna') {
  //                       if (
  //                         !this.colegioapps_internas.some(
  //                           (el) => el.nombre === this.app.nombre
  //                         )
  //                       ) {
  //                         /* añade las apps internas a un array */
  //                         this.colegioapps_internas.push({
  //                           icono: this.app.icono,
  //                           nombre: this.app.nombre,
  //                           url: this.app.url,
  //                         });
  //                       }
  //                     } else {
  //                       if (
  //                         !this.colegioapps_externas.some(
  //                           (el) => el.nombre === this.app.nombre
  //                         )
  //                       ) {
  //                         /* añade las apps externas un array */
  //                         this.colegioapps_externas.push({
  //                           icono: this.app.icono,
  //                           nombre: this.app.nombre,
  //                           url: this.app.url,
  //                         });
  //                       }
  //                     }
  //                   }
  //                 });
  //               });
  //             }
  //           }
  //         });
  //     }
  //   }

  /* obtiene los ultimos mensajes de chat */
  getLastChats(mainuserid) {
    this.chatService.getChats(mainuserid).subscribe((chat) => {
      chat.forEach((chatData: any) => {
        /* genera la id del chat */
        const messagechatid = [
          chatData.members[0].uid,
          chatData.members[1].uid,
        ].sort();
        const themeschaid = messagechatid.join('|');

        /* encuentra el usuario receptor o emisor que es el que no tiene la id del usuario logueado */
        let receiveruser;
        if (chatData.members[1].uid != mainuserid) {
          receiveruser = chatData.members[1].uid;
        } else {
          receiveruser = chatData.members[0].uid;
        }

        /* trae la informacion el usuario receptor o emisor */
        this.userService.getUserById(receiveruser).subscribe((user) => {
          this.receiveruserchat = user[0].payload.doc.data();
          /* guarda la informacion del usuario en un array usando su id como clave */
          this.chatusers[this.receiveruserchat.uid] =
            user[0].payload.doc.data();
          this.chats = chat; /* guarda todos los chats abiertos */
          this.getLastMessage(themeschaid, mainuserid);
        });
      });
    });
  }

  getLastMessage(chatid, mainuserid) {
    this.chatService.getLastMessage(chatid, mainuserid).subscribe((chat) => {
      if (chat[0]) {
        this.grupodechats[chatid] = chat[0].payload.doc.data();
      }
    });
  }

  /* funcion para descargar ics de eventos */
  download() {
    /* añade todos los eventos al array para luego generar el archivo ics */
    this.eventosService.getEventos().subscribe((eventosdata) => {
      if (eventosdata !== undefined) {
        this.eventos = eventosdata;
        this.eventos.forEach((evento) => {
          this.events.push({
            start: evento.payload.doc
              .data()
              .fecha_inicio.toDate() /*fecha de inicio*/,
            end: evento.payload.doc
              .data()
              .fecha_final.toDate() /* fecha final */,
            summary: evento.payload.doc.data().nombre /* resumen */,
            description: evento.payload.doc.data().nombre /*descripción */,
          });
        });
        this.content = createEvent(this.events);
      }
    });

    download('thecampus-calendar.ics', this.content); /* genera archivo */
  }

  UpdateBreadcrumb(name, url) {
    this.breadService.addtobread(name, url);
  }
  //se utiliza para recoger una sala según el email del usuario
  getroom(email) {
    //this.http.get<any>("https://us-central1-the-campus-56b06.cloudfunctions.net/getRoom",
    this.http
      .get<any>(
        'https://us-central1-the-campus-56b06.cloudfunctions.net/getRoom',
        { params: new HttpParams().set('salaname', email) }
      )
      .subscribe((data) => {
        this.pexipdata = data;
      });
  }

  // clases() {
  //   let clasesLista = new Array();
  //   let clasesTotales = new Array();
  //   let clasesParaBorrar = new Array();

  //   this.clasesService.getClasesNow().subscribe((clasesTotal) => {
  //     clasesTotal.forEach((claseTotal) => clasesTotales.push(claseTotal.id));
  //     console.log('clases totales;', clasesTotales);
  //   });

  //   this.userActivos.forEach((element) => {
  //     this.clasesService.getUserClassNow(element).subscribe((clases) => {
  //       clases.forEach((clase) => {
  //         var classe = clase.data();
  //         if (!clasesLista.includes(clase.id)) {
  //           clasesLista.push(clase.id);
  //         }
  //       });
  //     });
  //     this.clasesService.getAdminClassNow(element).subscribe((clases) => {
  //       clases.forEach((clase) => {
  //         var classe = clase.data();
  //         if (!clasesLista.includes(clase.id)) {
  //           clasesLista.push(clase.id);
  //         }
  //       });
  //     });
  //   });
  //   setTimeout(() => {
  //     console.log('clases Lista', clasesLista);
  //     clasesTotales.forEach((idClase) => {
  //       if (!clasesLista.includes(idClase)) {
  //         clasesParaBorrar.push(idClase);
  //       }
  //     });
  //     clasesParaBorrar.forEach((idClaseBorrar) => {
  //       this.cursosService
  //         .getCursosByClassidNow(idClaseBorrar)
  //         .subscribe((cursos) => {
  //           console.log('--------------------- ');
  //           cursos.forEach((curso) => {
  //             var cursoData = curso.data();
  //             let index = cursoData.clases.indexOf(idClaseBorrar);
  //             cursoData.clases.splice(index, 1);
  //             // this.cursosService.editCurso(curso.id,cursoData);

  //           });
  //           //elimina la clase
  //           // this.clasesService.DeleteClass("S2tsg2pgfaCfjSywpZ7Z");
  //         });
  //     });
  //     console.log('clases para borrar:', clasesParaBorrar);
  //   }, 5000);
  // }

  eliminar() {
    // this.eliminarusuarios();
    // this.eliminarClases()
    // this.eliminarCursos()
    this.eliminarColegios()
  }

  // eliminarusuarios() {
  //   var usuarios = [
  //     'taQqu0GzZXR1qKQuNCBcDaFWQkS2',
  //     'z0UXhE4cN9gOsrQdHPEzDWGBgLz1',
  //     'dtjyFXV8a2eWEsVNhSUdWLQeMAn2',
  //     'CYgN1P0997WpDpVXwAGXS3Lji1h1',
  //     '9CAQqw2LXPUZZ2NzK9dg8LCbcx82',
  //     'n5bPFHURgSQjOCdlMXENg6p8bYy2',
  //     'ovgV63GauEfuxeXlc81fD6ctkXk2',
  //     'oHCCzRbCnYg8MmBlcHefLEcVR9i2',
  //     'LoCrcN4JARNwwzpDi84ERtXxMP93',
  //     'GadHpNAqrkMwKrtt3H8rwyRvv9n2',
  //     'Pio4jShzt3b1YkztBqcOKu6aOjY2',
  //     'vfIxj1KoeTfPAwfJXLHWZoNHYid2',
  //     '7OShGdi79SdL6OuiXVkKDd9BBbz2',
  //     'rZrndVEAokQ5TrGXRpsHyE65IJH3',
  //     'rY1H52CEJQeQ8pK2IpXoMEohKER2',
  //     'wkCEDGK8suOZ9Mo5jq7dyxXRFJK2',
  //     'JAt4G2ykhkPm5BsynQhiMcvsp683',
  //     'WHOR6uIcrXTc93pQkTB2VURE6Vm2',
  //     '96R2x455HpPyOWjqE0NOVRTyHlt2',
  //     '2pXg4p7rKgeeYvHG0vNoIiKAQiN2',
  //   ];
  //   let contador = 0;
  //   this.userService.getUsersNow().subscribe((usersD) => {
  //     usersD.forEach((usuario) => {
  //       let usu = usuario.data();
  //       let comp = usuarios.includes(usu.uid)
  //       if (comp) {
  //         console.log(usu);
  //       }
  //       else{
  //         this.userService.deleteUser(usuario.id);
  //         const callable = this.functions.httpsCallable('deleteUsers');
  //         const obs = callable({
  //           uid: usu.uid
  //         });
  //         obs.subscribe(async (res) => {});
  //       }
  //     });
  //   });
  // }

  eliminarClases(){
    var clases = [
      "hCes0RqpxlrRrgwWsYRa",
      "P4wygap3nMXxQe9nD6Y5",
      "rGZosNDJFxROWO7QXjvv",
      "DMqvyXcxMWmlZtHsRFb7",
      "dkUNVIiIrRbuGKaI1kVk",
      "TqvUiUktvHyjtePuwkjO",
      "QZTDToWahVZTmyilcXEC",
      "qGUMPRKBHaIxed81mVni",
      "CJ9GMi347NhLANvs8eZN",
      "koy60AtF2ui1Jcl43ip9",
      "ISqNxHIHX1SGhWYOqcrG",
      "j0VygdOQ0hJS4rDBM1E7",
      "CH5AlRyVbs1kP1eUbyZx"
    ]

    this.clasesService.getClasesNow().subscribe((cls) => {
      cls.forEach((clas)=>{
        let comp = clases.includes(clas.id)
        if (comp) {
          console.log(clas.id);
        }
        else{
          this.clasesService.DeleteClass(clas.id)
        }
      })
    })

  }

  eliminarCursos(){
    let curso="4mOlN9bbCMK5wANiuHCd"
    this.cursosService.getCursosNow().subscribe((curs)=>{
      curs.forEach((curs)=>{
        let comp = curso.includes(curs.id)
        if (comp) {
          console.log(curs.id);
        }
        else{
          this.cursosService.deleteCurso(curs.id)
        }
      })
    })

  }

  eliminarColegios(){
    let colegios=[
      "8WXCKoWICwHY2gDPlIuX",
      "OswOTCWcaL82uwbijPAu"
    ]
    this.colegiosService.getColegiosNow().subscribe((coles)=>{
      coles.forEach((col)=>{
        let comp = colegios.includes(col.id)
        if (comp) {
          console.log(col.id);
        }else{
          this.colegiosService.deleteColegio(col.id)
        }
      })
    })
  }

}
