<section class="basicbox fondo">
    <header class="headermisala padtop">
      <div class="icon">
          <div class="wrapicon">
              <img src="/assets/img/cog.svg" alt="">
          </div>
      </div>
      <h3 translate>
         dashboard.perfil.misalaS.titulo
      </h3>
      <div class="copybutton">
        <button class="button" (click)="copy()" title="{{'dashboard.perfil.misalaS.info' | translate}}">
          {{'dashboard.perfil.misalaS.link' | translate}}
        </button>
        <div class="copymsg" *ngIf="copymsg==true">
          <p > <b translate>dashboard.perfil.misalaS.copiado</b> </p>
          <p>https://demo.thecampus.education/{{usuario}}</p>
        </div>
      </div>
    </header>
    <div class="submenu">
    <ul>
      
      <li routerLink="/dashboard/profile/{{ userUid }}/misala/pin"
          (click)="
            UpdateBreadcrumb(
              'dashboard.perfil.misalaS.pin',
              '/dashboard/profile/' + userUid + '/misala/pin'
            )
          "
          translate
          >
          dashboard.perfil.misalaS.pin
      </li>
      <li
          routerLink="/dashboard/profile/{{ userUid }}/misala/layout"
          (click)="
            UpdateBreadcrumb(
              'dashboard.perfil.misalaS.layout',
              '/dashboard/profile/' + userUid + '/misala/layout'
            )
          "
          translate
        >
        dashboard.perfil.misalaS.layout
      </li>
      <li
          routerLink="/dashboard/profile/{{ userUid }}/misala/dispositivos"
          (click)="
            UpdateBreadcrumb(
              'dashboard.perfil.misalaS.dispositivos',
              '/dashboard/profile/' + userUid + '/misala/dispositivos'
            )
          "
          translate
        >
        dashboard.perfil.misalaS.dispositivos
      </li>
      <!-- <li routerLink="/dashboard/profile/{{ userUid }}/misala/estadisticas"
      (click)="
        UpdateBreadcrumb(
          'Estadisticas',
          '/dashboard/profile/' + userUid + '/misala/estadisticas'
        )
      ">
      Estadísticas
  </li> -->
    </ul>
  </div>
    <div class="basicbox uploadfile">
            <router-outlet></router-outlet>
    </div>
</section>