import { AuthenticationService } from './authentication.service';
import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard implements CanActivate {
  url: any;
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    var tieneAdmin = false;
    var tienePadres = false;
    this.url = window.location.href.split('/');
    if (this.url.includes('admin')) {
      tieneAdmin = true;
    } else {
      tieneAdmin = false;
    }
    if (this.url.includes('padres')) {
      tienePadres = true;
    } else {
      tienePadres = false;
    }

    return this.authenticationService.getStatus().pipe(
      map((status) => {
 
        if (status) {
          // console.log("status", status)
          // this.authenticationService
          //   .isUserAdminBueno(status.uid)
          //   .subscribe((admin) => {
          //     if (admin.length == 0) {
          //       if (tieneAdmin) {
          //         this.router.navigate(['/dashboard']);
          //         return true;
          //       }
          //       if(tienePadres){
          //         this.router.navigate(['/padres']);
          //         return true;
          //       }
          //       return false;
          //     } else {
           
          //       if (!tieneAdmin) {
          //         this.router.navigate(['/admin']);
          //         return true;
          //       }
          //       if(tienePadres){
          //         this.router.navigate(['/padres']);
          //         return true;
          //       }
          //       return true;
          //     }
          //   });
          this.authenticationService
            .isUserBueno(status.uid)
            .subscribe((admin:any) => {
              const targetRoute = state.url;
              if (admin.length == 0) {
                this.router.navigate(['login']);
                return false;
              } else {
                if (admin[0].rol=='admin' && targetRoute.startsWith('/admin')) {
                  return true;
                }
                if (admin[0].rol=='padre' && targetRoute.startsWith('/padres')) {
                  return true;
                }
                if ((admin[0].rol=='director'|| admin[0].rol=='estudiante' || admin[0].rol=='supervisor') && targetRoute.startsWith('/dashboard')) {
                  return true;
                }

                if (admin[0].rol=='admin') {
                  this.router.navigate(['/admin']);
                  return false;
                }
                if (admin[0].rol=='padre' ) {
                  this.router.navigate(['/padres']);
                  return false;
                }
                if (admin[0].rol=='director'|| admin[0].rol=='estudiante' || admin[0].rol=='supervisor') {
                  this.router.navigate(['/dashboard']);
                  return false;
                }
              }
            });
          return true;
        } else {
          this.router.navigate(['login']);
          return false;
        }
      })
    );
  }
}
