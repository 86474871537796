<section class="basicbox fondo">
    <header class="headerperfil padtop wsmall">
    <div class="icon2">
          <div class="wrapicon2">
             <div  class="boxicon2">
                <img *ngIf="picture!='' ; else default" src="{{picture}}">
             </div>
             <ng-template #default class="boxicon">
                <img src="assets/img/defaultavatar.jpg">
             </ng-template> 

          </div>
    </div>
    <h3 translate>
       dashboard.perfil.cambiarfoto.titulo
       <small translate>
          dashboard.perfil.cambiarfoto.des
       </small>
    </h3>
    </header>
    
    <article>
       <section *ngIf="popDelete==false && mensaje==false" class="basicbox uploadfile" >

             <div class="editDelete">
                <input id="buttonFoto" type="submit" value="{{'dashboard.perfil.cambiarfoto.eliminar' |translate}}" (click)="changedelete()">
                <button id="buttonFoto" (click)="file.click()">{{'dashboard.perfil.cambiarfoto.buscar'|translate}} <img src="assets/img/lupa.svg"></button>
             </div>

             <div *ngIf="changeFoto" class="dragfile" >
                <image-cropper 
                [imageChangedEvent]="imageChangedEvent"
                [maintainAspectRatio]="true"
                [aspectRatio]="1 / 1"
                [cropperMinWidth]="120"
                [cropperMinHeight]="120"
                format="jpg"
                (imageCropped)="imageCropped($event)"
                (imageLoaded)="imageLoaded()"
                (cropperReady)="cropperReady()"
                (loadImageFailed)="loadImageFailed()"
                ></image-cropper>
                <input *ngIf="changeFoto"id="subir" type="submit" value="Subir" (click)="saveavatar($event)">
             </div>
             <input [hidden]="true" type="file" (change)="fileChangeEvent($event)" accept="image/*" #file>
    </section>
    
 </article>
 <ng-container *ngIf="popDelete==true">
    <div class="confirmMs">
       <p translate>dashboard.perfil.cambiarfoto.seguro</p>
       <div>
          <input id="buttonFoto" type="submit" value="{{'dashboard.perfil.cambiarfoto.eliminar' |translate}}" (click)="deleteAvatar($event)">
          <input id="buttonFoto" type="submit" value="{{'dashboard.perfil.cambiarfoto.Noeliminar' |translate}}" (click)="changedelete()">
       </div>
    </div>
 </ng-container>
 
</section>
<ng-container *ngIf="mensaje==true">
       <div class="respuesta">
         <p >{{saveresponse}}</p>
         
       </div>
 </ng-container>

