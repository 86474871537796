

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PadresComponent } from './padres.component';
import { HomeComponent } from './home/home.component';
import { MishijosComponent } from './mishijos/mishijos.component';
import { HijoComponent } from './hijo/hijo.component';
import { MensajesComponent } from './mensajes/mensajes.component';
import { HiloComponent } from './mensajes/hilo/hilo.component';
import { AppsInternasComponent } from './home/apps-internas/apps-internas.component';
import { NotificacionesComponent } from './notificaciones/notificaciones.component';
import { ProfileComponent } from './profile/profile.component';
import { AjustesComponent } from './profile/ajustes/ajustes.component';
import { PassComponent } from './profile/pass/pass.component';
import { FotoComponent } from './profile/foto/foto.component';


const routes: Routes = [
  {
    path: '',
    component: PadresComponent,
    children: [
      { path: '', component: HomeComponent },
      { path: 'mishijos/:uid', component: HijoComponent },
      { path: 'mishijos', component: MishijosComponent },
      {
        path: 'mensajes',
        component: MensajesComponent,
        children: [{ path: 'chat/:uid', component: HiloComponent }],
      },
      {
        path: 'profile/:uid',
        component: ProfileComponent,
        children: [
          { path: '', component: AjustesComponent },
          { path: 'ajustes', component: AjustesComponent },
          { path: 'pass', component: PassComponent },
          { path: 'foto', component: FotoComponent },
          // { path: 'eventos', component: EventosComponent },
          // { path: 'miseventos', component: MiseventosComponent },
          // { path: 'editarevento/:uid', component: EditareventoComponent },
          // { path: 'misala', loadChildren: () => MisalaModule },
        ],
      },
      // { path: 'ayuda', component: ManualComponent },
      // {
      //   path: 'mensajes',
      //   component: MensajesComponent,
      //   children: [{ path: 'chat/:uid', component: HiloComponent }],
      // },
      // { path: 'alertas', component: AlertasComponent },
      // { path: 'alertas/alerta/:idAlerta', component: AlertaComponent },
      // { path: 'eliminar', component: EliminarusuariosComponent },
      // { path: 'clase/:uid', component: ClaseComponent },
      // { path: 'archivos/:uid', component: ArchivosComponent },
      // { path: 'misgrupos', component: MisgruposComponent },
      { path: 'notificaciones', component: NotificacionesComponent },
      // { path: 'creargrupo', component: CreargrupoComponent },
      // { path: 'cursos', component: CursosComponent },
      // { path: 'cursos/crearcurso', component: CrearcursosComponent },
      // { path: 'editarclase/:uid', component: EditarclaseComponent },
      { path: 'appsint/:url', component: AppsInternasComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PadresRoutingModule {}
