import { ClasesService } from './../../services/clases.service';
import { ArchivosService } from './../../services/archivos.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { UserService } from './../../services/user.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-archivos',
  templateUrl: './archivos.component.html',
  styleUrls: ['./archivos.component.css'],
})
export class ArchivosComponent implements OnInit {
  currentuserid: string;
  fileupload: any;
  urluid: string;
  foldername: string;
  porcentaje: number;
  thefile: any;
  currentfolder = 'root';
  losarchivos = [];
  breadcrumb = [{ name: 'dashboard.archivos.inicio', uid: 0 }];
  espacioocupado: number;
  maxspace = 1048576000.0; //1000mb
  propietario: any;
  updateroot = true;
  classuid: string;
  getfilesid: string;
  searchValue: string;
  clasenombre: string;
  clase: any;

  rol: string;

  orderbyname: boolean = false;
  orderbydate: boolean = false;
  administrador: boolean = false;
  carpetaDestino: any = 'root';

  carpetas = [];
  moverA: string = '';
  move: boolean = false;
  archivoToMove = [];
  deletePop: boolean = false;
  archivoToDelete = [];
  deleteuid: any;
  deleteurl: any;
  deletePopCarpeta: boolean = false;
  carpetaToDelete = [];

  clasesCompartidas = [];
  clases: any[];
  crearCarpetaCompartida: boolean = false;
  currentuserrol: any;

  esColegia = false;
  usuario: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private angularfireStorage: AngularFireStorage,
    private archivosService: ArchivosService,
    private clasesservice: ClasesService,
    private userService: UserService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.urluid = params['uid'];

      if (this.urluid === 'user') {
        const Userdata: any = this.userService.getUserinfodata();

        if (Userdata === undefined) {
          this.authenticationService.getStatus().subscribe(
            (status) => {
              this.currentuserid = status.uid;
              this.getfilesid = this.currentuserid;
              this.classuid = '';
              this.updateroot = true;

              this.authenticationService
                .isUserAdmin(this.currentuserid)
                .subscribe((userdata) => {
                  if (userdata[0]) {
                    const userdata2: any = userdata[0];
                    this.usuario = userdata2;
                    userdata2.colegios[0] == '9lE19LwnoOVUYwyzAbue'
                      ? (this.esColegia = true)
                      : (this.esColegia = false);
                    this.currentuserrol =
                      userdata2.rol; /* nombres del usuario logueado */
                  }
                });

              this.clasesservice
                .getUserClassNow(this.currentuserid)
                .subscribe((clasesUser) => {
                  this.clases = [];
                  this.clasesCompartidas = [];
                  clasesUser.forEach((claseUser) => {
                    if (claseUser.id != this.classuid) {
                      this.clases.push(claseUser);
                    }
                  });
                });
              this.getarchivos();
            },
            (error) => {
              console.log(error);
            }
          );
        } else {
          this.currentuserid = Userdata.payload.doc.data().uid;
          this.currentuserrol = Userdata.payload.doc.data().rol;
          Userdata.payload.doc.data().colegios[0] == '9lE19LwnoOVUYwyzAbue'
            ? (this.esColegia = true)
            : (this.esColegia = false);
          this.usuario = Userdata.payload.doc.data();
          this.getfilesid = this.currentuserid;
          this.classuid = '';
          this.updateroot = true;
          this.clasesservice
            .getUserClassNow(this.currentuserid)
            .subscribe((clasesUser) => {
              this.clases = [];
              this.clasesCompartidas = [];
              clasesUser.forEach((claseUser) => {
                if (claseUser.id != this.classuid) {
                  this.clases.push(claseUser);
                }
              });
            });
          this.getarchivos();
        }
      } else {
        this.authenticationService.getStatus().subscribe((status) => {
          this.currentuserid = status.uid;
          this.userService.getUserById(this.currentuserid).subscribe((user) => {
            var use = user[0].payload.doc.data();
            this.rol = use['rol'];
          });
          this.clasesservice
            .getUserClassNow(this.currentuserid)
            .subscribe((clasesUser) => {
              this.clases = [];
              this.clasesCompartidas = [];
              clasesUser.forEach((claseUser) => {
                if (claseUser.id != this.classuid) {
                  this.clases.push(claseUser);
                }
              });
            });
        });

        this.classuid = this.urluid;
        this.getfilesid = this.urluid;
        this.updateroot = true;

        this.archivosService
          .getGroupArchivos(this.getfilesid)
          .subscribe((files) => {
            this.espacioocupado = 0;

            files.forEach((fileData: any) => {
              if (!fileData.payload.doc.data().folder) {
                this.espacioocupado =
                  this.espacioocupado + fileData.payload.doc.data().size;
              }
            });
          });

        this.clasesservice
          .getClaseById(this.classuid)
          .subscribe((infoclase) => {
            const clasedata: any = infoclase.payload.data();
            this.clase = infoclase;
            this.clasenombre = clasedata.nombre;
          });
        this.authenticationService.getStatus().subscribe(
          (status) => {
            this.currentuserid = status.uid;
            this.getarchivos();
          },
          (error) => {
            console.log(error);
          }
        );
      }
    });
  }

  uploadfile(event) {
    const fileSize = event.target.files[0].size;
    if (this.espacioocupado + fileSize < this.maxspace) {
      if (event) {
        const file = event.target.files[0];
        const filePath =
          this.getfilesid +
          '/' +
          Date.now() +
          file.name.replace(/\s/g, '').replace(/[^a-zA-Z0-9-.]/g, '');
        const fileRef = this.angularfireStorage.ref(filePath);
        const taks = this.angularfireStorage.upload(filePath, file);
        taks.percentageChanges().subscribe((porcentaje) => {
          this.porcentaje = Math.round(porcentaje);
        });

        taks.then((fileuploaded) => {
          this.thefile = this.angularfireStorage.ref(filePath).getDownloadURL();
          this.porcentaje = null;
          this.thefile.subscribe((p) => {
            let archivo = {};
            if (this.classuid) {
              archivo = {
                url: p,
                nombre: file.name,
                propietario: this.currentuserid,
                grupo: [this.classuid],
                folder: false,
                parent: this.currentfolder,
                size: file.size,
                fecha: new Date(),
              };
            } else {
              archivo = {
                url: p,
                nombre: file.name,
                propietario: this.currentuserid,
                grupo: [],
                folder: false,
                parent: this.currentfolder,
                size: file.size,
                fecha: new Date(),
              };
            }
            this.archivosService
              .createdArchivo(archivo)
              .then((filelist) => {
                setTimeout(() => {
                  this.getarchivos();
                }, 500);
              })
              .catch((error) => {
                console.log(error);
              });
          });
        });
      }
    } else {
      this.translate.get('dashboard.archivos.masgrande').subscribe((data) => {
        alert(data);
      });
    }
  }

  subirPoster(event: any) {
    const file = event.target.files[0];
    const filePath = `posterColegia/${this.usuario.cursos[0].nombre}_${this.usuario.codigo}`;
    const storageRef = this.angularfireStorage.ref(filePath);
    storageRef.getDownloadURL().subscribe(
      (url) => {
        // El archivo existe
        // Preguntar al usuario si quiere reemplazarlo
        this.translate
          .get('dashboard.archivos.reemplazar')
          .subscribe((data) => {
            const shouldReplace = confirm(data);
            if (shouldReplace) {
              // Reemplazar el archivo existente
              const task = this.angularfireStorage
                .upload(filePath, file)
                .then(() => {
                  this.translate
                    .get('dashboard.archivos.correctamente')
                    .subscribe((ok) => {
                      alert(ok);
                    });
                });
            } else {
              // No hacer nada
            }
          });
      },
      (error) => {
        // El archivo no existe
        // Subir el nuevo archivo
        const task = this.angularfireStorage.upload(filePath, file).then(() => {
          this.translate
            .get('dashboard.archivos.correctamente')
            .subscribe((ok) => {
              alert(ok);
            });
        });
      }
    );
  }

  deletePoster() {
    this.translate
      .get('dashboard.archivos.quieresEliminar')
      .subscribe((data) => {
        const shouldReplace = confirm(data);
        if (shouldReplace) {
          const filePath = `posterColegia/${this.usuario.cursos[0].nombre}_${this.usuario.codigo}`;
          this.angularfireStorage.storage
            .ref(filePath)
            .delete()
            .then(() => {
              this.translate
                .get('dashboard.archivos.eliminado')
                .subscribe((ok) => {
                  alert(ok);
                });
            }).catch(()=>{
              this.translate
                .get('auth.error')
                .subscribe((ok) => {
                  alert(ok);
                });
            });
        } else {
          // No hacer nada
        }
      });
  }

  createFolder() {
    if (this.foldername != '') {
      let folder = {};
      if (this.classuid) {
        folder = {
          nombre: this.foldername,
          propietario: this.currentuserid,
          grupo: [this.classuid],
          folder: true,
          parent: this.currentfolder,
          fecha: new Date(),
        };
      } else {
        folder = {
          nombre: this.foldername,
          propietario: this.currentuserid,
          grupo: [],
          folder: true,
          parent: this.currentfolder,
          fecha: new Date(),
        };
      }
      this.foldername = '';
      this.archivosService
        .createdArchivo(folder)
        .then((filelist) => {
          setTimeout(() => {
            this.getarchivos();
          }, 500);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  getarchivos() {
    this.carpetas = [];
    if (this.classuid) {
      this.clasesservice.getClaseById(this.classuid).subscribe((infoclase) => {
        const clasedata: any = infoclase.payload.data();
        this.clase = infoclase;
        this.clasenombre = clasedata.nombre;
        this.administrador = clasedata.administradores.includes(
          this.currentuserid
        );

        this.archivosService
          .getGroupArchivos(this.getfilesid)
          .subscribe((files) => {
            // this.espacioocupado = 0;
            if (this.updateroot) {
              this.losarchivos = [];
            }
            if (this.rol == 'estudiante') {
              files.forEach((fileData: any) => {
                this.userService
                  .getUserById(fileData.payload.doc.data().propietario)
                  .subscribe((user) => {
                    this.propietario = user[0].payload.doc.data();
                    if (this.updateroot) {
                      if (
                        !this.losarchivos.some(
                          (el) => el.uid === fileData.payload.doc.id
                        )
                      ) {
                        if (
                          fileData.payload.doc.data().parent === 'root' &&
                          (fileData.payload.doc.data().propietario ==
                            this.currentuserid ||
                            this.clase.payload
                              .data()
                              .administradores.includes(
                                fileData.payload.doc.data().propietario
                              ))
                        ) {
                          this.losarchivos.push({
                            filedata: fileData.payload.doc.data(),
                            uid: fileData.payload.doc.id,
                            propietario:
                              this.propietario.nombres +
                              ' ' +
                              this.propietario.apellidos,
                            avatar: this.propietario.avatar,
                          });
                          if (fileData.payload.doc.data().folder == true) {
                            this.carpetas.push(fileData);
                          }
                        }
                      }
                    }
                    // if (!fileData.payload.doc.data().folder) {
                    //   this.espacioocupado =
                    //     this.espacioocupado + fileData.payload.doc.data().size;
                    // }
                  });
              });
            } else {
              files.forEach((fileData: any) => {
                this.userService
                  .getUserById(fileData.payload.doc.data().propietario)
                  .subscribe((user) => {
                    this.propietario = user[0].payload.doc.data();
                    if (this.updateroot) {
                      if (
                        !this.losarchivos.some(
                          (el) => el.uid === fileData.payload.doc.id
                        )
                      ) {
                        if (fileData.payload.doc.data().parent === 'root') {
                          this.losarchivos.push({
                            filedata: fileData.payload.doc.data(),
                            uid: fileData.payload.doc.id,
                            propietario:
                              this.propietario.nombres +
                              ' ' +
                              this.propietario.apellidos,
                            avatar: this.propietario.avatar,
                          });
                          if (fileData.payload.doc.data().folder == true) {
                            this.carpetas.push(fileData);
                          }
                        }
                      }
                    }
                    // if (!fileData.payload.doc.data().folder) {
                    //   this.espacioocupado =
                    //     this.espacioocupado + fileData.payload.doc.data().size;
                    // }
                  });
              });
            }
          });
        this.archivosService
          .getGroupCompartidosArchivos(this.classuid)
          .subscribe((archivos) => {
            archivos.forEach((fileData) => {
              this.userService
                .getUserById(fileData.data().propietario)
                .subscribe((user) => {
                  this.propietario = user[0].payload.doc.data();
                  if (this.updateroot) {
                    if (
                      !this.losarchivos.some((el) => el.uid === fileData.id)
                    ) {
                      if (
                        this.currentfolder == 'root' &&
                        fileData
                          .data()
                          .clasescompartidas?.includes(this.classuid)
                      ) {
                        this.losarchivos.push({
                          filedata: fileData.data(),
                          uid: fileData.id,
                          propietario:
                            this.propietario.nombres +
                            ' ' +
                            this.propietario.apellidos,
                          avatar: this.propietario.avatar,
                        });
                        if (fileData.data().folder == true) {
                          this.carpetas.push(fileData);
                        }
                      }
                    }
                  }
                  // if (!fileData.payload.doc.data().folder) {
                  //   this.espacioocupado =
                  //     this.espacioocupado + fileData.payload.doc.data().size;
                  // }
                });
            });
          });
        //()
      });
    } else {
      this.archivosService.getArchivos(this.getfilesid).subscribe((files) => {
        this.espacioocupado = 0;
        if (this.updateroot) {
          this.losarchivos = [];
        }
        files.forEach((fileData: any) => {
          this.userService
            .getUserById(fileData.payload.doc.data().propietario)
            .subscribe((user) => {
              this.propietario = user[0].payload.doc.data();
              if (this.updateroot) {
                if (
                  !this.losarchivos.some(
                    (el) => el.uid === fileData.payload.doc.id
                  )
                ) {
                  if (fileData.payload.doc.data().parent === 'root') {
                    this.losarchivos.push({
                      filedata: fileData.payload.doc.data(),
                      uid: fileData.payload.doc.id,
                      propietario:
                        this.propietario.nombres +
                        ' ' +
                        this.propietario.apellidos,
                      avatar: this.propietario.avatar,
                    });
                    if (fileData.payload.doc.data().folder == true) {
                      this.carpetas.push(fileData);
                    }
                  }
                }
              }

              if (!fileData.payload.doc.data().folder) {
                this.espacioocupado =
                  this.espacioocupado + fileData.payload.doc.data().size;
              }
            });
        });
      });
    }
  }

  changefolder(uid, name) {
    this.archivosService.getArchivoparents(uid).subscribe((files) => {
      this.losarchivos = [];
      this.carpetas = [];

      files.forEach((fileData: any) => {
        if (fileData.payload.doc.data().parent === uid) {
          this.userService
            .getUserById(fileData.payload.doc.data().propietario)
            .subscribe((user) => {
              this.propietario = user[0].payload.doc.data();
              if (
                !this.losarchivos.some(
                  (el) => el.uid === fileData.payload.doc.id
                )
              ) {
                this.losarchivos.push({
                  filedata: fileData.payload.doc.data(),
                  uid: fileData.payload.doc.id,
                  propietario:
                    this.propietario.nombres + ' ' + this.propietario.apellidos,
                  avatar: this.propietario.avatar,
                });
                if (fileData.payload.doc.data().folder == true) {
                  this.carpetas.push(fileData);
                }
              }
            });
        }
      });

      if (!this.breadcrumb.some((el) => el.name === name)) {
        this.breadcrumb.push({ name: name, uid: uid });
      }
      this.currentfolder = uid;
      this.updateroot = false;
    });
  }

  deletefile(path, uid) {
    this.angularfireStorage.storage
      .refFromURL(path)
      .delete()
      .then((filedel) => {
        this.archivosService.deletefile(uid).then((delfiledoc) => {
          setTimeout(() => {
            this.getarchivos();
          }, 500);
        });
      })
      .then(() => {
        this.deletePop = false;
      });
  }

  deletefolder(uid) {
    this.archivosService.getArchivoparents(uid).subscribe((files) => {
      files.forEach((fileData: any) => {
        if (fileData.payload.doc.data().folder) {
          this.deletefolder(fileData.payload.doc.id);
        } else {
          this.deletefile(
            fileData.payload.doc.data().url,
            fileData.payload.doc.id
          );
        }
      });

      this.archivosService
        .deletefile(uid)
        .then((delfiledoc) => {
          setTimeout(() => {
            this.getarchivos();
          }, 500);
        })
        .then(() => {
          this.deletePopCarpeta = false;
        });
    });
  }

  returnhome() {
    this.breadcrumb = [{ name: 'dashboard.archivos.inicio', uid: 0 }];
    this.updateroot = true;
    this.getarchivos();
    this.currentfolder = 'root';
  }

  gofolder(i, uid) {
    this.breadcrumb.length = i + 1;
    this.archivosService.getArchivoparents(uid).subscribe((files) => {
      this.losarchivos = [];
      files.forEach((fileData: any) => {
        this.losarchivos.push({
          filedata: fileData.payload.doc.data(),
          uid: fileData.payload.doc.id,
        });
      });
      this.currentfolder = uid;
    });
  }

  search() {
    if (!this.searchValue) {
      this.getarchivos();
      return;
    }
    if (this.classuid) {
      this.archivosService
        .getGroupArchivos(this.getfilesid)
        .subscribe((files) => {
          this.carpetas = [];
          files.forEach((fileData: any) => {
            this.userService
              .getUserById(fileData.payload.doc.data().propietario)
              .subscribe((user) => {
                this.propietario = user[0].payload.doc.data();

                if (this.searchValue) {
                  if (
                    !this.losarchivos.some(
                      (el) => el.uid === fileData.payload.doc.id
                    )
                  ) {
                    this.losarchivos.push({
                      filedata: fileData.payload.doc.data(),
                      uid: fileData.payload.doc.id,
                      propietario:
                        this.propietario.nombres +
                        ' ' +
                        this.propietario.apellidos,
                      avatar: this.propietario.avatar,
                    });
                    if (fileData.payload.doc.data().folder == true) {
                      this.carpetas.push(fileData);
                    }
                  }
                }
              });
          });
        });
    } else {
      this.archivosService.getArchivos(this.getfilesid).subscribe((files) => {
        files.forEach((fileData: any) => {
          this.userService
            .getUserById(fileData.payload.doc.data().propietario)
            .subscribe((user) => {
              this.propietario = user[0].payload.doc.data();
              if (this.searchValue) {
                if (
                  !this.losarchivos.some(
                    (el) => el.uid === fileData.payload.doc.id
                  )
                ) {
                  this.losarchivos.push({
                    filedata: fileData.payload.doc.data(),
                    uid: fileData.payload.doc.id,
                    propietario:
                      this.propietario.nombres +
                      ' ' +
                      this.propietario.apellidos,
                    avatar: this.propietario.avatar,
                  });
                  if (fileData.payload.doc.data().folder == true) {
                    this.carpetas.push(fileData);
                  }
                }
              }
            });
        });
      });
    }
  }

  checkimage(url) {
    let rutype = '';
    if (url.match(/\.(jpeg|jpg|gif|png)$/) != null) {
      rutype = 'image';
    } else if (
      url.match(/\.(mp4|3gp|mov|avi|wmv|flv|m3u8|ts|3g2|mpeg|ogv|webm)$/) !=
      null
    ) {
      rutype = 'video';
    } else {
      rutype = 'doc';
    }
    return rutype;
  }

  ordenarPorNombre() {
    this.orderbyname = !this.orderbyname;
    if (this.orderbyname) {
      this.ordenarDes('nombre');
    } else {
      this.ordenarAsc('nombre');
    }
  }
  ordenarPorFecha() {
    this.orderbydate = !this.orderbydate;
    if (this.orderbydate) {
      this.ordenarDes('fecha');
    } else {
      this.ordenarAsc('fecha');
    }
  }

  ordenarDes(item) {
    this.losarchivos.sort(function (a, b) {
      if (a.filedata[item] < b.filedata[item]) {
        return -1;
      } else if (a.filedata[item] > b.filedata[item]) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  ordenarAsc(item) {
    this.losarchivos.sort(function (a, b) {
      if (a.filedata[item] > b.filedata[item]) {
        return -1;
      } else if (a.filedata[item] < b.filedata[item]) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  moverArchivo(archivo) {
    if (this.carpetaDestino == '0' || this.carpetaDestino == 0) {
      this.carpetaDestino = 'root';
    }

    archivo[0].parent = this.carpetaDestino;
    console.log('archivo', archivo[0]);
    this.archivosService.editArchivo(this.moverA, archivo[0]);
    this.move = false;
    this.carpetaDestino = '';
    this.moverA = '';
  }

  moverVariable(archivo) {
    this.archivoToMove = [];
    this.move ? (this.move = false) : (this.move = true);
    this.moverA = archivo.uid;
    this.archivosService.getArchivobyId(this.moverA).forEach((d) => {
      this.archivoToMove.push(d.payload.data());
    });
  }
  closeM() {
    this.move = false;
  }
  closeD() {
    this.deletePop = false;
  }
  closeDC() {
    this.deletePopCarpeta = false;
  }
  deleteUp(archivo) {
    this.deletePop ? (this.deletePop = false) : (this.deletePop = true);
    this.archivoToDelete = [];
    this.deleteuid = archivo.uid;
    this.archivosService.getArchivobyId(this.deleteuid).forEach((d) => {
      this.archivoToDelete.push(d.payload.data());
    });
  }
  deleteUpCarpeta(archivo) {
    this.deletePopCarpeta
      ? (this.deletePopCarpeta = false)
      : (this.deletePopCarpeta = true);
    this.carpetaToDelete = [];
    this.deleteuid = archivo.uid;
    this.archivosService.getArchivobyId(this.deleteuid).forEach((d) => {
      this.carpetaToDelete.push(d.payload.data());
    });
  }

  download(url) {
    var xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = function (event) {
      var blob = xhr.response;
    };
    xhr.open('GET', url);
    xhr.send();
  }

  createFolderCompartida() {
    if (this.foldername != '') {
      let folder = {};
      if (this.classuid) {
        folder = {
          nombre: this.foldername,
          propietario: this.currentuserid,
          grupo: [this.classuid],
          folder: true,
          parent: this.currentfolder,
          fecha: new Date(),
          clasescompartidas: this.clasesCompartidas,
        };
      } else {
        folder = {
          nombre: this.foldername,
          propietario: this.currentuserid,
          grupo: [],
          folder: true,
          parent: this.currentfolder,
          fecha: new Date(),
          clasescompartidas: this.clasesCompartidas,
        };
      }
      this.foldername = '';
      this.popCrearCarpetaCompartida();
      this.archivosService
        .createdArchivo(folder)
        .then((filelist) => {
          setTimeout(() => {
            this.getarchivos();
          }, 500);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  checkboxClasesCompartidas(event, uid) {
    if (event.target.checked) {
      this.clasesCompartidas.push(uid);
    } else {
      this.clasesCompartidas = this.clasesCompartidas.filter((m) => m != uid);
    }
  }

  popCrearCarpetaCompartida() {
    this.crearCarpetaCompartida
      ? (this.crearCarpetaCompartida = false)
      : (this.crearCarpetaCompartida = true);
  }
}
