<div *ngIf="cargado">
  <app-header
    [nombres]="nombres"
    [apellidos]="apellidos"
    [colegio]="colegio"
    [genero]="genero"
    [idColegio]="idColegio"
  ></app-header>

  <div class="maingrid">
    <div>
      <app-menu
        [uidb]="mainUserUidDb"
        [uid]="userUid"
        [avatar]="useravatar"
        [rolUser]="rolUsuario"
      ></app-menu>
    </div>

    <div>
      <!-- content -->
      <div class="contenido">
        <section class="basicbox2 horojo medium martop misclases">
          <header routerLink="/padres/mishijos">
            <h3 translate>padres.mishijos</h3>
            <div
              class="icon"
              routerLink="/padres/mishijos"
              (click)="
                UpdateBreadcrumb('padres.mishijos', '/padres/mishijos')
              "
            >
              <div>
                <img src="/assets/img/hijos-dark.svg" />
              </div>
            </div>

           
          </header>
          <div id="flexContainer">
            <article>
              <ng-container *ngFor="let hijo of mishijos; let i = index">
                <!-- clase box -->
                <div class="subbasicbox2">
                  <div class="boximage">
                    <a
                      routerLink="/padres/mishijos/{{ hijo.uid }}"
                      (click)="
                        UpdateBreadcrumb(hijo.nombres, '/padres/mishijos/' + hijo.uid)
                      "
                    >
                      <img
                        *ngIf="hijo.avatar; else defaultclassavatar"
                        src="{{ hijo.avatar }}"
                        alt="{{ hijo.nombres | titlecase }}"
                      />
                      <ng-template #defaultclassavatar
                        ><img
                          src="assets/img/clase_default.jpg"
                          alt="{{ hijo.nombres | titlecase }}"
                      /></ng-template>
                    </a>
                  </div>
                  <div class="boxtitles">
                    <h4>
                      <a
                        routerLink="/padres/mishijos/{{ hijo.uid }}"
                        (click)="
                          UpdateBreadcrumb(hijo.nombres, '/padres/' + hijo.uid)
                        "
                        >{{ hijo.nombres +" "+ hijo.apellidos | titlecase }}
                      </a>
                      <p>
                        {{ hijo.usuario | lowercase }}
                      </p>
                    </h4>
                   
                  </div>
                 
                </div>
                <!--/clase box -->
              </ng-container>
            </article>

            <footer>
              <a
                routerLink="/padres/mishijos"
                (click)="
                  UpdateBreadcrumb(
                    'padres.mishijos',
                    '/padres/mishijos'
                  )
                "
                class="button medium center"
              >
                {{ "dashboard.vertodos" | translate }}
              </a>
            </footer>
          </div>
        </section>

        <section class="contentwrapper">
          <div class="breadcrumb">
            <div id="container">
              <ng-container *ngFor="let bread of breadcrumb" id="container">
                <div routerLink="{{ bread.link }}" id="migas">
                  <div id="migasF">
                    <div id="nameM" translate>
                      {{ bread.nombre |translate |titlecase }}
                    </div>
                    <div>
                      <span
                        class="close"
                        (click)="RemoveBreadcrumb(bread.nombre)"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="11.513"
                          height="8.063"
                          viewBox="0 0 11.513 8.063"
                        >
                          <g
                            id="Grupo_9481"
                            data-name="Grupo 9481"
                            transform="translate(-5.602 -5.601)"
                          >
                            <path
                              id="Trazado_9279"
                              data-name="Trazado 9279"
                              d="M17.115,9.633Z"
                              fill="#fff"
                            />
                            <path
                              id="Trazado_9280"
                              data-name="Trazado 9280"
                              d="M16.889,9.164a.575.575,0,0,0-.814,0l0,0-3.045,3.05L9.982,9.164a.578.578,0,0,0-.817.817l3.05,3.045-3.05,3.045a.578.578,0,0,0,.817.817l3.045-3.05,3.045,3.05a.578.578,0,0,0,.817-.817l-3.05-3.045,3.05-3.045a.575.575,0,0,0,0-.814Z"
                              transform="translate(-3.394 -3.393)"
                              fill="#fff"
                            />
                          </g>
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>

          <!-- contenedor cambiante -->
          <section class="changecontent">
            <router-outlet> </router-outlet>
          </section>
          <!-- /contenedor cambiante -->
        </section>
      </div>
      <!-- /content -->
    </div>
  </div>
</div>
