import { Component, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-posters',
  templateUrl: './posters.component.html',
  styleUrls: ['./posters.component.css'],
})
export class PostersComponent implements OnInit {
  files = [];
  pin = '';
  mostrar = false;
  mensajeError = '';
  remember = false;
  constructor(private storage: AngularFireStorage) {}
  mostrarArchivos = false;
  ngOnInit(): void {
    // this.listFiles()
    this.listFiles()
    if (localStorage.getItem('pinposter')) {
      this.pin = atob(localStorage.getItem('pinposter'));
      this.remember = true;
      this.comprobarPin();
    } else {
      this.remember = false;
    }
  }

  comprobarPin() {
    if (this.pin == 'ACA2424') {
      this.remembercredentials(this.remember);
      this.mensajeError = '';
      this.mostrar = true;
      // this.listFiles()
    } else {
      this.mensajeError = 'Contraseña erronea';
      this.mostrar = false;
    }
  }

  remembercredentials(remember) {
    if (remember) {
      let con = btoa(this.pin);
      localStorage.setItem('pinposter', con);
    }
  }

  olvidarcredenciales() {
    localStorage.removeItem('pinposter');
    this.remember = false;
  }

  rememberme() {
    if (this.remember == false) {
      this.remember = true;
    } else {
      this.remember = false;
    }
  }

  listFiles() {
    this.files = [];
    const ref = this.storage.ref('posterColegia');

    ref.listAll().subscribe((result) => {
      result.items.forEach((fileRef) => {
        console.log(fileRef);
        fileRef.getDownloadURL().then((url) => {
          this.files.push({ name: fileRef.name, url: url });
          // const xhr = new XMLHttpRequest();
          // xhr.responseType = 'blob';
          // xhr.onload = (event) => {
          //   const blob = xhr.response;
          //   saveAs(blob, fileRef.name);
          //   // Save the blob to your computer
          // };
          // xhr.open('GET', url);
          // xhr.send();
          // console.log('XHR', xhr);
        });
      });
    });
    setTimeout(() => {
      this.files.sort((a, b) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase())
      );
    }, 1500);

    return this.files;
  }

  descargarTodos() {
    this.files.forEach((element) => {
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = (event) => {
        const blob = xhr.response;
        saveAs(blob, element.name);
        // Save the blob to your computer
      };
      xhr.open('GET', element.url);
      xhr.send();
      console.log('XHR', xhr);
    });
  }
}
