<!-- <p>{{messages?.payload.doc.data().message}}</p> -->
<div id="container">
  <div class="box-alerta">
    <div id="title">
      <img src="assets/img/alertIcon.svg" />
      <h1 translate>dashboard.alertas.alerta.titulo</h1>
    </div>
    <div id="dataTitle">
      <h2 translate>dashboard.alertas.alerta.nombre</h2>
      <h2 translate>dashboard.alertas.alerta.colegio</h2>
      <h2 translate>dashboard.alertas.alerta.fecha</h2>
      <h2 translate>dashboard.alertas.alerta.id</h2>
    </div>
    <div class="box-message">
      <div id="data">
        <h3>{{ nombreAlumno | titlecase }}</h3>
        <h3>{{ alerta.idColegio | titlecase }}</h3>
        <h3>{{alerta.fechaAlerta | date:"short"}}</h3>
        <h3>{{ alerta.idAlerta }}</h3>
      </div>
      <div id="Message">
        <p ><b translate>{{"dashboard.alertas.alerta.mensaje"|translate}}</b> {{ alerta.mensaje }}</p>
      </div>
    </div>
    <div id="stateMessage">
      <div *ngIf="alerta.estado == 'pendiente'">
        <div class="botones-alerta2">
          <a
            (click)="aceptAlert()"
            style="background-color: #fa3b32"
            class="button"
            >{{'dashboard.alertas.alerta.solicitar' | translate}} </a
          >
          <a
            (click)="discardAlert()"
            style="background-color: rgb(104, 109, 131, 0.8)"
            class="button"
            > {{'dashboard.alertas.alerta.denegar' | translate}}</a
          >
          <!-- <a
            (click)="deleteAlert()"
            style="background-color:rgb(104, 109, 131, 0.8)"
            class="button"
          >{{'dashboard.alertas.alerta.ignorar' | translate}}</a> -->
        </div>
      </div>
      <div id="divA" *ngIf="alerta.estado == 'aceptada'">
        <h2 id="msgA">
         {{"dashboard.alertas.alerta.alerta"|translate}} &nbsp;<b>{{"dashboard.alertas.aceptada"|translate}}</b>
          <!-- <div *ngIf="alerta.estado === 'aceptada'">
            <img src="assets/img/rechazada.svg" />
         
          </div> -->
        </h2>
        <a
          (click)="deleteAlert()"
          style="background-color: #fa3b32"
          class="button"
        >
          <img src="assets/img/delete.svg"/>
        </a>
      </div>
      <div id="divB" *ngIf="alerta.estado == 'rechazada'">
        <h2>
         {{"dashboard.alertas.alerta.alerta"|translate}} &nbsp;<b>{{"dashboard.alertas.rechazada"|translate}}</b>
          <!-- <div *ngIf="alerta.estado === 'rechazada'">
            <img src="assets/img/ok.svg" />
          </div> -->
        </h2>
        <a
          (click)="deleteAlert()"
          style="background-color: #fa3b32"
          class="button"
        >
          <img src="assets/img/delete.svg"/>
        </a>
      </div>
    </div>
  </div>

</div>
