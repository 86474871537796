import { BreadcrumbService } from './../../services/breadcrumb.service';
// import { PresenciaService } from './../../services/presencia.service';
import { Colegio } from './../../interfaces/colegio';
import { ClasesService } from './../../services/clases.service';
import { ColegiosService } from './../../services/colegios.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { AuthenticationService } from '../../services/authentication.service';
import { Clases } from '../../interfaces/clases';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-mishijos',
  templateUrl: './mishijos.component.html',
  styleUrls: ['./mishijos.component.css']
})
export class MishijosComponent implements OnInit {
  datosUsuario: any;
  colegio: Colegio;
  clase: Clases;
  userUid: any;
  // misclases: any;
  nombres: string;
  apellidos: string;
  useravatar: string;
  breadcrumb = [];
  mainUserUidDb: string;
  rolUsuario: string;
  genero: string;
  cargado: boolean = false;
  
  profesoresId = [];
  profesores = [];
  lang: string;
  idColegio: string = "";
  hijos: any =[];
  mishijos:any=[]

  constructor(
    private translate: TranslateService,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private colegioService: ColegiosService,
    private clasesService: ClasesService,
    private breadService: BreadcrumbService
  ) {}


  ngOnInit() {
    if (localStorage.getItem('idioma')) {
      this.lang = localStorage.getItem('idioma');
      this.translate.setDefaultLang(this.lang);
      this.translate.use(this.lang);
    } else {
      this.translate.setDefaultLang('es');
      this.translate.use('es');
    }

    this.breadcrumb = this.breadService.getbread();
    const Userdata: any = this.userService.getUserinfodata();

    if (Userdata === undefined) {
      this.authenticationService.isAuth().subscribe((auth) => {
        if (auth) {
          this.userUid = auth.uid;

          this.userService.getUserById(this.userUid).subscribe((userdata) => {
            if (userdata[0].payload.doc.data()) {
              this.mainUserUidDb = userdata[0].payload.doc.id;

              this.datosUsuario = userdata[0].payload.doc.data();
              if (this.datosUsuario.nombrefalse) {
                this.nombres = this.datosUsuario.nombrefalse;
                this.apellidos = this.datosUsuario.apellidofalse;
              } else {
                this.nombres = this.datosUsuario.nombres;
                this.apellidos = this.datosUsuario.apellidos;
              }
              this.useravatar = this.datosUsuario.avatar;
              this.rolUsuario = this.datosUsuario.rol;
              this.genero = this.datosUsuario.genero;
              this.hijos=this.datosUsuario.hijos;
              this.idColegio = "";
              if (this.datosUsuario.colegios[0]) {
                this.idColegio = this.datosUsuario.colegios[0]
                this.colegioService
                  .getColegioById(this.datosUsuario.colegios[0])
                  .subscribe(
                    (colegio) => {
                      if (colegio) {
                        this.colegio = colegio.payload.data();
                      }
                      this.cargado = true;
                    },
                    (error) => {
                      console.log(error);
                      this.cargado = true;
                    }
                  );

                  this.hijos.forEach(hijo => {
                    this.userService.getUserByIdNow(hijo).subscribe((usuario)=>{
                      this.mishijos.push(usuario.docs[0].data())
                      // console.log(usuario.docs[0].data())
                    })
                  });

                // this.clasesService
                //   .getUserClass(this.userUid)
                //   .subscribe((lasclases) => {
                //     this.misclases = lasclases;
                //     lasclases.forEach((admin) => {
                //       if (
                //         admin.payload.doc.data()['administradores'].length > 0
                //       ) {
                //         this.profesoresId.push(
                //           admin.payload.doc.data()['administradores'][0]
                //         );
                //       }
                //       else{
                //         this.profesoresId.push("")
                //       }
                //     });

                //     for (
                //       let index = 0;
                //       index < this.profesoresId.length;
                //       index++
                //     ) {
                //       const element = this.profesoresId[index];
                //       this.userService
                //         .getUserById(element)
                //         .subscribe((data) => {
                //           data.forEach((us) => {
                //             this.profesores[index] =
                //               us.payload.doc.data()['nombres'];
                //           });
                //         });
                //     }
                //   });
              }
            }
          });
        }
      });

      // this.getCurrentUser();
    } else {
      this.userUid = Userdata.payload.doc.data().uid;

      this.mainUserUidDb = Userdata.payload.doc.id;
      this.datosUsuario = Userdata.payload.doc.data();

      if (this.datosUsuario.nombrefalse) {
        this.nombres = this.datosUsuario.nombrefalse;
        this.apellidos = this.datosUsuario.apellidofalse;
      } else {
        this.nombres = this.datosUsuario.nombres;
        this.apellidos = this.datosUsuario.apellidos;
      }

      this.useravatar = this.datosUsuario.avatar;
      this.rolUsuario = this.datosUsuario.rol;
      this.genero = this.datosUsuario.genero;
      this.hijos=this.datosUsuario.hijos;
      this.idColegio = "";
      if (this.datosUsuario.colegios[0]) {
        this.idColegio = this.datosUsuario.colegios[0]
        this.colegioService
          .getColegioById(this.datosUsuario.colegios[0])
          .subscribe(
            (colegio) => {
              if (colegio) {
                this.colegio = colegio.payload.data();
                this.cargado = true;
              }
            },
            (error) => {
              this.cargado = true;
              console.log(error);
            }
          );
          this.hijos.forEach(hijo => {
            this.userService.getUserByIdNow(hijo).subscribe((usuario)=>{
              this.mishijos.push(usuario.docs[0].data())
              // console.log(usuario.docs[0].data())
            })
          });
        // this.clasesService.getUserClass(this.userUid).subscribe((lasclases) => {
        //   this.misclases = lasclases;
        //   lasclases.forEach((admin) => {
        //     this.profesoresId.push(
        //       admin.payload.doc.data()['administradores'][0]
        //     );
        //   });

        //   for (let index = 0; index < this.profesoresId.length; index++) {
        //     const element = this.profesoresId[index];
        //     this.userService.getUserById(element).subscribe((data) => {
        //       data.forEach((us) => {
        //         this.profesores[index] = us.payload.doc.data()['nombres'];
        //       });
        //     });
        //   }
        // });
      }
    }
  }
}
