import { Component, OnInit } from '@angular/core';
import { ChatService } from '../services/chat.service';
import { UserService } from '../services/user.service';
import { AuthenticationService } from '../services/authentication.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-auditorio',
  templateUrl: './auditorio.component.html',
  styleUrls: ['./auditorio.component.css'],
})
export class AuditorioComponent implements OnInit {
  chatorimessa: any;
  email: any;
  usuariovalido = false;
  password = '';
  nombre="";

  src:SafeResourceUrl;

  listadoUsuarios = ['daniel.candil@laiatech.com', 'antonio@laiatech.com'];
  mensaje: string = '';

  constructor(
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private chatService: ChatService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    const Userdata: any = this.userService.getUserinfodata();
    this.mensaje = '';
    if (Userdata !== undefined) {
      this.email = Userdata.payload.doc.data().email;
      this.nombre =
        Userdata.payload.doc.data().nombres +
        ' ' +
        Userdata.payload.doc.data()
          .apellidos; /*apellidos del usuario logueado */
          this.src= this.sanitizer.bypassSecurityTrustResourceUrl("https://in2.thecampus.education/webapp3/?conference=daniel.candil&role=admin&callType=video&join=1&name="+this.nombre)
      this.usuarioEsValido();
    } else {
      this.authenticationService.isAuth().subscribe((auth) => {
        if (auth) {
          this.email = auth.email; /* email del usuario */
          this.usuarioEsValido();
          this.authenticationService
            .isUserAdmin(auth.uid)
            .subscribe((userdata: any) => {
              if (userdata[0]) {
                this.nombre =
                  userdata[0].nombres +
                  ' ' +
                  userdata[0].apellidos; /*apellidos del usuario logueado */
                  this.src= this.sanitizer.bypassSecurityTrustResourceUrl("https://in2.thecampus.education/webapp3/?conference=daniel.candil&role=admin&callType=video&join=1&name="+this.nombre)
              }
            });
        } else {
          this.usuariovalido = false;
        }
      });
    }
    this.getmessages();
  }

  getmessages() {
    const chatclassid = 'AUDITORIO1-MYF';
    this.chatService.getClaseMessages(chatclassid).subscribe((message) => {
      this.chatorimessa = message;
      // this.chatorimessa.forEach((messageData: any) => {
      //   this.userchat = messageData.payload.doc.data().sender;
      //   if (!this.chatusers[this.userchat.uid]) {
      //     this.chatusers[this.userchat.uid] = userchat[0].payload.doc.data();
      //   }
      // });
    });
  }

  usuarioEsValido() {
    console.log('result', this.listadoUsuarios.includes(this.email));
    this.usuariovalido = this.listadoUsuarios.includes(this.email);
    this.mensaje = 'Este usuario no puede acceder';
  }

  login() {
    this.mensaje = '';
    this.authenticationService
      .loginWithEmail(this.email, this.password)
      .then((data) => {
        this.authenticationService
        .isUserAdmin(data.user.uid)
        .subscribe((userdata:any) => {
          if (userdata[0]) {
            this.nombre =userdata[0].nombres + " " + userdata[0].apellidos; /*apellidos del usuario logueado */
            this.src= this.sanitizer.bypassSecurityTrustResourceUrl("https://in2.thecampus.education/webapp3/?conference=daniel.candil&role=admin&callType=video&join=1&name="+this.nombre)
            }
          });
        this.usuarioEsValido();
      })
      .catch(() => {
        this.usuariovalido = false;
      });
  }
  scrolltobottom() {
    const objDiv = document.getElementById('themessagesbox');
    objDiv.scrollTop = objDiv.scrollHeight + 1000;
  }
}
