<div class="container1">
  <div class="izquierda">
    <div class="foto">
      <img src="{{datos.avatar}}" alt="">
    </div>
    <div class="titulo">
      <h2>
        {{ datos.nombres + " " + datos.apellidos | titlecase }}
      </h2>
      <h3>
        {{ datos.usuario }}
      </h3>
    </div>
  </div>

  <!-- <div class="clasesbox">
    <p>
      <b> {{ "padres.claseshijo" | translate }} </b>
    </p>
    <div class="clases">
      <div *ngFor="let clase of clases">
        <p class="nombreclase" routerLink="/dashboard/clase/{{clase.uid}}">{{ clase.nombre | titlecase}}</p>
      </div>
    </div>
  </div> -->
  <div class="ver">
    <button routerLink="{{ datos.uid }}">{{ "padres.ver" | translate }}</button>
  </div>
</div>
