import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-manual',
  templateUrl: './manual.component.html',
  styleUrls: ['./manual.component.css']
})
export class ManualComponent implements OnInit {

  manual: string="https://firebasestorage.googleapis.com/v0/b/mygofacer.appspot.com/o/manuales%2FVMR_UserGuide_ENG.pdf?alt=media&token=e57fa4cc-fd79-438f-9ef3-f294eace7325";
  idioma="en"
  constructor() { }

  ngOnInit(): void {

    if (localStorage.getItem('lenguaje')) {
      this.idioma = localStorage.getItem('lenguaje');
    }
    switch (this.idioma) {
      case 'es':
        this.manual="https://firebasestorage.googleapis.com/v0/b/the-campus-56b06.appspot.com/o/Manualuser%2FManual_Alumno_2122(ESP).pptx?alt=media&token=98f79592-9f02-4c2e-8a75-ea324a1c33b4"
        break;
    
      default:
        this.manual="https://firebasestorage.googleapis.com/v0/b/the-campus-56b06.appspot.com/o/Manualuser%2FStudent%C2%B4s%20Manual(ENG).pptx?alt=media&token=e48e417d-31c5-4415-8575-a568b40f6a5f"
        break;
    }
  }

}