import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-apps-internas',
  templateUrl: './apps-internas.component.html',
  styleUrls: ['./apps-internas.component.css']
})
export class AppsInternasComponent implements OnInit {
  iframe: string;

  constructor(private activatedRoute: ActivatedRoute) {

    /* toma el parametro uid que viene en la url */
    this.activatedRoute.params.subscribe(params => {
      /* uid del evento actual */
      let nurl = params['url'].replaceAll("+", "/");
      this.iframe = 'https://' + nurl;

    });

   }

  ngOnInit(): void {
  }

}
