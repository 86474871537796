<div class="cuerpo" id="video">
  <div #scriptContainer class="grabacion">
    <!-- <div id="web-player-0"></div> -->
    <div id="rec-live-9IDfJXZlosz57Zsl"></div>
  </div>
  <div class="chat" *ngIf="nombre != '' && apellidos != ''">
    <div class="hilo" >
      <div class="messages" id="themessagesbox" [scrollTop]="scrolltobottom()">
        <ng-container *ngFor="let messages of chatorimessa">
          <div class="message">
            <div class="avatar">
              <ng-template #defaultavatar
                ><img src="assets/img/defaultavatar.jpg" alt=""
              /></ng-template>
            </div>
            <div class="messagedata">
              <div class="name"></div>
              <div class="date">
                {{
                  messages?.payload.doc.data().fecha.toDate()
                    | date : "dd/MM/y - HH:mm"
                }}h
                {{ messages?.payload.doc.data().sender | titlecase }}
                <!-- {{messages.nombre | titlecase}} -->
              </div>
              <div class="themessage">
                <p>
                  {{ messages?.payload.doc.data().message }}
                  <!-- {{ messages.mensaje }} -->
                </p>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="sendbox">
      <input
        class="messagebox"
        type="text"
        placeholder="{{ 'dashboard.mensajes.escribirmensaje' | translate }}"
        [(ngModel)]="chatmessage"
        (keyup.enter)="sendmessage()"
      />
      <button class="sendmessage" (click)="sendmessage()">
        {{ "dashboard.mensajes.enviarmensaje" | translate }}
      </button>
    </div>
  </div>
  <div class="noHilo" *ngIf="nombre == '' || apellidos == ''">
    <div class="sendbox2">
        <div class="logo">
            <img src="assets/img/logocolor.png" alt="" />
            <!-- <img src="assets/img/logocolor.png" alt="" /> -->
          </div>
        <label>Introduce tu nombre y apellidos para participar en el chat</label>
        <div class="inputs">
            <p>Nombre</p>
            <input class="messagebox" type="text" [(ngModel)]="nombreForm" />
        </div>
        <div class="inputs">
            <p>Apellidos</p>
            <input  class="messagebox" type="text" [(ngModel)]="apellidosForm" />
        </div>
        <button class="sendmessage" class="sendmessage" (click)="credenciales()">
          Insertar
        </button>
    </div>
  </div>
</div>
