import { Component, OnInit, Input } from '@angular/core';
import { Colegio } from 'src/app/interfaces/colegio';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ColegiosService } from 'src/app/services/colegios.service';
import { UserService } from 'src/app/services/user.service';
import { BannerService } from '../../services/banner.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  @Input() nombres: string;
  @Input() apellidos: string;
  @Input()colegio: Colegio;
  @Input() genero:string;
  @Input() idColegio:string;

  background="#FA3B32"
  avatar: any = "assets/img/logo.png";
  banner:boolean=true
  banner2:boolean=false

  bannerDatos:any
  logoBanner:any=""
  tituloBanner: any = "";
  subtituloBanner: any = "";
  
  constructor(
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private colegioService: ColegiosService,
    private bannerService: BannerService
  ) {
  }

  ngOnInit(): void {
    if(this.colegio.background){
      this.background = this.colegio.background;
    }
    if(this.colegio.avatar){
      this.avatar = this.colegio.avatar
    }
    this.bannerService.getBannerByColegio(this.idColegio).subscribe(banners=>{
      if(banners.length>0){
        var datoBanner:any = banners[0].payload.doc.data()
        this.bannerDatos = datoBanner
        this.logoBanner = datoBanner.imagen
        this.tituloBanner = datoBanner.titulo
        this.subtituloBanner = datoBanner.subtitulo
        this.banner=this.bannerDatos.activo;
      }
      else{
        this.banner=false
      }
    })

  }

  abrirEnlace(){
    console.log("me voooy", this.idColegio)
    window.open(this.bannerDatos.url)
  }

  quitarBanner(){
    this.banner=!this.banner
    this.banner2=!this.banner2

  }
}