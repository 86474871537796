import { LicenciaService } from './../services/licencia.service';
import { ClasesService } from './../services/clases.service';
import { CursosService } from './../services/cursos.service';
import { ColegiosService } from './../services/colegios.service';
import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { UserService } from '../services/user.service';
import * as firebase from 'firebase/app';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-registro',
  templateUrl: './registro.component.html',
  styleUrls: ['./registro.component.css'],
})
export class RegistroComponent implements OnInit {
  nombres: string = null;
  apellidos: string = null;
  email: string = null;
  password: string = null;
  // direccion: string = null;
  licencia: string = null;
  pin: string = null;
  fechana: string = null;
  colegio: string = null;
  cursos = [];
  // localidad: string = null;
  asignaturas = [];
  response: string = null;
  // pais: string = '';
  genero: string = '';
  telefono: string = '';

  colegiosdb: any = '';
  cursosdb = [];
  clasesdb = [];
  colegiodata: any;
  cursodata: any;
  asignaturadata: any;
  regcolegio: any;
  regcurso: any;
  regasignatura = [];
  licenciadb: any;
  confirmacion = false;
  error = false;
  enviado = true;
  mayuscula = false;
  minuscula = false;
  numero = false;
  caracter = false;
  caractount = false;

  infoPassword: boolean = false;
  licenciaCorrecta = false;
  idColegio = '';
  nombreColegio = '';

  registerEmail = false;
  usuario: any = '';
  existeUsuario: boolean = false;
  en: boolean;
  es: boolean;
  ar: boolean;
  click: boolean;
  idioma: any;
  lang: string;
  instruccionespdf =
    'https://firebasestorage.googleapis.com/v0/b/the-campus-56b06.appspot.com/o/Manualuser%2FInstructions%20-%20Student%20Registration.pdf?alt=media&token=87e42a18-00b1-4010-9ee1-66524238e236';

  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private cursosService: CursosService,
    private clasesService: ClasesService,
    private licenciaService: LicenciaService,
    private functions: AngularFireFunctions,
    private colegiosService: ColegiosService,
    private router: Router,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.asignaturas = new Array();

    if (localStorage.getItem('idioma')) {
      this.lang = localStorage.getItem('idioma');
      this.translate.setDefaultLang(this.lang);
      this.translate.use(this.lang);
      switch (this.lang) {
        case 'es':
          this.es = true;
          this.instruccionespdf =
            'https://firebasestorage.googleapis.com/v0/b/the-campus-56b06.appspot.com/o/Manualuser%2FInstructions%20-%20Student%20Registration.pdf?alt=media&token=87e42a18-00b1-4010-9ee1-66524238e236';
          break;
        case 'en':
          this.en = true;
          this.instruccionespdf =
            'https://firebasestorage.googleapis.com/v0/b/the-campus-56b06.appspot.com/o/Manualuser%2FInstructions%20-%20Student%20Registration.pdf?alt=media&token=87e42a18-00b1-4010-9ee1-66524238e236';
          break;
        case 'ar':
          this.ar = true;
          this.instruccionespdf =
            'https://firebasestorage.googleapis.com/v0/b/the-campus-56b06.appspot.com/o/Manualuser%2FInstructions%20-%20Student%20Registration.pdf?alt=media&token=87e42a18-00b1-4010-9ee1-66524238e236';
          break;
        default:
          this.instruccionespdf =
            'https://firebasestorage.googleapis.com/v0/b/the-campus-56b06.appspot.com/o/Manualuser%2FInstructions%20-%20Student%20Registration.pdf?alt=media&token=87e42a18-00b1-4010-9ee1-66524238e236';
          break;
      }
    } else {
      this.es = true;
      this.translate.setDefaultLang('es');
      this.translate.use('es');
      localStorage.setItem('idioma', 'es');
    }

    // this.getmaindata();
  }

  // getmaindata() {
  //   this.getcolegios();
  // }

  // getcolegios() {
  //   this.colegiosService.getColegiosNow().subscribe((colegios) => {
  //     this.colegiosdb = colegios;
  //   });
  // }

  selectcolegio(event) {
    let obs1 = this.cursosService
      .getCursosByColegioidNow(event)
      .subscribe((cursos) => {
        this.cursosdb = new Array();
        cursos.forEach((curso) => {
          this.cursosdb.push(curso);
        });
        obs1.unsubscribe();
        // this.cursosdb = cursos[0];
        // this.selectcurso(cursos[0].payload.doc.id);

        // this.clasesService.getClaseByColegioNow(event).subscribe((clases) => {
        //   this.clasesdb = [];
        //   clases.forEach((seclase) => {
        //     if (
        //       !this.clasesdb.some(
        //         (el) => el.id === seclase.id
        //       )
        //     ) {
        //       this.clasesdb.push(seclase);
        //     }
        //   });
        // });
      });
  }

  selectcurso(event) {
    this.clasesdb = [];
    this.asignaturas = [];
    let obs2 = this.clasesService
      .getClaseByCursoidNow(event)
      .subscribe((clases) => {
        console.log(clases);
        this.clasesdb = [];
        clases.forEach((seclase) => {
          if (!this.clasesdb.some((el) => el.id === seclase.id)) {
            this.clasesdb.push(seclase);
          }
        });
        obs2.unsubscribe();
      });
  }

  existeUser() {
    let obs3 = this.userService
      .getUserByUsuario(this.usuario.toLowerCase())
      .subscribe((user) => {
        console.log("--existeUser", user)
        if (user.size > 0) {
          this.existeUsuario = true;
          console.log("--EXISTE")
          // obs3.unsubscribe();
        } else {
          this.existeUsuario = false;
          console.log("--NOEXISTE")
          // obs3.unsubscribe();
        }
      });
  }

  register() {
    this.usuario = this.usuario.replace(/\s+/g, '');
    console.log("--", this.usuario)
    if (this.usuario != '') {
      this.existeUser();
      console.log("--despuesexisteuser")
    }
    setTimeout(() => {
      if (this.existeUsuario) {
        this.translate.get('registro.responenombre').subscribe((data) => {
          this.response = data;
          console.log("--existeusuario", data)
          return;
        });
      }

      if (this.nombres === null || this.nombres === '') {
        this.translate.get('registro.responsenombrevacio').subscribe((data) => {
          this.response = data;
          return;
        });
      }

      if (this.apellidos === null || this.apellidos === '') {
        this.translate.get('registro.responseapellidos').subscribe((data) => {
          this.response = data;
          return;
        });
      }

      if (this.fechana === null || this.fechana === '') {
        this.translate.get('registro.responsenacimiento').subscribe((data) => {
          this.response = data;
          return;
        });
      }

      if (
        this.email === null ||
        this.email === '' ||
        !this.validateEmail(this.email)
      ) {
        this.translate.get('registro.responseemail').subscribe((data) => {
          this.response = data;
          return;
        });
      }

      if (this.telefono === null || this.telefono === '') {
        this.translate.get('registro.responsetelefono').subscribe((data) => {
          this.response = data;
          return;
        });
      }

      // if (this.direccion === null || this.direccion === '') {
      //   this.translate.get('registro.responsedireccion').subscribe((data) => {
      //     this.response = data;
      //     return;
      //   })
      // }
      // if (this.pais === null || this.pais === '') {
      //   this.translate.get('registro.responsepais').subscribe((data) => {
      //     this.response = data;
      //     return;
      //   })

      // }
      if (this.genero === null || this.genero === '') {
        this.translate.get('registro.responsegenero').subscribe((data) => {
          this.response = data;
          return;
        });
      }

      if (this.password === null || this.password === '') {
        this.translate.get('registro.responsecontraseña').subscribe((data) => {
          this.response = data;
          return;
        });
      }

      if (this.licencia === null || this.licencia === '') {
        this.translate.get('registro.responselicencia').subscribe((data) => {
          this.response = data;
          return;
        });
      }

      // tslint:disable-next-line: max-line-length
      if (
        this.pin === null ||
        this.pin === '' ||
        !this.validateNumbers(this.pin) ||
        this.pin.toString().length > 4 ||
        this.pin.toString().length < 4
      ) {
        this.translate.get('registro.responsepin').subscribe((data) => {
          this.response = data;
          return;
        });
      }

      if (this.colegio === null || this.colegio === '') {
        this.translate.get('registro.responsecolegio').subscribe((data) => {
          this.response = data;
          return;
        });
      }

      if (this.cursos === undefined || this.cursos.length <= 0) {
        this.translate.get('registro.responsecurso').subscribe((data) => {
          this.response = data;
          return;
        });
      }

      if (this.asignaturas === undefined || this.asignaturas.length <= 0) {
        this.translate.get('registro.responseasignatura').subscribe((data) => {
          this.response = data;
          return;
        });
      }

      if (
        this.mayuscula !== true &&
        this.minuscula !== true &&
        this.numero !== true &&
        this.caracter !== true &&
        this.caractount !== true
      ) {
        this.translate
          .get('registro.responsecontraseñasegura')
          .subscribe((data) => {
            this.response = data;
            return;
          });
      }
      this.translate.get('registro.responseregistrando').subscribe((data) => {
        this.response = data;
        this.getcolegiodata();
      });
    }, 2500);
  }

  getcolegiodata() {
    let obs4 = this.colegiosService
      .getColegioByIdNow(this.colegio)
      .subscribe((colegio) => {
        this.colegiodata = colegio;
        this.regcolegio = [this.colegio];
        console.log("--colegio", colegio)
        this.updatelicencia();
        // obs4.unsubscribe();
      });
  }

  updatelicencia() {
    let obs5 = this.licenciaService
      .searchLicenciaNow(this.licencia)
      .subscribe((licen1) => {
        if (licen1.empty) {
          this.error = true;
          this.enviado = false;
          this.confirmacion = false;

          this.translate
            .get('registro.responsenumerolicencia')
            .subscribe((data) => {
              this.response = data;
              obs5.unsubscribe();
              return;
            });
        }
        licen1.forEach((licen) => {
          console.log(licen);
          this.licenciadb = licen.data();
          console.log(this.colegiodata.id);
          if (
            this.licenciadb.usado == false &&
            this.licenciadb.colegio.toLowerCase() ==
              this.colegiodata.id.toLowerCase()
          ) {
            console.log('licenciaAntes', this.licenciadb);
            this.licenciadb.usado = true;
            this.licenciaService.updateLicencia(licen.id, this.licenciadb);
            console.log('licenciaPreborrado', this.licenciadb);
            this.licenciadb = [];
            console.log('licencia', this.licenciadb);
            this.licenciaCorrecta = false;
            this.getcursodata();
          } else {
            this.error = true;
            this.enviado = false;
            this.confirmacion = false;

            this.translate
              .get('registro.responselicenciaincorrecta')
              .subscribe((data) => {
                this.response = data;
              });
          }
        });
        obs5.unsubscribe();
      });
  }

  getcursodata() {
    let obs6 = this.cursosService
      .getCursosById(this.cursos)
      .subscribe((curso) => {
        this.cursodata = curso;
        this.regcurso = [
          { nombre: this.cursodata.payload.data().nombre, uid: this.cursos },
        ];
        this.getclasedata();
        obs6.unsubscribe();
      });
  }

  getclasedata() {
    this.asignaturas.forEach((asignatura) => {
      const obs7 = this.clasesService
        .getClaseById(asignatura)
        .subscribe((clase) => {
          this.asignaturadata = clase;
          this.regasignatura.push(asignatura);
          obs7.unsubscribe();
        });
      });
      setTimeout(() => {
        
        this.registeruser();
      }, 2000);
  }

  registeruser() {
    console.log("--Registrando usuario: ", this.email)
    this.authenticationService
      .registerWithEmail(this.email, this.password)
      .then((data) => {
        console.log("--devuelve data", data)
        if (this.registerEmail == false) {
          this.registerEmail = true;
          if (data) {
            console.log("--Datos", data)
            const user = {
              nombres: this.nombres.toLowerCase(),
              apellidos: this.apellidos.toLowerCase(),
              email: this.email.toLowerCase(),
              // direccion: this.direccion,
              licencia: this.licencia,
              fechanac: new Date(this.fechana + ' 00:00'),
              fechana: this.fechana,
              colegios: this.regcolegio,
              cursos: this.regcurso,
              // localidad: this.localidad,
              clases: this.regasignatura,
              uid: data.user.uid,
              // pais: this.pais,
              genero: this.genero,
              telefono: this.telefono,
              avatar: '',
              codigo: '',
              rol: 'estudiante',
              usuario: this.usuario.toLowerCase(),
            };
            this.userService
              .createdUser(user)
              .then((data2) => {
                console.log("datos", data2)
                this.updateclase(data.user.uid);
                // this.authenticationService.sendEmailverification();
                this.sendpexipdata();
                setTimeout(() => {
                  this.router.navigate(['login']);
                }, 500);
              })
              .catch((error) => {
                this.error = true;
                this.enviado = false;
                this.confirmacion = false;

                this.translate
                  .get('registro.responsenocrear')
                  .subscribe((data) => {
                    this.response = data;
                  });
                this.registerEmail = false;
              });
          } else {
            this.error = true;
            this.enviado = false;
            this.confirmacion = false;
            this.registerEmail = false;

            this.translate
              .get('registro.responsenoregistrar')
              .subscribe((data) => {
                this.response = data;
              });
          }
        }
      });
  }

  updateclase(newuseruid) {
    const clasedata = {
      usuarios: firebase.firestore.FieldValue.arrayUnion(newuseruid),
    };
    this.asignaturas.forEach((asignatura) => {
      this.clasesService.editClase(asignatura, clasedata).then();
      console.log("--Clase actualizada", asignatura)
    });
  }

  validateEmail(email) {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  validateNumbers(numbers) {
    const re = /^[0-9]+$/;
    return re.test(numbers);
  }

  testPassword(pwString) {
    if (pwString) {
      this.infoPassword = true;
    }
    if (/[A-Z]+/.test(pwString)) {
      this.mayuscula = true;
    } else {
      this.mayuscula = false;
    }

    if (/[a-z]+/.test(pwString)) {
      this.minuscula = true;
    } else {
      this.minuscula = false;
    }

    if (/[0-9]+/.test(pwString)) {
      this.numero = true;
    } else {
      this.numero = false;
    }

    if (/[\W]+/.test(pwString)) {
      this.caracter = true;
    } else {
      this.caracter = false;
    }

    if (pwString.length > 6) {
      this.caractount = true;
    } else {
      this.caractount = false;
    }
  }

  countnumers(event) {
    if (event.toString().length >= 4) {
      this.pin = event.toString().slice(0, -1);
    }
  }

  returntoform() {
    this.confirmacion = false;
    this.error = false;
    this.enviado = true;
  }

  sendpexipdata() {
    let nombre = this.nombres + ' ' + this.apellidos;
    nombre = nombre.replace(/[^a-zA-Z ]/g, '');
    let rand = Math.floor(Math.random() * (1000 - 0)) + 0;

    const bodydata = {
      name: this.usuario.toLowerCase(),
      description:
        this.nombres.normalize('NFD').replace(/[\u0300-\u036f]/g, '') +
        ' ' +
        this.apellidos.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
      primary_owner_email_address: this.email,
      automatic_participants: [],
      pin: this.pin,
      service_type: 'conference',
      call_type: 'video',
      max_callrate_in: '2048',
      max_callrate_out: '2048',
      allow_guests: true,
      participant_limit: 10,
      enable_overlay_text: true,
      tag: 'estudiante ' + this.nombreColegio,
      ivr_theme: '/api/admin/configuration/v1/ivr_theme/63/',
      aliases: [
        { alias: this.usuario.toLowerCase(), description: 'Nombre de Usuario' },
        {
          alias:
            this.nombres.normalize('NFD').replace(/[\u0300-\u036f]/g, '') +
            this.apellidos.normalize('NFD').replace(/[\u0300-\u036f]/g, '') +
            rand,
          description: 'NOMBRE APELLIDO NUMERO RANDOM',
        },
        {
          alias: 'user_' + this.licencia + '@thecampus.education',
          description: 'URI',
        },
      ],
    };

    const callable = this.functions.httpsCallable('addRoom');
    const obs = callable({ saladata: bodydata });

    obs.subscribe(async (res) => {
      this.enviado = false;
      this.confirmacion = true;
      this.error = false;
    });
  }

  comprobarDatosLincencia(codigo) {
    this.response = '';
    if (codigo) {
      this.clasesdb = [];
      this.asignaturas = [];
      let obs8 = this.licenciaService
        .searchLicenciaNow(codigo)
        .subscribe((licen1) => {
          if (licen1.empty) {
            this.colegiosdb = null;
            this.cursosdb = null;
            this.licenciaCorrecta = false;
            this.error = true;

            this.translate
              .get('registro.responselicencianovalida')
              .subscribe((data) => {
                this.response = data;
                obs8.unsubscribe();
                return;
              });
          }
          licen1.forEach((licen) => {
            console.log('licendata', licen.data());
            var licenciaDatos = licen.data();
            if (licenciaDatos.usado == false) {
              this.licenciaCorrecta = true;
              this.colegiosService
                .getColegioByIdNow(licenciaDatos.colegio)
                .subscribe((cole) => {
                  console.log('cole', cole.data());
                  this.colegio = this.nombreColegio;
                  this.idColegio = cole.id;
                  this.nombreColegio = cole.data()['nombre'];
                  this.colegiosdb = cole;
                  this.selectcolegio(licenciaDatos['colegio']);
                });
            } else {
              this.licenciaCorrecta = false;
              this.error = true;

              this.translate
                .get('registro.responselicenciaincorrecta')
                .subscribe((data) => {
                  this.response = data;
                });
            }
          });
          obs8.unsubscribe();
        });
    } else {
      this.error = true;
      this.translate
        .get('registro.responsenumerolicencia')
        .subscribe((data) => {
          this.response = data;
        });
    }
  }

  getClassId(event, uid) {
    if (event.target.checked) {
      //console.log(uid + " checked")
      this.asignaturas.push(uid);
    } else {
      //console.log(uid + " UNchecked");
      this.asignaturas = this.asignaturas.filter((m) => m != uid);
    }
  }

  changeLang(lang: string) {
    this.translate.use(lang);
    localStorage.setItem('idioma', lang);
  }

  espanol() {
    this.translate.use('es');
    this.es = true;
    this.en = false;
    this.ar = false;
    localStorage.setItem('idioma', 'es');
    this.instruccionespdf =
      'https://firebasestorage.googleapis.com/v0/b/the-campus-56b06.appspot.com/o/Manualuser%2FInstructions%20-%20Student%20Registration.pdf?alt=media&token=87e42a18-00b1-4010-9ee1-66524238e236';
    this.activeLanguage();
  }

  ingles() {
    this.translate.use('en');
    this.en = true;
    this.es = false;
    this.ar = false;
    localStorage.setItem('idioma', 'en');
    this.instruccionespdf =
      'https://firebasestorage.googleapis.com/v0/b/the-campus-56b06.appspot.com/o/Manualuser%2FInstructions%20-%20Student%20Registration.pdf?alt=media&token=87e42a18-00b1-4010-9ee1-66524238e236';
    this.activeLanguage();
  }

  arabe() {
    this.translate.use('ar');
    this.en = false;
    this.es = false;
    this.ar = true;
    localStorage.setItem('idioma', 'ar');
    this.instruccionespdf =
      'https://firebasestorage.googleapis.com/v0/b/the-campus-56b06.appspot.com/o/Manualuser%2FInstructions%20-%20Student%20Registration.pdf?alt=media&token=87e42a18-00b1-4010-9ee1-66524238e236';
    this.activeLanguage();
  }

  changeColor() {
    this.click = !this.click;
  }

  activeLanguage() {
    this.idioma ? (this.idioma = false) : (this.idioma = true);
  }

  // cargarColegioByLicencia(codigo) {
  //   this.licenciaService.searchLicencia(codigo).subscribe((licen) => {
  //     if (licen) {
  //       var licenciaDatos = licen[0].payload.doc.data();
  //       this.colegiosService
  //         .getColegioById(licenciaDatos['colegio'])
  //         .subscribe((cole) => {
  //           var arrayCole = new Array();
  //           arrayCole.push(cole);
  //           this.colegiosdb = arrayCole;
  //           arrayCole.pop();
  //           this.selectcolegio(licenciaDatos['colegio']);
  //         });
  //     } else {
  //       this.colegiosdb = null;
  //       this.cursosdb = null;
  //     }
  //   });
  // }
}
