import { Component, Input, OnInit } from '@angular/core';
import { ClasesService } from 'src/app/services/clases.service';

@Component({
  selector: 'app-hijobox',
  templateUrl: './hijobox.component.html',
  styleUrls: ['./hijobox.component.css']
})
export class HijoboxComponent implements OnInit {
  @Input() datos;
  clases:any =[]
  constructor(
    private clasesServices: ClasesService
  ) { }

  ngOnInit(): void {
    this.clasesServices.getUserClass(this.datos.uid).subscribe((clases)=>{
      this.clases =[]
      clases.forEach(clase=>{
        var clasedatos = clase.payload.doc.data()
        this.clases.push(clasedatos)
      })
    })

  }

}
