<!-- mensajes -->

<div class="archivos">
  <section class="basicbox clase">
    <header>
      <div class="wrapicon">
        <img src="/assets/img/folder-b.svg" alt="" />
      </div>
      <div>
        <h3 *ngIf="urluid == 'user'; else defaultitle" translate>
          dashboard.archivos.titulo
        </h3>
        <ng-template #defaultitle>
          <h3>
            {{ "dashboard.archivos.de" | translate }}
            {{ clasenombre | titlecase }}
          </h3>
        </ng-template>
      </div>
      <div *ngIf="esColegia" class="poster">
        <label for="upload-file" class="custom-file-upload">
          <input type="file" id="upload-file" (change)="subirPoster($event)" />
          {{ "dashboard.archivos.poster" | translate }}
        </label>
        <div class="deletePoster" (click)="deletePoster()">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="18"
            height="18"
          >
            <path
              d="M17 6H22V8H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V8H2V6H7V3C7 2.44772 7.44772 2 8 2H16C16.5523 2 17 2.44772 17 3V6ZM18 8H6V20H18V8ZM9 11H11V17H9V11ZM13 11H15V17H13V11ZM9 4V6H15V4H9Z"
              fill="rgba(255,255,255,1)"
            ></path>
          </svg>
        </div>
      </div>
      <div *ngIf="!esColegia"></div>
    </header>

    <div class="Actions">
      <div class="grupo1">
        <div class="searchfile">
          <input
            type="text"
            placeholder="{{ 'dashboard.archivos.buscar' | translate }} "
            (keyup)="search()"
            [(ngModel)]="searchValue"
          />
          <a id="buttonArchivo">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20.578"
              height="20.6"
              viewBox="0 0 20.578 20.6"
            >
              <path
                id="Trazado_9282"
                data-name="Trazado 9282"
                d="M30.993,29.611,27.185,25.8a9.091,9.091,0,1,0-1.457,1.482l3.808,3.808a1.009,1.009,0,0,0,.716.307.928.928,0,0,0,.716-.307A1.074,1.074,0,0,0,30.993,29.611Zm-3.834-9.584a7.156,7.156,0,1,1-2.1-5.086A7.152,7.152,0,0,1,27.159,20.027Z"
                transform="translate(-10.7 -10.8)"
                fill="#fff"
              />
            </svg>
          </a>
        </div>

        <div class="breadcrumfile">
          <ng-container *ngFor="let bread of this.breadcrumb; let i = index">
            <ng-container *ngIf="i == 0">
              <a (click)="returnhome()"> {{ bread.name | translate }} / </a>
            </ng-container>
            <ng-container *ngIf="i != 0">
              <a (click)="gofolder(i, bread.uid)"> {{ bread.name }} / </a>
            </ng-container>
          </ng-container>
        </div>
      </div>

      <div class="grupo2">
        <div class="buttons">
          <div class="subir">
            <ng-container *ngIf="espacioocupado >= 0">
              <ng-container *ngIf="espacioocupado < maxspace">
                <div (click)="file.click()">
                  <input
                    [hidden]="true"
                    type="file"
                    (change)="uploadfile($event)"
                    #file
                  />
                  <a class="button" id="buttonArchivo">
                    {{ "dashboard.archivos.subir" | translate }}
                  </a>
                </div>
              </ng-container>

              <ng-container *ngIf="currentuserrol != 'estudiante'">
                <div id="compartida" *ngIf="urluid == 'user'">
                  <a
                    (click)="popCrearCarpetaCompartida()"
                    class="button"
                    id="buttonArchivo"
                  >
                    {{ "dashboard.archivos.compartida" | translate }}
                  </a>
                </div>
              </ng-container>
            </ng-container>
          </div>
          <!--  Meeting Your Future -->
          <div class="createfoldercol">
            <div class="createfolder">
              <input
                type="text"
                placeholder="{{ 'dashboard.archivos.nombreP' | translate }}"
                [(ngModel)]="foldername"
              />
              <a (click)="createFolder()" id="buttonArchivo">
                <img src="/assets/img/plusicon.svg" />
              </a>
            </div>
          </div>
        </div>
        <!-- CREAR CARPETA COMPARTIDA-->

        <div class="claseComp" *ngIf="crearCarpetaCompartida">
          <div id="compContainer">
            <div id="name">
              <label for="claseComp1"
                >{{ "dashboard.archivos.nombre" | translate }}
              </label>
              <input
                type="text"
                placeholder="{{ 'dashboard.archivos.nombreP' | translate }}"
                [(ngModel)]="foldername"
              />
            </div>
            <div class="checkclases">
              <label for="clasesComp2"
                >{{ "dashboard.archivos.clase" | translate }}
              </label>
              <div class="checkbox-inline" *ngFor="let clase of clases">
                <input
                  type="checkbox"
                  name="{{ clase?.id }}"
                  [value]="clase?.id"
                  (change)="checkboxClasesCompartidas($event, clase?.id)"
                />
                <p>{{ clase?.data().nombre | titlecase }}</p>
              </div>
              <a
                class="button"
                id="crearComp"
                (click)="createFolderCompartida()"
              >
                {{ "dashboard.archivos.crear" | translate }}
              </a>
            </div>
          </div>
        </div>

        <div class="ocupado">
          <div>
            <progress
              value="{{ espacioocupado }}"
              max="{{ maxspace }}"
            ></progress>
          </div>
          <div>
            <p translate class="maxfile">
              <br />
              dashboard.archivos.ocupados
              <b>{{ espacioocupado / 1024 / 1024 | number : "1.2-2" }}</b
              >{{ "dashboard.archivos.mb" | translate }} 1000 mb<br />
              <ng-container *ngIf="porcentaje != null">
                {{ "dashboard.archivos.subiendo" | translate }}
                {{ porcentaje }} %
              </ng-container>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="filestable">
      <div class="dataTitle">
        <div class="order">
          <img
            src="/assets/img/sort.svg"
            alt="order"
            (click)="ordenarPorNombre()"
            title="{{ 'dashboard.archivos.ordenarN' | translate }}"
          />
          <p translate>dashboard.archivos.nombreA</p>
        </div>
        <div>
          <p translate>dashboard.archivos.tamano</p>
        </div>
        <div class="order2">
          <img
            src="/assets/img/sort.svg"
            alt="order"
            (click)="ordenarPorFecha()"
            title="{{ 'dashboard.archivos.ordenarF' | translate }}"
          />
          <p translate>dashboard.archivos.fecha</p>
        </div>
        <div id="cp">
          <p translate>dashboard.archivos.compartido</p>
        </div>
      </div>
      <div class="tablecontent">
        <ng-container
          *ngFor="
            let archivo of this.losarchivos | search : searchValue;
            let i = index
          "
        >
          <ng-container *ngIf="archivo.filedata.folder">
            <!-- file item -->
            <div class="filename">
              <div
                class="delete"
                *ngIf="
                  archivo.filedata.propietario == currentuserid;
                  else elseboxfolder
                "
                id="buttonArchivo"
                (click)="deleteUpCarpeta(archivo)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="11.513"
                  height="8.063"
                  viewBox="0 0 11.513 8.063"
                >
                  <g
                    id="Grupo_9481"
                    data-name="Grupo 9481"
                    transform="translate(-5.602 -5.601)"
                  >
                    <path
                      id="Trazado_9279"
                      data-name="Trazado 9279"
                      d="M17.115,9.633Z"
                      fill="#2D3453"
                    />
                    <path
                      id="Trazado_9280"
                      data-name="Trazado 9280"
                      d="M16.889,9.164a.575.575,0,0,0-.814,0l0,0-3.045,3.05L9.982,9.164a.578.578,0,0,0-.817.817l3.05,3.045-3.05,3.045a.578.578,0,0,0,.817.817l3.045-3.05,3.045,3.05a.578.578,0,0,0,.817-.817l-3.05-3.045,3.05-3.045a.575.575,0,0,0,0-.814Z"
                      transform="translate(-3.394 -3.393)"
                      fill="#2D3453"
                    />
                  </g>
                </svg>
              </div>
              <ng-template #elseboxfolder>
                <div class="nodelete"></div>
              </ng-template>
              <div (click)="changefolder(archivo.uid, archivo.filedata.nombre)">
                <img src="/assets/img/file-folder.svg" alt="" />
              </div>
              <div
                id="buttonArchivo"
                (click)="changefolder(archivo.uid, archivo.filedata.nombre)"
              >
                <p>{{ archivo.filedata.nombre }}</p>
              </div>
            </div>
            <div (click)="moverVariable(archivo)" title="mover a">
              <img id="moveTo" src="assets/img/moveto.svg" />
            </div>
            <div>
              <!-- Hueco de mb para cuadrar grid  -->
            </div>
            <div>
              <p>{{ archivo.filedata.fecha.toDate() | date : "MMM d, y" }}</p>
            </div>
            <div class="userowner">
              <div>
                <p>{{ archivo.propietario | titlecase }}</p>
              </div>
              <div>
                <img
                  *ngIf="archivo?.avatar; else defaultheadavatar"
                  src="{{ archivo?.avatar }}"
                  alt="{{ archivo?.propietario | titlecase }}"
                />
                <ng-template #defaultheadavatar>
                  <img
                    src="assets/img/defaultavatar.jpg"
                    alt="{{ archivo?.propietario | titlecase }}"
                  />
                </ng-template>
              </div>
            </div>
            <!-- file item -->
          </ng-container>

          <ng-container *ngIf="!archivo.filedata.folder">
            <!-- file item -->
            <div class="filename">
              <div
                class="delete"
                *ngIf="
                  archivo.filedata.propietario == currentuserid ||
                    administrador;
                  else elsebox
                "
                id="buttonArchivo"
                (click)="deleteUp(archivo)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="11.513"
                  height="8.063"
                  viewBox="0 0 11.513 8.063"
                >
                  <g
                    id="Grupo_9481"
                    data-name="Grupo 9481"
                    transform="translate(-5.602 -5.601)"
                  >
                    <path
                      id="Trazado_9279"
                      data-name="Trazado 9279"
                      d="M17.115,9.633Z"
                      fill="#2D3453"
                    />
                    <path
                      id="Trazado_9280"
                      data-name="Trazado 9280"
                      d="M16.889,9.164a.575.575,0,0,0-.814,0l0,0-3.045,3.05L9.982,9.164a.578.578,0,0,0-.817.817l3.05,3.045-3.05,3.045a.578.578,0,0,0,.817.817l3.045-3.05,3.045,3.05a.578.578,0,0,0,.817-.817l-3.05-3.045,3.05-3.045a.575.575,0,0,0,0-.814Z"
                      transform="translate(-3.394 -3.393)"
                      fill="#2D3453"
                    />
                  </g>
                </svg>
              </div>

              <ng-template #elsebox>
                <div class="nodelete"></div>
              </ng-template>
              <div>
                <a href="{{ archivo.filedata.url }}" target="_blank">
                  <img
                    *ngIf="checkimage(archivo.filedata.nombre) === 'image'"
                    src="{{ archivo.filedata.url }}"
                  />
                  <img
                    *ngIf="checkimage(archivo.filedata.nombre) === 'video'"
                    src="/assets/img/file-video.svg"
                  />
                  <img
                    *ngIf="checkimage(archivo.filedata.nombre) === 'doc'"
                    src="/assets/img/file-document.svg"
                  />
                </a>
              </div>
              <div>
                <p>
                  <a
                    download
                    href="{{ archivo.filedata.url }}"
                    target="_blank"
                    >{{ archivo.filedata.nombre }}</a
                  >
                </p>
              </div>
            </div>
            <div (click)="moverVariable(archivo)" title="mover a">
              <img id="moveTo" src="assets/img/moveto.svg" />
            </div>

            <div>
              <p>
                {{ archivo.filedata.size / 1024 / 1024 | number : "1.2-2" }} Mb
              </p>
            </div>
            <div>
              <p>{{ archivo.filedata.fecha.toDate() | date : "MMM d, y" }}</p>
            </div>
            <div class="userowner">
              <div>
                <p>{{ archivo.propietario | titlecase }}</p>
              </div>
              <div>
                <img
                  *ngIf="archivo?.avatar; else defaultheadavatar"
                  src="{{ archivo?.avatar }}"
                  alt="{{ archivo?.propietario | titlecase }}"
                />
                <ng-template #defaultheadavatar>
                  <img
                    src="assets/img/defaultavatar.jpg"
                    alt="{{ archivo?.propietario | titlecase }}"
                  />
                </ng-template>
              </div>
            </div>

            <!-- file item -->
          </ng-container>
        </ng-container>
      </div>

      <!-- //POP-UPS// -->

      <div id="moverA" *ngIf="move == true" class="formgroup">
        <div class="delete2" (click)="closeM()">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="11.513"
            height="8.063"
            viewBox="0 0 11.513 8.063"
          >
            <g
              id="Grupo_9481"
              data-name="Grupo 9481"
              transform="translate(-5.602 -5.601)"
            >
              <path
                id="Trazado_9279"
                data-name="Trazado 9279"
                d="M17.115,9.633Z"
                fill="#2D3453"
              />
              <path
                id="Trazado_9280"
                data-name="Trazado 9280"
                d="M16.889,9.164a.575.575,0,0,0-.814,0l0,0-3.045,3.05L9.982,9.164a.578.578,0,0,0-.817.817l3.05,3.045-3.05,3.045a.578.578,0,0,0,.817.817l3.045-3.05,3.045,3.05a.578.578,0,0,0,.817-.817l-3.05-3.045,3.05-3.045a.575.575,0,0,0,0-.814Z"
                transform="translate(-3.394 -3.393)"
                fill="#2D3453"
              />
            </g>
          </svg>
        </div>
        <p translate>
          dashboard.archivos.mover <b>{{ archivoToMove[0].nombre }}</b> a:
        </p>
        <select
          class="form-control"
          name="miselect"
          [(ngModel)]="carpetaDestino"
        >
          <option
            [value]="bread.uid"
            *ngFor="
              let bread of this.breadcrumb | breadcrumbactual : currentfolder
            "
          >
            {{ bread.name | translate }}
          </option>
          <option
            [value]="carpeta.payload.doc.id"
            *ngFor="let carpeta of carpetas | filterarchivo : moverA"
          >
            {{ carpeta.payload.doc.data().nombre | titlecase | translate }}
          </option>
          <!-- {{carpetas[0].payload.doc.data().nombre}} -->
        </select>
        <button class="button" (click)="moverArchivo(archivoToMove)">
          {{ "dashboard.archivos.mover" | translate }}
        </button>
      </div>
      <div *ngIf="deletePop == true" id="moverA" class="formgroup">
        <div class="delete2" (click)="closeD()">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="11.513"
            height="8.063"
            viewBox="0 0 11.513 8.063"
          >
            <g
              id="Grupo_9481"
              data-name="Grupo 9481"
              transform="translate(-5.602 -5.601)"
            >
              <path
                id="Trazado_9279"
                data-name="Trazado 9279"
                d="M17.115,9.633Z"
                fill="#2D3453"
              />
              <path
                id="Trazado_9280"
                data-name="Trazado 9280"
                d="M16.889,9.164a.575.575,0,0,0-.814,0l0,0-3.045,3.05L9.982,9.164a.578.578,0,0,0-.817.817l3.05,3.045-3.05,3.045a.578.578,0,0,0,.817.817l3.045-3.05,3.045,3.05a.578.578,0,0,0,.817-.817l-3.05-3.045,3.05-3.045a.575.575,0,0,0,0-.814Z"
                transform="translate(-3.394 -3.393)"
                fill="#2D3453"
              />
            </g>
          </svg>
        </div>
        <p translate>
          dashboard.archivos.seguro
          <b>{{ archivoToDelete[0].nombre }}</b
          >?
        </p>
        <button
          class="button"
          (click)="deletefile(archivoToDelete[0].url, deleteuid)"
        >
          {{ "dashboard.archivos.borrar" | translate }}
        </button>
      </div>
      <div *ngIf="deletePopCarpeta == true" id="moverA" class="formgroup">
        <div class="delete2" (click)="closeDC()">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="11.513"
            height="8.063"
            viewBox="0 0 11.513 8.063"
          >
            <g
              id="Grupo_9481"
              data-name="Grupo 9481"
              transform="translate(-5.602 -5.601)"
            >
              <path
                id="Trazado_9279"
                data-name="Trazado 9279"
                d="M17.115,9.633Z"
                fill="#2D3453"
              />
              <path
                id="Trazado_9280"
                data-name="Trazado 9280"
                d="M16.889,9.164a.575.575,0,0,0-.814,0l0,0-3.045,3.05L9.982,9.164a.578.578,0,0,0-.817.817l3.05,3.045-3.05,3.045a.578.578,0,0,0,.817.817l3.045-3.05,3.045,3.05a.578.578,0,0,0,.817-.817l-3.05-3.045,3.05-3.045a.575.575,0,0,0,0-.814Z"
                transform="translate(-3.394 -3.393)"
                fill="#2D3453"
              />
            </g>
          </svg>
        </div>
        <p translate>
          dashboard.archivos.seguroC <b>{{ carpetaToDelete[0].nombre }}</b
          >?
        </p>
        <button class="button" (click)="deletefolder(deleteuid)">Borrar</button>
      </div>
    </div>
  </section>
  <!-- <div *ngFor="let carpeta of carpetas">{{carpeta.payload.doc.data().nombre}} {{carpeta.payload.doc.id}}</div> -->
</div>

<!-- /mensajes -->
