<!-- header -->
<header id="mainheader" style="background-color:{{background}}" >
  <div></div>
  <div class="saludo" >

    <ng-container *ngIf="nombres && apellidos">
      <div *ngIf="genero=='masculino'; else elsebox"><p translate>dashboard.header.bienvenido <span> {{nombres | titlecase }} {{apellidos | titlecase }}</span></p></div>
      <ng-template #elsebox> <p translate>dashboard.header.bienvenida<span>{{nombres | titlecase }} {{apellidos | titlecase }}</span></p></ng-template>
    </ng-container>


  </div>
  <div class="logo">
    <div class="logo" *ngIf="colegio.avatar!='';else elsebox" >
      <img src="{{avatar}}" routerLink="/dashboard" alt="">
    </div>
    <ng-template class="logo" #elsebox  >
      <img src="assets/img/logo.png" routerLink="/dashboard" alt="">
    </ng-template>
  </div>
</header>
<!-- /header -->


<!--BANNER-->
<!-- <div class="banner" *ngIf="banner" (click)="abrirEnlace()">
	<div class="shopping-image">
		<img src="{{logoBanner}}" alt="img">
	</div>
	
	<div class="text">
		<h4>
			{{tituloBanner}}
		</h4>
    <p>{{subtituloBanner}}</p>
	</div>
  <div (click)="quitarBanner()" class="close-image">
		<img src="assets/img/close.svg" alt="close">
	</div>
</div> -->


<!--BANNER 2-->
<!-- <div class="banner2" *ngIf="banner2" (click)="abrirEnlace()">
	<div class="image2">
		<img src="{{logoBanner}}" alt="img">
	</div>
  <div (click)="quitarBanner()" class="close-image2">
		<img src="assets/img/close.svg" alt="close">
	</div>
</div> -->
