import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit, Input } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UserService } from 'src/app/services/user.service';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { DatePipe } from '@angular/common';
import { ExcelService } from 'src/app/services/excel.service';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-estadisticas-box',
  templateUrl: './estadisticas-box.component.html',
  styleUrls: ['./estadisticas-box.component.css'],
})
export class EstadisticasBoxComponent implements OnInit {
  @Input() datos;
  cargadoDatos: boolean;
  userUid: string;
  user: any;
  mainUserUidDb: string;
  datosUsuario: unknown;
  participantes: any = new Array();
  popUp: boolean;
  spin: boolean;
  listaDatos: any[];
  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private _userService: UserService,
    public datepipe: DatePipe,
    private excelService: ExcelService
  ) {}

  ngOnInit(): void {
    const Userdata: any = this._userService.getUserinfodata();
    this.cargadoDatos = false;
    if (Userdata === undefined) {
      this.authenticationService.isAuth().subscribe((auth) => {
        if (auth) {
          this.userUid = auth.uid;
          this._userService.getUserById(this.userUid).subscribe((userdata) => {
            if (userdata[0].payload.doc.data()) {
              this.user = userdata[0];
              this.mainUserUidDb = userdata[0].payload.doc.id;
              this.datosUsuario = userdata[0].payload.doc.data();
            }
          });
        }
      });
    } else {
      this.user = Userdata;
      this.userUid = Userdata.payload.doc.data().uid;
      this.mainUserUidDb = Userdata.payload.doc.id;
      this.datosUsuario = Userdata.payload.doc.data();
    }
  }

  async getParticipantInfo(id) {
    return await this.http
      .get<any>(
        'https://us-central1-the-campus-56b06.cloudfunctions.net/infoParticipant/',
        { params: new HttpParams().set('id', id) }
      )
      .subscribe((element) => {
        var participantInfo = {
          display_name: element.display_name,
          start_time: element.start_time,
          end_time: element.end_time,
          duration: element.duration,
          protocol: element.protocol,
          role: element.role,
          disconnect_reason: element.disconnect_reason,
          historic_call_quality: element.historic_call_quality,
          vendor: element.vendor,
          media_node: element.media_node,
          type: '-',
          start_time_media: '',
          end_time_media: '',
          tx_codec: '-',
          tx_bitrate: '-',
          tx_resolution: '-',
          tx_packets_sent: '-',
          tx_packets_lost: '-',
          rx_codec: '-',
          rx_bitrate: '-',
          rx_resolution: '-',
          rx_packets_received: '-',
          rx_packets_lost: '-',
          rx_packets_sent: '-',
        };
        if (element.media_streams.length > 0) {
          (participantInfo.type = element.media_streams[0].stream_type),
            (participantInfo.start_time_media =
              element.media_streams[0].start_time),
            (participantInfo.end_time_media =
              element.media_streams[0].end_time),
            (participantInfo.tx_codec = element.media_streams[0].tx_codec),
            (participantInfo.tx_bitrate = element.media_streams[0].tx_bitrate),
            (participantInfo.tx_resolution =
              element.media_streams[0].tx_resolution),
            (participantInfo.tx_packets_sent =
              element.media_streams[0].tx_packets_sent),
            (participantInfo.tx_packets_lost =
              element.media_streams[0].tx_packets_lost),
            (participantInfo.rx_codec = element.media_streams[0].rx_codec),
            (participantInfo.rx_bitrate = element.media_streams[0].rx_bitrate),
            (participantInfo.rx_resolution =
              element.media_streams[0].rx_resolution),
            (participantInfo.rx_packets_received =
              element.media_streams[0].rx_packets_received),
            (participantInfo.rx_packets_lost =
              element.media_streams[0].rx_packets_lost);
          participantInfo.rx_packets_sent =
            element.media_streams[0].rx_packets_sent;
        }
        this.participantes.push(participantInfo);
      });
  }

  generatePDF() {
    this.crearDatosPdf();
  }

  async crearDatosPdf() {
    let docDefinition = {
      header: '',
      content: [
        {
          image: await this.getBase64ImageFromURL(
            '/assets/media/img/gofacing.png'
          ),
          width: 200,
          margin: [150, 0, 0, 20],
        },
        {
          text: `Nombre de la sala: ${this.datos.nombre}
          Hora de inicio: ${this.datepipe.transform(
            this.datos.fechaInicio,
            'short'
          )}
          Hora de fin: ${this.datepipe.transform(
            this.datos.fechaFin,
            'short'
          )} `,
          style: 'header',
        },
        {
          text: 'Listado de Participantes',
          style: 'title',
        },
        {
          style: 'tabla',
          table: {
            headerRows: 1,
            body: [
              [
                { text: 'Nombre', style: 'bold' },
                { text: 'Hora de Inicio', style: 'bold' },
                { text: 'Hora de Fin', style: 'bold' },
                { text: 'Duración', style: 'bold' },
                { text: 'Protocolo', style: 'bold' },
                { text: 'Rol', style: 'bold' },
                { text: 'Razón de desconexión', style: 'bold' },
              ],
            ],
          },
        },
      ],
      styles: {
        tabla: {
          widths: ['auto'],
        },
        tabla2: {
          widths: ['auto'],
          margin: [0, 20, 0, 10],
        },
        media: {
          margin: [0, 10, 0, 0],
        },
        header: {
          fontSize: 12,
          bold: true,
        },
        title: {
          fontSize: 14,
          bold: true,
          alignment: 'center',
          margin: [0, 20, 0, 20],
        },
        bold: {
          fontSize: 13,
          bold: true,
          alignment: 'center',
        },
        tableExample: {
          margin: [0, 5, 0, 15],
        },
      },
    };

    setTimeout(() => {
      this.participantes.forEach((element) => {
        var p = new Array();
        p.push(
          element.display_name,
          this.datepipe.transform(element.start_time, 'short'),
          this.datepipe.transform(element.end_time, 'short'),
          element.duration,
          element.protocol,
          element.role,
          element.disconnect_reason
        );
        docDefinition.content[3].table.body.push(p);
      });
      this.participantes.forEach(async (element) => {
        let Calidad: any;

        let url = '';
        if (element.historic_call_quality.length > 0) {
          let countgood = 0;
          let arrayGrafica = new Array();

          for (let i = 0; i < element.historic_call_quality.length; i++) {
            if (element.historic_call_quality[i] < 3) {
              countgood++;
            }
            switch (element.historic_call_quality[i]) {
              case 0:
                arrayGrafica[i] = 0;
                break;
              case 1:
                arrayGrafica[i] = 2;
                break;
              case 2:
                arrayGrafica[i] = 0.5;
                break;
              case 3:
                arrayGrafica[i] = -0.5;
                break;
              case 4:
                arrayGrafica[i] = -1;
                break;
              case 5:
                arrayGrafica[i] = -1.5;
                break;
              default:
                arrayGrafica[i] = 0;
                break;
            }
          }
          let label = new Array();
          element.historic_call_quality.forEach((element) => {
            label.push('" "');
          });
          label[0] =
            '"' +
            this.datepipe.transform(element.start_time, 'short').toString() +
            '"';
          label[element.historic_call_quality.length - 1] =
            '"' +
            this.datepipe.transform(element.end_time, 'short').toString() +
            '"';

          url = `https://quickchart.io/chart?bkg=white&c={type:'line',data:{labels:[${label}],datasets:[{label:'Quality',data:[${arrayGrafica}],fill:false,tension:0.6},]}}&width=1400&height=220`;

          Calidad =
            (
              (countgood / element.historic_call_quality.length) *
              100
            ).toPrecision(3) + '%';
        } else {
          url = `https://quickchart.io/chart?bkg=white&c={type:'line',data:{labels:[],datasets:[{label:'Quality',data:[],fill:false,tension:0.6},]}}&width=1400&height=150`;
          Calidad = ' Dato no disponible.';
        }
        let Estadisticas = [
          {
            text: element.display_name,
            style: 'title',
          },
          {
            text: [
              { text: 'Calidad percibida:', bold: true },
              { text: Calidad },
            ],
            style: 'none',
          },
          {
            image: await this.getBase64ImageFromURL(url),
            width: 500,
            margin: [0, 10, 0, 10],
          },
          {
            text: [
              { text: 'Nombre mostrado:', bold: true },
              { text: element.display_name },
            ],
            style: 'none',
          },
          {
            text: [
              { text: 'Plataforma Utilizada:', bold: true },
              { text: element.vendor },
            ],
            style: 'none',
          },
          {
            text: [
              { text: 'Dirección IP externa:', bold: true },
              { text: element.media_node },
            ],
            style: 'none',
          },
          {
            text: [{ text: 'Type:', bold: true }, { text: element.type }],
            style: 'none',
          },
          {
            text: [
              { text: 'Start Time:', bold: true },
              {
                text: this.datepipe.transform(
                  element.start_time_media,
                  'short'
                ),
              },
            ],
            style: 'none',
          },
          {
            text: [
              { text: 'End Time:', bold: true },
              {
                text: this.datepipe.transform(element.end_time_media, 'short'),
              },
            ],
            style: 'none',
          },
          {
            text: [{ text: 'Media Stream:', bold: true }],
            style: 'media',
          },
          {
            style: 'tabla2',
            table: {
              headerRows: 1,
              body: [
                [
                  { text: 'Tx bitrate (Kbps)', style: 'bold' },
                  { text: 'Tx resolution', style: 'bold' },
                  { text: 'Tx packets sent', style: 'bold' },
                  { text: 'Tx packets lost', style: 'bold' },
                  { text: 'Tx codec', style: 'bold' },
                ],
                [
                  { text: element.tx_bitrate, style: 'none' },
                  { text: element.tx_resolution, style: 'none' },
                  { text: element.tx_packets_sent, style: 'none' },
                  { text: element.tx_packets_lost, style: 'none' },
                  { text: element.tx_codec, style: 'none' },
                ],
              ],
            },
          },
          {
            style: 'tabla2',
            table: {
              headerRows: 1,
              body: [
                [
                  { text: 'Rx bitrate (Kbps)', style: 'bold' },
                  { text: 'Rx resolution', style: 'bold' },
                  { text: 'Rx packets sent', style: 'bold' },
                  { text: 'Rx packets lost', style: 'bold' },
                  { text: 'Rx codec', style: 'bold' },
                ],
                [
                  { text: element.rx_bitrate, style: 'none' },
                  { text: element.rx_resolution, style: 'none' },
                  { text: element.rx_packets_sent, style: 'none' },
                  { text: element.rx_packets_lost, style: 'none' },
                  { text: element.rx_codec, style: 'none' },
                ],
              ],
            },
          },
        ];

        Estadisticas.forEach((e) => {
          docDefinition.content.push(e);
        });
      });
      setTimeout(() => {
        console.log(docDefinition);
        let nombre =
          'meet_' + this.datepipe.transform(this.datos.fechaInicio, 'short');
        pdfMake.createPdf(docDefinition).download(nombre);
        this.popUp = false;
        this.spin = false;
      }, 1500);
    }, 2000);
  }

  getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute('crossOrigin', 'anonymous');
      img.onload = () => {
        var canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL('image/png');
        resolve(dataURL);
      };
      img.onerror = (error) => {
        reject(error);
      };
      img.src = url;
    });
  }
  pop() {
    this.popUp ? (this.popUp = false) : (this.popUp = true);
  }
  // getdata() {
  //   this.spin = true;
  //   this.participantes = new Array();
  //   if (this.datos.participantesData.length == 0) {
  //     this.popUp = false;
  //     this.spin = false;
  //   }
  //   for (let i = 0; i < this.datos.participantesData.length; i++) {
  //     this.getParticipantInfo(this.datos.participantesData[i]).then(() => {
  //       if (this.participantes.length < this.datos.participantesData.length) {
  //         this.popUp = false;
  //         this.spin = true;
  //       } else {
  //         this.generatePDF();
  //       }
  //     });
  //   }
  // }
  // downloadExcel() {
  //   this.participantes = new Array();
  //   if (this.datos.participantesData.length == 0) {
  //     this.popUp = false;
  //     this.spin = false;
  //   }

  //   this.recogerInfo().then(() => {
  //     setTimeout(() => {

  //       console.log("hola", this.participantes)

  //       this.rellenarDatos().then(() => {
  //         console.log("listad",this.listaDatos)
  //         console.log('6--------');
  //         this.excelService.exportAsExcelFile(
  //           this.listaDatos,
  //           'meet' + this.datos.fechaInicio
  //         );
  //         console.log('7--------');
  //         this.popUp = false;
  //         this.spin = false;
  //       });

  //     }, 3000);

  //   });
  // }

  downloadExcel() {
    this.participantes = new Array();
    if (this.datos.participantesData.length == 0) {
      this.popUp = false;
      this.spin = false;
    }
     for (let i = 0; i < this.datos.participantesData.length; i++) {
       this.getParticipantInfo(this.datos.participantesData[i]).then(() => {
        console.log('info participante', this.participantes);
        console.log('longitud', this.participantes.length);
        if (this.participantes.length < this.datos.participantesData.length) {
          this.popUp = false;
          this.spin = true;
        } else {
          var listaDatos = new Array();
          this.participantes.forEach((element) => {
            var datos = {
              nombre: element.display_name,
              inicio: this.datepipe.transform(element.start_time, 'short'),
              fin: this.datepipe.transform(element.end_time, 'short'),
              duracion: element.duration,
              protocolo: element.protocol,
              rol: element.role,
              desconexion: element.disconnect_reason,
            };
            listaDatos.push(datos);
          });
          this.excelService.exportAsExcelFile(
            
            listaDatos,
            'meet' + this.datos.fechaInicio
            );
            this.popUp = false;
            this.spin = false;
          }
          
      });
    }
  }

  async recogerInfo() {
    for (var element of this.datos.participantesData) {
      await this.getParticipantInfo(element);
    }
  }
  async rellenarDatos() {
    this.listaDatos = new Array();

    for await (var element of this.participantes) {

      var datos = {
        nombre: element.display_name,
        inicio: this.datepipe.transform(element.start_time, 'short'),
        fin: this.datepipe.transform(element.end_time, 'short'),
        duracion: element.duration,
        protocolo: element.protocol,
        rol: element.role,
        desconexion: element.disconnect_reason,
      };
      this.listaDatos.push(datos);
    }

  }
}
