import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LicenciaService } from '../services/licencia.service';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.css'],
})
export class RedirectComponent implements OnInit {
  sala: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private licenciaService: LicenciaService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.sala = params['sala'];
    });
    location.href=
      'https://in2.thecampus.education/webapp3/#/?conference=' +this.sala+'&role=guest&callType=video'

    // this.crearLicencias();
  }

  // crearLicencias() {
  //   let arrayNumeros = [];
  //   for (let index = 0; index < 10; index++) {
  //     let random = Math.floor(Math.random() * 9000) + 1000;
  //     let numeroString = "MYF"+random.toString();
  //     if (!arrayNumeros.includes(random)) {
  //       arrayNumeros.push(random);
  //       let licencia = {
  //         codigo: numeroString,
  //         colegio: '0MTFJvw88G9UdRdrq69q',
  //         usado: false,
  //       };
  //       console.log(numeroString);
  //       // this.licenciaService.createdLicencia(licencia)
  //     }
  //   }
  //   console.log(arrayNumeros);

  // }
}



