import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { UserService } from '../services/user.service';
import { ChatService } from '../services/chat.service';
import { AngularFireStorage } from '@angular/fire/storage';

@Component({
  selector: 'app-preguntasauditorio',
  templateUrl: './preguntasauditorio.component.html',
  styleUrls: ['./preguntasauditorio.component.css']
})
export class PreguntasauditorioComponent implements OnInit {
  @ViewChild('scriptContainer') scriptContainer: ElementRef;
  mensajes: any = [];
  chatorimessa: any;
  nombre = '';
  apellidos = '';
  nombreForm = '';
  apellidosForm = '';

  chatmessage = '';
  constructor(

    private usuariosService: UserService,
    private chatService: ChatService,

    private storage: AngularFireStorage,
    private renderer: Renderer2,
  ) {}

  ngAfterViewInit() {
    try {
      const script = this.renderer.createElement('script');
      this.renderer.setAttribute(
        script,
        'src',
        'https://www.recordingandstreaming.com/live/js/9IDfJXZlosz57Zsl.js'
      );
      this.renderer.appendChild(this.scriptContainer.nativeElement, script);
    } catch (error) {
      console.log('ERRRROR', error);
    }
  }

  ngOnInit(): void {
    this.getmessages()
  }
  sendmessagePrueba() {
    if (this.chatmessage != '') {
      this.mensajes.push({
        mensaje: this.chatmessage,
        nombre: 'Pepe',
      });
      this.chatmessage = '';
    }
  }

  getmessages() {
    const chatclassid = 'AUDITORIO1-MYF';
    this.chatService.getClaseMessages(chatclassid).subscribe((message) => {
      this.chatorimessa = message;
      // this.chatorimessa.forEach((messageData: any) => {
      //   this.userchat = messageData.payload.doc.data().sender;
      //   if (!this.chatusers[this.userchat.uid]) {
      //     this.chatusers[this.userchat.uid] = userchat[0].payload.doc.data();
      //   }
      // });
    });
  }

  credenciales(){
    if(this.nombreForm!=""&&this.apellidosForm!=""){
      this.nombre=this.nombreForm;
      this.apellidos=this.apellidosForm
    }
  }

  sendmessage() {
    if (this.nombre != '' && this.apellidos != '') {
      const message = {
        sender: this.nombre + ' ' + this.apellidos,
        message: this.chatmessage,
        fecha: new Date(),
      };
      const chatclassid = 'AUDITORIO1-MYF';
      this.chatService
        .sendmessageclase(chatclassid, message)
        .then((datames) => {
          this.chatmessage = '';
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  scrolltobottom() {
    const objDiv = document.getElementById('themessagesbox');
    objDiv.scrollTop = objDiv.scrollHeight + 1000;
  }

}
