<div>
    <section class="basicbox clase grupo">
      <header>
        <div class="icon">
          <div class="wrapicon">
            <img src="/assets/img/bell.svg" alt="" />
          </div>
        </div>
        <h3 translate>dashboard.notificaciones.titulo</h3>
        <div>

        </div>
      </header>
  
  

  
      <app-notificacion
      class="noti"
        *ngFor="
          let notificacion of notificacionesMostrar;
        "
        [notificacion]="notificacion.notificacion" [numeromensajes]="notificacion.numero"
      >
      </app-notificacion>
      

  
      <div></div>
    </section>
  </div>
  