import { Component, Input, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-clasebox',
  templateUrl: './clasebox.component.html',
  styleUrls: ['./clasebox.component.css'],
})
export class ClaseboxComponent implements OnInit {
  @Input() datos;
  @Input() userUid;
  profesores: any = [];
  constructor(private userServices: UserService) {}

  ngOnInit(): void {
    this.cargarProfesores();
  }

  cargarProfesores() {
    this.profesores=[]
    this.datos.administradores.forEach((administrador) => {
      // console.log("administrador", administrador)
      this.userServices
        .getUserById(administrador)
        .subscribe((profesor) => {
          if(profesor.length>0){
            var profe = profesor[0].payload.doc.data();
            this.profesores.push(profe);
          }
        });
    });
  }

  // get chaturl(profesor) {
  //   return [profesor, this.userUid].sort().join('|');
  // }

  chatUsuario(profesor) {
    var usuarios = [profesor, this.userUid]
    var enlace = usuarios.sort().join("|")
    // console.log(enlace)
    // console.log(usuarios)

    return "/padres/mensajes/chat/" + enlace

  }
}
