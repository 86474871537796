import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { UserService } from '../services/user.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { auth } from 'firebase';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  email: string = null;
  password: string = null;
  userdata: any;
  remember = false;
  usuario: any;
  lang: string;

  es: boolean = false;
  en: boolean = false;
  ar: boolean = false;
  idioma: boolean = false;
  click: boolean = false;
  loginNormal = false;
  hide=true;
  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private router: Router,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    if (document.location.host == 'colegia.education') {
      this.loginNormal = false;
      window.location.href = 'https://colegia.education/thecampus';
    }
    if (localStorage.getItem('user')) {
      this.usuario = localStorage.getItem('user');
    }

    if (localStorage.getItem('passw')) {
      this.password = atob(localStorage.getItem('passw'));
    }
    if (localStorage.getItem('idioma')) {
      this.lang = localStorage.getItem('idioma');
      this.translate.setDefaultLang(this.lang);
      this.translate.use(this.lang);
      switch (this.lang) {
        case 'es':
          this.es = true;
          break;
        case 'en':
          this.en = true;
          break;
        case 'ar':
          this.ar = true;
          break;
        default:
          break;
      }
    } else {
      this.es = true;
      this.translate.setDefaultLang('es');
      this.translate.use('es');
      localStorage.setItem('idioma', 'es');
    }
    setTimeout(() => {
      this.loginNormal = true;
    }, 500);
  }

  login() {
    this.userService
      .getUserByUsuario(this.usuario.toLowerCase())
      .subscribe((usuar) => {
        if (usuar.size > 0) {
          usuar.forEach((us) => {
            this.email = us.data().email.toLowerCase();;
            this.authenticationService
              .loginWithEmail(this.email, this.password)
              .then((data) => {
                let opt = data.operationType;
                this.remembercredentials(this.remember);

                this.userService
                  .getUserById(data.user.uid)
                  .subscribe((ddata) => {
                    if (ddata[0]) {
                      this.userdata = ddata[0].payload.doc.data();
                      this.userService.setUserinfo(ddata[0]);

                      if (
                        this.userdata.rol === 'estudiante' ||
                        this.userdata.rol === 'profesor' ||
                        this.userdata.rol === 'director' ||
                        this.userdata.rol === 'supervisor'
                      ) {
                        this.router.navigate(['dashboard']);
                      } else if (this.userdata.rol === 'admin') {
                        this.router.navigate(['admin']);
                      }
                      else if (this.userdata.rol === 'padre') {
                        this.router.navigate(['padres']);
                      }
                    }
                  });
              })
              .catch((error) => {
                this.translate
                  .get('login.datosequivocados')
                  .subscribe((data) => {
                    alert(data);
                  });
              });
          });
        } else {
          this.translate.get('login.datosequivocados').subscribe((data) => {
            alert(data);
          });
        }
      });
  }

  // loginInSAMLcharterschoolit() {
  //   const provider = new auth.SAMLAuthProvider('saml.charterschoolit');
  //   this.authenticationService
  //     .loginWithSAML(provider)
  //     .then((userCredential) => {
  //       console.log("Login correcto");
  //       var samlProfile = userCredential.additionalUserInfo.profile;
  //       //userCredential.user.getIdToken()
  //     })
  //     .catch((error) => {
  //       console.log("Hubo este error: " + error);
  //     });

  //   // volver a autenticar a un usuario con reauthenticateWithRedirect() y reauthenticateWithPopup() , que se puede usar para recuperar credenciales nuevas para operaciones confidenciales que requieren un inicio de sesión reciente.
  // }

  remembercredentials(remember) {
    if (remember) {
      let con = btoa(this.password);
      localStorage.setItem('user', this.usuario);
      localStorage.setItem('passw', con);
    }
  }

  rememberme() {
    if (this.remember == false) {
      this.remember = true;
    } else {
      this.remember = false;
    }
  }

  changeLang(lang: string) {
    this.translate.use(lang);
    localStorage.setItem('idioma', lang);
  }

  espanol() {
    this.translate.use('es');
    this.es = true;
    this.en = false;
    this.ar = false;
    localStorage.setItem('idioma', 'es');
    this.activeLanguage();
  }

  ingles() {
    this.translate.use('en');
    this.en = true;
    this.es = false;
    this.ar = false;
    localStorage.setItem('idioma', 'en');
    this.activeLanguage();
  }

  arabe() {
    this.translate.use('ar');
    this.en = false;
    this.es = false;
    this.ar = true;
    localStorage.setItem('idioma', 'ar');
    this.activeLanguage();
  }

  changeColor() {
    this.click = !this.click;
  }

  activeLanguage() {
    this.idioma ? (this.idioma = false) : (this.idioma = true);
  }
}
