<div class="cuerpo" id="video" *ngIf="usuariovalido">
  <div #scriptContainer class="grabacion">
    <iframe
       [src]="src"
      name="iframe1"
      id="iframe1"
      allow="camera;microphone"
      width="100%"
      height="80%"
    ></iframe>
  </div>
  <div class="chat">
    <div class="hilo">
      <div class="messages" id="themessagesbox" [scrollTop]="scrolltobottom()">
        <ng-container *ngFor="let messages of chatorimessa">
          <div class="message">
            <div class="avatar">
              <ng-template #defaultavatar
                ><img src="assets/img/defaultavatar.jpg" alt=""
              /></ng-template>
            </div>
            <div class="messagedata">
              <div class="name"></div>
              <div class="date">
                {{
                  messages?.payload.doc.data().fecha.toDate()
                    | date : "dd/MM/y - HH:mm"
                }}h
                {{ messages?.payload.doc.data().sender | titlecase }}
                <!-- {{messages.nombre | titlecase}} -->
              </div>
              <div class="themessage">
                <p>
                  {{ messages?.payload.doc.data().message }}
                  <!-- {{ messages.mensaje }} -->
                </p>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<div class="cuerpo" *ngIf="!usuariovalido">

  <article>
    <label class="error" > {{mensaje}} </label>
    <div class="loginform">
      <div class="logo">
        <img src="assets/img/logocolor.png" alt="" />
      </div>
      <label for="" translate> login.usuario </label>
      <input
        [(ngModel)]="email"
        class="user"
        type="text"
        placeholder="{{ 'login.usuario' | translate }}"
      />
      <label for="" translate> login.contraseña </label>
      <input
        [(ngModel)]="password"
        class="pass"
        type="password"
        placeholder="{{ 'login.contraseña' | translate }}"
        (keyup.enter)="login()"
      />
      <div class="loginbuttons">
        <div>
          <input
            class="acceso"
            type="submit"
            value="{{ 'login.acceso' | translate }}"
            (click)="login()"
          />
        </div>
      </div>
    </div>
    <!-- <div>
      <button (click)="loginInSAMLcharterschoolit()" > Login SAML </button>
    </div> -->
  </article>
</div>
