<div>
  <section class="basicbox clase grupo">
    <header>
      <div class="wrapicon">
        <img src="/assets/img/padreslogoazul.png" alt="" />
      </div>
      <h3 translate>dashboard.padres.titulo</h3>

      <div class="searchcol"></div>

      <div>
        <!-- <ng-container *ngIf="this.userdata?.rol == 'estudiante'">
            <a routerLink="/dashboard/creargrupo" class="button">
              <div class="plusicon">+</div>
             {{'dashboard.grupos.crear'|translate}}
            </a>
          </ng-container> -->
        <ng-container *ngIf="!inputCodigo">
          <button (click)="abrirInputCodigo()" class="button">
            {{ "dashboard.padres.mandar" | translate }}
          </button>
        </ng-container>
        <ng-container *ngIf="inputCodigo">
          <input
            id="idnombg"
            class="inputCodigo"
            type="text"
            placeholder="{{ 'dashboard.padres.email' | translate }}"
            [(ngModel)]="email"
          />
          <div class="botonesmandar">
            <button class="mandar" (click)="generarCodigo()">dashboard.padres.mandar</button>
            <button class="cancelar" (click)="abrirInputCodigo()">dashboard.padres.cancelar</button>
          </div>
        </ng-container>
      </div>
    </header>

    <!-- <div>
      <p class="groupcounter">
        <ng-container *ngIf="clasecontador === 1">
          {{
            "dashboard.grupos.clase"
              | translate : { clasecontador: this.clasecontador }
          }}
        </ng-container>
        <ng-container *ngIf="clasecontador > 1">
          {{
            "dashboard.grupos.clases"
              | translate : { clasecontador: this.clasecontador }
          }}
        </ng-container>
      </p>
    </div> -->

    <div class="claseheader">
      <ng-container *ngFor="let padre of padres">
        <div class="subbasicbox chat headinguser">
          <!-- <div class="contenedor" *ngIf="borrar==false"> -->
          <div class="boximage">
            <img
              *ngIf="padre.datos.avatar; else defaultclassavatar"
              src="{{ padre.datos.avatar }}"
              alt="{{ padre.datos.nombres | titlecase }}"
            />
            <ng-template #defaultclassavatar
              ><img
                src="assets/img/clase_default.jpg"
                alt="{{ padre.datos.nombres | titlecase }}"
            /></ng-template>
          </div>
          <div class="boxtitles" >
            <h4>{{ padre.datos.nombres + ' ' + padre.datos.apellidos | titlecase }}</h4>
            <!-- <p class="admins colegio">{{ clase.colegio_nombre | titlecase }}</p> -->
          </div>
          
          <div class="boxtitles2" >
            <button class="buttonborrar"> BORRAR </button>
          </div>
           

          <!-- </div> -->

          <!-- <div class="popBorrar" *ngIf="uidBorrar == padre.datos.uid">
            <img
              id="close"
              src="assets/img/close.svg"
              alt="close"
              (click)="borrarPop(padre.datos.uid)"
            />
            <p translate>dashboard.grupos.seguro</p>
            <button
              class="button"
              [disabled]="clicked"
              (click)="DeleteClass(padre.datos.uid); clicked = true"
            >
              {{ "dashboard.grupos.borrar" | translate }}
            </button>
          </div> -->
        </div>
      </ng-container>
    </div>

    <div></div>
  </section>
</div>
