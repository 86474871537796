<div class="box">

    <div class="izquierda">
        <h3>{{datos.nombre}}</h3>

        <img *ngIf="datos.imagen!=''" src="{{datos.imagen}}" alt="">
        <img *ngIf="datos.imagen==''" src="/assets/img/clase_default.jpg" alt="">
    </div>
    
    <div class="derecha">
        <h3>{{"padres.profesores"|translate}}:</h3>
        <div class="profesores"  >
            <div class="profesor" *ngFor="let profesor of profesores">

                <p class="nombreprofesor" >
                    {{profesor.nombres + " " + profesor.apellidos |titlecase}}
                </p>
                <img class="chat" src="/assets/img/chatbox-b.svg" [routerLink]="chatUsuario(profesor.uid)">
            </div>
        </div>
    </div>
</div>
