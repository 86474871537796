import { AuthenticationService } from '../../services/authentication.service';
import { UserService } from '../../services/user.service';
import { Component, OnInit,Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { TitleCasePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  
  user: any;
  nombres: string ="";
  apellidos: string;
  email: string;
  codigo: string;
  telefono: string;
  fechanac: Date;
  genero: string;
  pais: string;
  uid: string;
  avatar: string;
  uid_in_userdatabase: any;
  currentuid: string;
  urlid: string;

  noMyUser:boolean=false;
  usuario:any;

  es: boolean = false;
  en: boolean = false;
  ar: boolean = false;
  idioma: boolean = false;
  click: boolean = false;
  colegioUid: any;

  constructor(private userService: UserService,
              private activatedRoute: ActivatedRoute,
              private authenticationService: AuthenticationService,
              private breadService: BreadcrumbService,
              private translate: TranslateService
              )
  {


}
ngOnInit(){

  if (localStorage.getItem('idioma')) {
    var lang = localStorage.getItem('idioma');
  
    switch (lang) {
      case 'es':
        this.es = true;
        break;
      case 'en':
        this.en = true;
        break;
        case 'ar':
          this.ar = true;
          break;
      default:
        break;
    }
  }else{
    this.es = true;
  }

  this.urlid = this.activatedRoute.snapshot.params['uid'];

  this.authenticationService.getStatus().subscribe((status)=>{
   this.currentuid=status.uid;
   if(this.urlid != status.uid){
     this.noMyUser = true;
     this.userService.getUserById(status.uid).subscribe((data)=>{
       if(data[0]){
         this.user= data[0].payload.doc.data();
       }
     })
     this.userService.getUserById(this.urlid).subscribe((data) => {
      if(data[0]) {
        var use = data[0].payload.doc.data()
        this.avatar = use['avatar']
        this.nombres = use['nombres']
        this.apellidos = use['apellidos']
        this.genero = use['genero']
        this.pais = use['pais']
        this.fechanac = new Date(use['fechanac'].seconds*1000)
        this.telefono = use['telefono']
        this.email = use['email']
        this.usuario = use['usuario']
        this.colegioUid = use['colegios'][0]
    }
   })
  }
   else{
     this.noMyUser=false;
          this.userService.getUserById(this.urlid).subscribe((data) => {
          if(data[0]) {
            this.user = data[0].payload.doc.data();
            this.uid_in_userdatabase = data[0].payload.doc.id;
            this.uid = this.urlid;
            this.nombres = this.user.nombres;
            this.apellidos = this.user.apellidos;
            this.email = this.user.email;
            this.codigo = this.user.codigo;
            this.telefono = this.user.telefono;
            this.fechanac = new Date(this.user.fechanac.seconds*1000)
            this.genero = this.user.genero;
            this.pais = this.user.pais;
            this.avatar = this.user.avatar;
            this.usuario = this.user.usuario;
            this.colegioUid = this.user.colegios[0]
          }
        }, (error) => {
          console.log(error);
        });
    }
   
  }, (error) => {
    console.log(error);
  });

}
UpdateBreadcrumb(name, url) {
  this.breadService.addtobread(name, url);
}
get chaturl(){
  return  [this.uid, this.urlid].sort().join('|');
}


changeLang(lang: string) {
  this.translate.use(lang);
  localStorage.setItem('idioma', lang);
}

espanol() {
  this.translate.use('es');
  this.es = true;
  this.en = false;
  this.ar = false;
  localStorage.setItem('idioma', 'es');
  this.activeLanguage()
}

ingles() {
  this.translate.use('en');
  this.en = true;
  this.es = false;
  this.ar = false;
  localStorage.setItem('idioma', 'en');
  this.activeLanguage()
}

arabe() {
  this.translate.use('ar');
  this.en = false;
  this.es = false;
  this.ar = true;
  localStorage.setItem('idioma', 'ar');
  this.activeLanguage()
}

changeColor() {
  this.click = !this.click;
}

activeLanguage() {
  this.idioma ? (this.idioma = false) : (this.idioma = true);
  console.log("hola", this.idioma)
}

}

