

<div class="containerForm" >
    <iframe
    *ngIf="idioma=='es'"
      src="https://firebasestorage.googleapis.com/v0/b/the-campus-prod.appspot.com/o/Manualuser%2FManual_profesor_Colegia%20(ESP).pdf?alt=media&token=85984d5b-586b-4c6a-8f09-15029b09a786"
      name="iframe1"
      id="iframe1"
      allow="camera;microphone"
      width="100%"
      height="100%"
    ></iframe>
    <!-- <iframe
    *ngIf="idioma!='es'"
      src="https://firebasestorage.googleapis.com/v0/b/the-campus-56b06.appspot.com/o/Manualuser%2FStudent%C2%B4s%20Manual(ENG).pptx?alt=media&token=e48e417d-31c5-4415-8575-a568b40f6a5f"
      name="iframe1"
      id="iframe1"
      allow="camera;microphone"
      width="100%"
      height="100%"
    ></iframe> -->
  </div>
  