<!-- <ul>
  <li *ngFor="let colegio of colegios">
    {{ colegio.data().nombre | titlecase }}
  </li>
</ul> -->

<section class="basicbox fondo evento">
  <header class="headerperfil padtop">
    <div class="icon2">
      <div class="wrapicon">
        <img src="/assets/img/hijos-dark.png" />
      </div>
    </div>
    <h3 >{{"padres.mishijos"|translate}}</h3>
  </header>

  <!-- <div>
    <p class="groupcounter">
      <ng-container *ngIf="mishijos.length === 1">
        {{ mishijos.length }} {{ "padres.numerohijo" | translate }}
      </ng-container>
      <ng-container *ngIf="mishijos.length > 1">
        {{ mishijos.length }} {{ "padres.numerohijos" | translate }}
      </ng-container>
    </p>
  </div> -->

  <app-hijobox *ngFor="let hijo of mishijos" [datos]="hijo"></app-hijobox>
</section>
