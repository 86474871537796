<div class="main">
  <div class="pin" *ngIf="!pinValido">
    <label> Insertar pin </label>
    <input
      type="text"
      name="pin"
      id=""
      [(ngModel)]="pinMYF"
    />

    <button (click)="comprobarPin()">Acceder</button>
    <p class="mensaje">{{ mensajepin }}</p>
  </div>

  <div class="registros" *ngIf="pinValido">
    <div class="registronuevo">
      <h2>Nuevo usuario</h2>
      <form [formGroup]="usuarioForm" novalidate>
        <div class="lineadatos">
          <div class="inputDato">
            <label class="titulo">
              Nombre
              <span class="obligatorio">*</span>
            </label>
            <input type="text" formControlName="nombre" />
          </div>
          <div class="mensajeError">
            <label
              class="error"
              *ngIf="errorControlUsuario.nombre.errors?.required"
            >
              Nombre requerido</label
            >
            <label
              class="error"
              *ngIf="errorControlUsuario.nombre.errors?.minlength"
            >
              Minimo de 2 letras</label
            >
          </div>
        </div>

        <div class="lineadatos">
          <div class="inputDato">
            <label class="titulo">
              Apellidos
              <span class="obligatorio">*</span>
            </label>
            <input type="text" formControlName="apellidos" />
          </div>
          <div class="mensajeError">
            <label
              class="error"
              *ngIf="errorControlUsuario.apellidos.errors?.required"
            >
              Apellido requerido</label
            >
            <label
              class="error"
              *ngIf="errorControlUsuario.apellidos.errors?.minlength"
            >
              Minimo 2 letras</label
            >
          </div>
        </div>

        <div class="lineadatos">
          <div class="inputDato">
            <label class="titulo">
              Email (Y tambien será usuario)
              <span class="obligatorio">*</span>
            </label>
            <input type="text" formControlName="email" />
          </div>
          <div class="mensajeError">
            <label
              class="error"
              *ngIf="errorControlUsuario.email.errors?.required"
              >Email requerido</label
            >
            <label
              class="error"
              *ngIf="errorControlUsuario.email.errors?.pattern"
            >
              Patrón de email no válido</label
            >
          </div>
        </div>

        <div class="lineadatos">
          <div class="inputDato">
            <label class="titulo">
              Contraseña
              <span class="obligatorio">*</span>
            </label>
            <input [type]="hidePass" formControlName="password" />
            <img *ngIf="hidePass == 'password'" class="ojo" src="assets/img/eyeclose.png" (click)="cambiarVisibilidad()"  alt="ojo">
            <img *ngIf="hidePass == 'text'" class="ojo" src="assets/img/eyeopen.png" (click)="cambiarVisibilidad()" alt="ojo">
          </div>
          <div class="mensajeError">
            <label
              class="error"
              *ngIf="errorControlUsuario.password.errors?.required"
              >Contraseña requerida</label
            >
            <label
              class="error"
              *ngIf="errorControlUsuario.password.errors?.minlength"
            >
              Minimo tamaño de 6 caracteres</label
            >
          </div>
        </div>
        <div class="crear">
          <button
            class="buttonSiguiente"
            [disabled]="!usuarioForm.valid"
            type="submit"
            (click)="onSubmitUser()"
          >
            Crear nuevo usuario
          </button>
        </div>
      </form>
      <div class="mensaje">{{ mensajeExiste }}</div>
    </div>
    <hr />
    <div class="busquedausuario">
      <h2>Buscar usuario</h2>
      <div *ngIf="!listo" > <p>Cargando usuarios...</p></div>
      <div class="search">
        <div class="conjunto">
          <img class="buscar" src="assets/img/search.svg" alt="" (click)="search()" />
          <input
            type="text"
            class="inputsearch"
            placeholder="{{ 'dashboard.mensajes.buscarU' | translate }} "
            (keyup)="quitarResult()"
            (keyup.enter)="search()"
            [(ngModel)]="searchValue"
          />
        </div>
        <div
          class="resulstsearch"
          *ngIf="results != '' && searchValue && !abrirdatos"
        >
          <ng-container
            class="contenedor"
            *ngFor="let result of results | searchall : searchValue"
          >
            <div>
              <p
                class="emailsearch"
                (click)="
                  openUser(result.payload.doc.data(), result.payload.doc.id)
                "
              >
                {{ result.payload.doc.data().email }}
              </p>
            </div>
          </ng-container>
        </div>
      </div>
      <div *ngIf="abrirdatos" class="datosusuario">
        <p>
          <b> Nombre: </b>{{ usuarioelegido.nombres | titlecase }}
          {{ usuarioelegido.apellidos | titlecase }}
        </p>
        <p><b> Email: </b>{{ usuarioelegido.email }}</p>
        <p><b> Usuario: </b>{{ usuarioelegido.usuario }}</p>
        <button
          *ngIf="!estaEnClase"
          (click)="addUser()"
          class="buttonSiguiente"
        >
          Añadir a la clase
        </button>
        <div *ngIf="estaEnClase" class="yaEsta">Ya está en el evento</div>
      </div>
    </div>
  </div>
</div>
