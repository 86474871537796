<!-- menu -->
<div class="mainmenu">
  <div class="usermenu">
    <ul class="listaprimera">
      <li class="avatar">
        <div class="ajustesC">
          <img src="/assets/img/cog2.svg" alt="ajustes" />
        </div>
        <img
          routerLink="/padres/profile/{{ uid }}/ajustes"
          (click)="
            UpdateBreadcrumb('dashboard.menu.perfil', '/padres/profile/' + uid)
          "
          *ngIf="avatar; else defaultheadavatar"
          src="{{ avatar }}"
        />

        <ng-template ng-template #defaultheadavatar
          ><img
            routerLink="/padres/profile/{{ uid }}/ajustes"
            (click)="
              UpdateBreadcrumb(
                'dashboard.menu.perfil',
                '/padres/profile/' + uid
              )
            "
            src="assets/img/defaultavatar.jpg"
          />
        </ng-template>
      </li>

      <li>
        <a
          routerLink="/padres/notificaciones/"
          (click)="
            UpdateBreadcrumb(
              'dashboard.menu.notificaciones',
              '/padres/notificaciones/'
            )
          "
          title="{{ 'dashboard.menu.notificaciones' | translate }}"
        >
          <img src="/assets/img/bell.svg" alt="" />
          <ng-container *ngIf="lasnotificaciones > 0">
            <div class="notificationnumber">
              {{ lasnotificaciones }}
            </div>
          </ng-container>
        </a>
      </li>
      <li>
        <a routerLink="/padres" title="{{ 'dashboard.menu.home' | translate }}">
          <img src="/assets/img/home2.svg" alt="" />
        </a>
      </li>
    </ul>
  </div>

  <div class="utilities">
    <ul class="listasegunda">
      
      <li>
        <a
          routerLink="/padres/mishijos"
          title="{{ 'padres.mishijos' | translate }}"
          (click)="
            UpdateBreadcrumb('padres.mishijos', '/padres/mishijos')
          "
        >
          <img src="/assets/img/hijos.png" alt="" />
        </a>
      </li>
      <li>
        <a
          routerLink="/padres/mensajes"
          title="{{ 'dashboard.menu.mensajes' | translate }}"
          (click)="
            UpdateBreadcrumb('dashboard.menu.mensajes', '/padres/mensajes')
          "
        >
          <img src="/assets/img/chatbox.png" alt="" />
          <ng-container *ngIf="mensajesContador > 0">
            <div class="notificationmensaje">
              {{ mensajesContador }}
            </div>
          </ng-container>
        </a>
      </li>

      <li>
        <a
          (click)="logout(uidb)"
          title="{{ 'dashboard.menu.logout' | translate }}"
        >
          <img src="/assets/img/logout.svg" alt="" />
        </a>
      </li>
    </ul>
  </div>

  <!-- <div class="manual">
      <ul class="listainfo">
        <li>
          <a
            routerLink="/padres/ayuda"
            title="{{ 'dashboard.menu.ayuda' | translate }}"
            (click)="
              UpdateBreadcrumb(
                'dashboard.menu.ayuda',
                '/padres/ayuda'
              )
            "
          >
            <img src="/assets/img/informacion.png" alt="" />
          </a>
        </li>
      </ul>
    </div> -->
  <!-- /menu -->
</div>
