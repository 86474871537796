<div class="loginwrap" *ngIf="loginNormal">
  <div class="idiomasC">
    <div class="idiomasCF">
      <h2 translate (click)="activeLanguage()" (click)="changeColor()">
        login.cambiaridioma
      </h2>
      <div id="circle">
        <img
          id="imgB"
          (click)="activeLanguage()"
          src="/assets/img/language.svg"
          alt="meet"
        />
      </div>
    </div>
    <div *ngIf="idioma == true">
      <div class="idiomas">
        <div *ngIf="es == true">
            <div class="selected">
              <h2>Español (ES)</h2>
              <hr />
            </div>
            <div>
              <h2 (click)="ingles()" [ngClass]="{ grey: click }">
                English (EN)
              </h2>
            </div>
            <div class="">
              <h2 (click)="arabe()" [ngClass]="{ grey: click }">عربي</h2>
            </div>
          </div>
          <div *ngIf="en == true">
            <div>
              <h2 (click)="espanol()" [ngClass]="{ grey: click }">
                Español (ES)
              </h2>
            </div>
            <div class="selected">
              <h2>English (EN)</h2>
              <hr />
            </div>
            <div class="">
              <h2 (click)="arabe()" [ngClass]="{ grey: click }">عربي</h2>
            </div>
          </div>
          <div *ngIf="ar == true">
            <div>
              <h2 (click)="espanol()" [ngClass]="{ grey: click }">
                Español (ES)
              </h2>
            </div>
            <div>
              <h2 (click)="ingles()" [ngClass]="{ grey: click }">
                English (EN)
              </h2>
            </div>
            <div class="selected">
              <h2>عربي</h2>
              <hr />
            </div>
          </div>
      </div>
    </div>
  </div>
  <div class="loginbox">
    <header>
      <div class="logo">
        <img src="assets/img/logocolor.png" alt="" />
      </div>
      <div class="title">
        <h1 translate>login.titulo</h1>
      </div>
    </header>
    <article>
      <div class="loginform">
        <label for="" translate> login.usuario </label>
        <input
          [(ngModel)]="usuario"
          class="user"
          type="text"
          placeholder="{{ 'login.usuario' | translate }}"
        />
        <label for="" translate> login.contraseña </label>
        <div class="password-container">
          <input
            [(ngModel)]="password"
            class="pass"
            [type]="hide ? 'password' : 'text'"
            placeholder="{{ 'login.contraseña' | translate }}"
            (keyup.enter)="login()"
          />
          <img
            src="{{ hide?'assets/img/eyeclose2.png':'assets/img/eyeopen2.png'}}"
            (click)="hide = !hide"
            class="eye-icon"
          />
        </div>
        <div class="loginbuttons">
          <div>
            <input
              class="acceso"
              type="submit"
              value="{{ 'login.acceso' | translate }}"
              (click)="login()"
            />
          </div>
          <div class="rembox">
            <div class="rememberme">
              <div
                [ngClass]="{ checked: remember }"
                class="checkbox"
                (click)="rememberme()"
              ></div>
              <p (click)="rememberme()" translate>login.recordarme</p>
            </div>
          </div>
        </div>
      </div>
      <!-- <div>
        <button (click)="loginInSAMLcharterschoolit()" > Login SAML </button>
      </div> -->
    </article>
    <footer>
      <a routerLink="/olvidar" class="forget">
        {{ "login.pregunta" | translate }}
      </a>
      <a routerLink="/necesito-ayuda" class="help">
        {{ "login.help" | translate }}
      </a>
    </footer>
  </div>
</div>

<div class="sm-video">
  <video
    id="video_background"
    loop
    muted
    autoplay
    oncanplay="this.play()"
    onloadedmetadata="this.muted = true"
  >
    <source src="assets/video/thecampus2.mp4" type="video/mp4" />
  </video>
</div>
