<!-- <div class="cuerpo" id="video">
    <div class="archivos" >
        <h3>Pulse en cada archivo para descargar</h3>
        <div class="fichero" *ngFor="let file of files">
           - <a [href]="file.url" target="_blank" download="{{file.name}}" title="{{file.name}}">{{file.name}}</a>
        </div>
    </div>
</div> -->
<div class="cuerpo" id="video">
   
</div>
