<!-- header -->
<header id="mainheader2">
    <!-- <div></div> -->
    <div class="idiomasC">
      <div class="idiomasCF">
        <h2 translate (click)="activeLanguage()" (click)="changeColor()">
          login.cambiaridioma
        </h2>
        <div id="circle" routerLink="/home/meet">
          <img
            id="imgB"
            (click)="activeLanguage()"
            src="/assets/img/language.svg"
            alt="meet"
          />
        </div>
      </div>
      <div *ngIf="idioma == true">
        <div class="idiomas">
          <div *ngIf="es == true">
            <div class="selected">
              <h2>Español (ES)</h2>
              <hr />
            </div>
            <div>
              <h2 (click)="ingles()" [ngClass]="{ grey: click }">English (EN)</h2>
            </div>
            <div class="">
              <h2 (click)="arabe()" [ngClass]="{ grey: click }">عربي</h2>
            </div>
          </div>
          <div *ngIf="en == true">
            <div>
              <h2 (click)="espanol()" [ngClass]="{ grey: click }">
                Español (ES)
              </h2>
            </div>
            <div class="selected">
              <h2>English (EN)</h2>
              <hr />
            </div>
            <div class="">
              <h2 (click)="arabe()" [ngClass]="{ grey: click }">عربي</h2>
            </div>
          </div>
          <div *ngIf="ar == true">
            <div>
              <h2 (click)="espanol()" [ngClass]="{ grey: click }">
                Español (ES)
              </h2>
            </div>
            <div>
              <h2 (click)="ingles()" [ngClass]="{ grey: click }">English (EN)</h2>
            </div>
            <div class="selected">
              <h2>عربي</h2>
              <hr />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="saludo">
      <!-- <a
        href="{{instruccionespdf}}"
        target="_blank"
        translate>registro.instrucciones</a
      > -->
    </div>
    <div class="logo">
      <img src="assets/img/logo.png" alt="" routerLink="/"/>
    </div>
  </header>
  <!-- /header -->
  
  <section class="basicbox clase grupo">
    <header>
      <div class="icon">
        <div class="wrapicon">
          <img src="/assets/img/registro.svg" alt="" />
        </div>
      </div>
      <h3 translate>registro.titulo</h3>
    </header>
  
    <div class="confirmacion" *ngIf="confirmacion">
      <div class="basicbox confrbox">
        <img src="assets/img/checkok.svg" />
        <p translate>registro.tituloconfirmado</p>
        <h4 id="respuesta2" translate>registro.textoconfirmado</h4>
      </div>
  
      <a class="button4 btncenter" routerLink="/">{{
        "registro.OK" | translate
      }}</a>
    </div>
  
    <div class="licensiaerror" *ngIf="error">
      <div class="basicbox confrbox">
        <img src="assets/img/xerror.svg" />
        <p translate>registro.titulodenegado</p>
        <h4 id="respuesta" translate>({{ response }})</h4>
      </div>
  
      <a class="button4 btncenter" (click)="returntoform()"
        >{{ "registro.volver" | translate }}
      </a>
    </div>
  
    <div class="form" *ngIf="enviado && !confirmacion && !error">
      <section>
        <h4 class="marbot" translate>registro.datoscontacto</h4>
  
        <div class="twocolsg">
          <div class="firstColumn">
            <div class="formgroup">
              <label for="idnombreal" translate>registro.nombre </label>
              <input
                id="idnombreal"
                class="form-control"
                type="text"
                [(ngModel)]="nombres"
              />
            </div>
  
            <div class="formgroup">
              <label for="idapellial" translate>registro.apellidos</label>
              <input
                id="idapellial"
                class="form-control"
                type="text"
                [(ngModel)]="apellidos"
              />
            </div>
  
            <div class="formgroup">
              <label for="iddiremail" translate>registro.email </label>
              <input
                id="iddiremail"
                class="form-control"
                type="email"
                [(ngModel)]="email"
              />
            </div>
  
            <!-- <div class="formgroup">
              <label for="idusuario" translate>registro.usuario </label>
              <input
                id="idusuario"
                class="form-control"
                type="text"
                [(ngModel)]="usuario"
              />
            </div>
   -->
           
  
            <!-- <div class="formgroup licencia">
              <label for="codpin" translate>registro.pin</label>
              <input
                id="codpin"
                class="form-control"
                type="number"
                placeholder="{{ 'registro.ejemplopin' | translate }}"
                min="1000"
                max="9999"
                pattern="\d{4}"
                maxlength="7"
                [(ngModel)]="pin"
                (keydown)="countnumers($event.target.value)"
              />
            </div> -->
  
            <div class="formgroup">
              <label for="idcont" translate>registro.contraseña </label>
              <input
                id="idcont"
                class="form-control"
                type="password"
                [(ngModel)]="password"
                (keyup)="testPassword($event.target.value)"
              />
            </div>
  
            <div *ngIf="infoPassword == true" class="validatepassContainer">
              <p translate>registro.requisitos</p>
              <div class="validatepass">
                <div>
                  <span [ngClass]="{ ok: mayuscula }" translate
                    >registro.mayuscula</span
                  >
                  <br />
  
                  <span [ngClass]="{ ok: minuscula }" translate
                    >registro.minuscula</span
                  ><br />
  
                  <span [ngClass]="{ ok: numero }" translate>registro.numero</span
                  ><br />
                </div>
                <div>
                  <span [ngClass]="{ ok: caracter }" translate
                    >registro.especial</span
                  ><br />
  
                  <span [ngClass]="{ ok: caractount }" translate
                    >registro.caracteres</span
                  >
                </div>
              </div>
            </div>
          </div>
  
          <div class="secondColumn">
            <div class="formgroup">
              <label for="idfechanac" translate>registro.nacimiento</label>
              <input
                id="idfechanac"
                class="form-control"
                type="date"
                [(ngModel)]="fechana"
              />
            </div>
  
            <div class="formgroup">
              <label for="idgen" translate>registro.genero </label>
              <select id="idgen" class="form-control" [(ngModel)]="genero">
                <option value="masculino">
                  {{ "registro.masculino" | translate }}
                </option>
                <option value="femenino">
                  {{ "registro.femenino" | translate }}
                </option>
              </select>
            </div>
  
            <!-- <div class="formgroup">
              <label for="idpais" translate>registro.pais </label>
              <input
                id="idpais"
                class="form-control"
                type="text"
                [(ngModel)]="pais"
              />
            </div> -->
  
            <!-- <div class="formgroup">
              <label for="iddirecc" translate>registro.domicilio </label>
              <textarea
                id="iddirecc"
                class="form-control"
                [(ngModel)]="direccion"
              ></textarea>
            </div> -->
  
            <div class="formgroup">
              <label for="idtelefono" translate>registro.telefono</label>
              <input
                id="idtelefono"
                class="form-control"
                type="text"
                [(ngModel)]="telefono"
              />
            </div>
  
        
          </div>
        </div>
      </section>
  
      <section>
        <h4 class="marbot" translate>registro.titulolicencia</h4>
  
        <div class="twocolsg">
          <div class="formgroup Lic">
            <label for="codlic" translate>registro.codigo</label>
            <input
              id="codlic"
              class="form-control"
              type="text"
              placeholder="{{ 'registro.ejemplolicencia' | translate }}"
              [(ngModel)]="codigo"
              (input)="comprobarDatosCodigo(codigo)"
            />
          </div>
          <div class="formgroup2">
            <a class="button2" (click)="comprobarDatosCodigo(codigo)">
              {{ "registro.comprobarlicencia" | translate }}</a
            >
          </div>
        </div>
      </section>
    </div>
  </section>
  
  <section *ngIf="licenciaCorrecta == true" class="basicbox clase grupo2">
    <h4 class="marbot" translate>registro.informacionacademica</h4>
    <div class="twocolsg">
      <div id="firstColumn">
        <!-- <div class="formgroup">
          <label for="idnombeced" translate>registro.nombrecentro</label>
          <select id="idnombeced" class="form-control" [(ngModel)]="colegio">
            <option value="{{ idColegio }}" selected>{{ nombreColegio }}</option>
          </select>
        </div> -->
  
        <!-- <div class="formgroup">
          <label for="idlocalic" translate>registro.localidad</label>
          <input
            id="idlocalic"
            class="form-control"
            type="text"
            [(ngModel)]="localidad"
          />
        </div> -->
        <!-- <div class="formgroup">
          <label for="idcurso" translate>registro.curso </label>
          <select
            id="idcurso"
            class="form-control"
            [(ngModel)]="cursos"
            (change)="selectcurso($event.target.value)"
          >
            <ng-container *ngFor="let elcurso of cursosdb">
              <option value="{{ elcurso.id }}">
                {{ elcurso.data().nombre }}
              </option>
            </ng-container>
          </select>
        </div> -->
      </div>
  
      <div id="secondColumn">

   
      </div>
    </div>
    <p id="respuesta">{{ response }}</p>
    <div class="formgroup2">
      <a class="button3" (click)="register()">{{
        "registro.confirmar" | translate
      }}</a>
    </div>
  </section>
  