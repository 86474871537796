<div class="cuerpo">
    <button (click)="changeLayout()">CAMBIAR VISTA</button>
    <div *ngIf="!cambiar" class="botones">
      <h2>Añadir palabra o frase a la lista</h2>
      <input type="text" [(ngModel)]="palabra" name="" id="" (keyup.enter)="comprobarPalabra()" />
      <button (click)="comprobarPalabra()">Subir</button>
      <p>{{ mensaje }}</p>
    </div>
  
    <div *ngIf="cambiar" class="listado">
      <h2>Palabras o frases en la BBDD ( {{listaPalabras.length}} )</h2>
      <div class="palabras" *ngFor="let palabra of listaPalabras">
        <p>
          <b> {{ palabra }} </b>
        </p>
        <hr />
      </div>
      <p>{{ mensaje }}</p>
    </div>
  </div>
  